import {DotYouClient} from "@youfoundation/js-lib/core";
import {getNewId} from "@youfoundation/js-lib/helpers";
import {makeAutoObservable, runInAction} from "mobx";
import {HomebaseStorage, SaveFileConfig, SaveOptions} from "../core/storage/HomebaseStorageTypes";
import {GuideFlowConfig} from "../../system/GuideFlowConfig";
import {CassieQueryOptions} from "../core/cassie/CassieTypes";
import {FileQueryParamsWithoutTargetDrive, SimplifiedStorageProvider} from "../core/storage/SimplifiedStorageProvider";

const noteSaveFileConfig: SaveFileConfig = {
    fileType: 9974,
    dataType: 0,
    targetDrive: GuideFlowConfig.DataDrive
}

export interface ContactNote extends HomebaseStorage {
    id: string
    contactId: string;  //groupId
    timestamp: number
    content: string
}

export interface ContactNoteQueryOptions {
    dataType?: number,
    cassieQueryOptions?: CassieQueryOptions
}

//Manages the data for a contact's progress; including helper methods
// to indicate the big events (initiated, life activation, etc.)
export class ContactNotesProvider {
    private readonly provider: SimplifiedStorageProvider<ContactNote>;

    public StateChanged: number = 0;

    constructor(dotYouClient: DotYouClient) {
        this.provider = new SimplifiedStorageProvider<ContactNote>({dataType: undefined, ...noteSaveFileConfig}, dotYouClient);
        makeAutoObservable(this);
        this.synchronize = this.synchronize.bind(this);
    }

    public async getAll(contactId: string, options?: ContactNoteQueryOptions): Promise<ContactNote[]> {
        return await this.provider.getAll({groupId: contactId, dataType: options?.dataType, cassieQueryOptions: options?.cassieQueryOptions});
    }

    public async save(note: ContactNote) {

        if (!note) {
            throw Error("Note is null");
        }

        if (!note.contactId || !note.timestamp) {
            throw Error("One or more fields is missing");
        }

        if (!note.id) {
            note.id = getNewId();
        }

        const options: SaveOptions =
            {
                groupId: note.contactId,
                userDate: note.timestamp,
                onVersionConflict: () => {
                    //TODO: how to handle this?
                }
            };

        await this.provider.saveHeaderFile(note, undefined, options);
        this.notifyStateChange();
    }

    public async delete(id: string): Promise<boolean> {

        if (await this.provider.hardDeleteFile(id)) {
            this.notifyStateChange();
            return true;
        }

        return false;
    };

    public async get(itemId: string): Promise<ContactNote | null> {
        return await this.provider.getByUniqueId(itemId);
    }

    public async syncDataType(dataType: number) {

        const params: FileQueryParamsWithoutTargetDrive = {
            dataType: [dataType]
        };

        return await this.provider.syncByQuery(params, 0);
    }

    ///
    public async synchronize(asOfTime: number) {
        const count = await this.provider.syncFileType(asOfTime);
        if (count > 0) {
            this.notifyStateChange();
        }
    }

    public async exportGraph(context?: string) {
        return await this.provider.exportGraph(context)
    }
    
    private notifyStateChange() {
        runInAction(() => {
            this.StateChanged++;
        });
    }
    
}