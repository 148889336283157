import React, {useEffect, useState} from "react";
import {Button} from "../../tailwindui/button";
import SpinnerIcon from "../../icons/SpinnerIcon";

export function CassieAwareSubmitButton(props: { title?: string, isSaving: boolean }) {

    const [isOffline, setIsOffline] = useState(true);

    useEffect(() => {
        setIsOffline(!window.navigator.onLine);
    }, []);

    return (<Button
        type="submit"
        disabled={props.isSaving || isOffline}
        color="indigo">
        {props.isSaving && <SpinnerIcon/>}
        {props.title ?? "Save"}
    </Button>)
}
