import {useGuideFlowProviderContext} from "../../../../GuideFlowProviderContext";
import {useEffect, useState} from "react";
import {naah} from "../../../viewUtils";

export const ContactStatusText = (props: { id: string }) => {
    const context = useGuideFlowProviderContext();
    const [loaded, setLoaded] = useState(false);
    const [statusText, setStatusText] = useState("");

    useEffect(() => {
        if(props.id) {
            context.Contacts.ProgressionEvents.getStatus(props.id).then(status => {
                setStatusText(status.text);
                setLoaded(true);
            });
        }
    }, [props.id, context.Contacts.ProgressionEvents, context.Contacts.ProgressionEvents.StateChanged, loaded]);

    if (!loaded) {
        return "...";
    }

    return naah(statusText);
}
