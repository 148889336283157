import React from "react";
import {Outlet} from "react-router-dom";
import {NextStepsPerContact} from "./controls/NextStepsPerContact";
import DashboardHeader from "./controls/DashboardHeader";
import {PrayersAndCallUpons} from "./controls/PrayersAndCallUpons";
import {ClientFollowUps} from "./controls/ClientFollowUps";
import {ClassPlanningHorizontalList} from "./controls/ClassPlanningPreview/ClassPlanningHorizontalList";
import {DropMenu, DropMenuItem} from "../../controls/vidence/menus/dropmenu/Dropmenu";
import {PlusIcon} from "@heroicons/react/20/solid";

// reminder: add the random select button so the HoL can help 
// you choose, or it could be a map of initiate names to see who 
// you check into
export default function HomeSectionIndex() {

    return (
        <>
            <div className="flex gap-3 bg-slate-50 flex-col relative z-0 flex-1 overflow-y-scroll p:3 sm:p-1">

                <DashboardHeader title="Classes" description="Whatcha teaching next?">
                    <div className="mt-3">
                        <ClassPlanningHorizontalList/>
                    </div>
                </DashboardHeader>

                <DashboardHeader title="Follow ups" description="The clients ready for a nudge.">
                    <ClientFollowUps/>
                </DashboardHeader>

                <DashboardHeader title="Next steps" description="The next steps/actions for your clients">
                    <NextStepsPerContact/>
                </DashboardHeader>

                <DashboardHeader title="Daily Call-Upons" description="A rotation of call upons for those you support"
                                 menu={
                                     <DropMenu
                                         menuButtonClassName="p-1.5 hover:bg-indigo-500 text-white relative rounded-full bg-indigo-600  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                         imageElement={<PlusIcon className="h-5 w-5" aria-hidden="true"/>}>
                                         <DropMenuItem title="Add" to="/callupons/new"/>
                                     </DropMenu>
                                 }>
                    <PrayersAndCallUpons/>
                </DashboardHeader>

                {/* Upcoming classes near me, by other guides*/}
                <main className="relative z-0 flex-1 overflow-y-auto focus:outline-none xl:order-last">
                    <Outlet/>
                </main>
            </div>
        </>
    );
}