import moment from "moment";

export const dateTextToUtcSeconds = (date: string): number => {
    const m = moment(date, 'YYYY-MM-DD');
    return m.unix();
}


export const nowUtcSeconds = (): number => {
    return moment().utc().unix()
}

export const utcSecondsToDateText = (seconds: number): string => {
    const m = moment.unix(seconds)
    return m.format("YYYY-MM-DD");
}

export const hoursToMinutes = (hours: number): number => {
    return hours * 60;
}

export const formatHoursFromMinutes = (minutes: number): number => {
    return minutes / 60;
}

export const unixTimeUtcFromDate = (dateString: string) => {
    return moment(dateString, 'YYYY-MM-DD HH:mm').unix();
}