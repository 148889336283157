import {base64ToUint8Array} from "@youfoundation/js-lib/helpers";
import {Image64Data} from "../../../../providers/core/storage/HomebaseStorageTypes";
import {EmbeddedThumb} from "@youfoundation/js-lib/dist/core/DriveData/File/DriveFileTypes";

export const imageDataToUri = (data: Image64Data) => {
    return `data:${data.mimeType};base64,${data.base64Data}`;
}
export const previewThumbnailUri = (thumb: EmbeddedThumb) => {
    return `data:${thumb.contentType};base64,${thumb.content}`;
}

export const imageDataToBlob = (data: Image64Data): Blob => {
    return new Blob([base64ToUint8Array(data.base64Data)], {type: data.mimeType});
}