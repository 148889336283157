import {Text} from "../../tailwindui/text";
import {PhysicalAddress} from "../../../../providers/MapTypes";

interface PhysicalAddressTextProps {
    address: PhysicalAddress
}

export const PhysicalAddressText = (props: PhysicalAddressTextProps) => {
    const {address} = props;
    return (<div>
        <Text>{address.addressLine1}</Text>
        {address.addressLine2 && <Text>{address.addressLine2}</Text>}
        <Text>{address.city} {" "} {address.stateProvinceRegion}</Text>
        <Text>{address.postalCode}</Text>
        <Text>{address.country}</Text>
    </div>)

}