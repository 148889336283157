import {DefinitionStorage} from "../DefinitionStorageProvider";
import {hoursToMinutes} from "../../../helpers/Dates";
import {parseDineroSnapshot} from "../../../helpers/Money";

export const SacredGeometry1: DefinitionStorage = {
    id: 'ccc209f0-84ba-4d40-aead-b731ee7607d2',
    title: 'Sacred Geometry 1',
    durationMinutes: hoursToMinutes(4),
    exchange: parseDineroSnapshot(150)
}
export const AstralTravel: DefinitionStorage = {
    id: 'ccc22512-23fb-422c-aaa5-c1cbfdf1db88',
    title: 'Astral Travel',
    durationMinutes: hoursToMinutes(4),
    exchange: parseDineroSnapshot(150),
    prerequisiteClasses: [SacredGeometry1.id]
}

export const SpiritualIntuition: DefinitionStorage = {
    id: 'ccc26c80-46af-471c-bed3-408e64156aaf',
    title: 'Spiritual Intuition',
    durationMinutes: hoursToMinutes(4),
    exchange: parseDineroSnapshot(40)
}

export const AwakenThyself: DefinitionStorage = {
    id: 'cccb54d9-ce80-40f5-84d4-d204b78f33b2',
    title: 'Awaken Thyself',
    durationMinutes: hoursToMinutes(4),
    exchange: parseDineroSnapshot(40)
}

export const MysterySchools: DefinitionStorage = {
    id: 'ccc2d764-bb43-44b3-be94-ca6812ae8b0f',
    title: '7 Mystery Schools',
    durationMinutes: hoursToMinutes(4),
    exchange: parseDineroSnapshot(150)
}
export const EmpowerThyself: DefinitionStorage = {
    id: 'cccf34c4-2ead-406e-ade5-5a6b3f24a6eb',
    title: 'Empower Thyself',
    durationMinutes: hoursToMinutes(4),
    exchange: parseDineroSnapshot(40)
}
export const SanctuaryMeditation: DefinitionStorage = {
    id: 'cccf7fbc-e936-4714-b965-f429900f55b3',
    title: 'Sanctuary Meditation',
    durationMinutes: hoursToMinutes(4),
    exchange: parseDineroSnapshot(40)
}
export const StressRescue: DefinitionStorage = {
    id: 'f000bb43-7e48-42a4-93f9-a6600844b1bc',
    title: 'Stress Rescue',
    durationMinutes: hoursToMinutes(4),
    exchange: parseDineroSnapshot(40)
}
export const JourneysOfTheSpirit: DefinitionStorage = {
    id: '17324a10-d926-474d-940c-64afb6963382',
    title: 'Journeys of the Spirit',
    durationMinutes: hoursToMinutes(4),
    exchange: parseDineroSnapshot(300)
}
export const TwelveRacesOfEarth: DefinitionStorage = {
    id: 'feb068d5-79bd-4628-b113-f68b9421d4be',
    title: 'Twelve Magical Beings of the World of Humans',
    durationMinutes: hoursToMinutes(4),
    exchange: parseDineroSnapshot(150)
}
export const AdvancedAstralTravel: DefinitionStorage = {
    id: '6a2da8ba-d348-4757-b9cd-4993ce65a1ba',
    title: 'Advanced Astral Travel',
    durationMinutes: hoursToMinutes(4),
    exchange: parseDineroSnapshot(150)
}
export const SacredGeometry2: DefinitionStorage = {
    id: '6bff4742-52de-47a4-95e9-1301164c802c',
    title: 'Sacred Geometry 2',
    durationMinutes: hoursToMinutes(4),
    exchange: parseDineroSnapshot(150)
}

export const SacredGeometry3: DefinitionStorage = {
    id: '5129b641-4d7a-49b6-a1b5-d6d12485fa14',
    title: 'Sacred Geometry 3',
    durationMinutes: hoursToMinutes(4),
    exchange: parseDineroSnapshot(300)
}

export const SacredGeometry4: DefinitionStorage = {
    id: 'ea72272b-0996-475f-854a-44e6414684be',
    title: 'Sacred Geometry 4',
    durationMinutes: hoursToMinutes(4),
    exchange: parseDineroSnapshot(300)
}

export const SacredGeometry5: DefinitionStorage = {
    id: '96739ccf-ceea-44c4-a005-061ec6271021',
    title: 'Sacred Geometry 5',
    durationMinutes: hoursToMinutes(4),
    exchange: parseDineroSnapshot(300)
}

export const SacredGeometry6: DefinitionStorage = {
    id: 'c76dd1f2-6f49-4af4-9d09-828ebda750fb',
    title: 'Sacred Geometry 6',
    durationMinutes: hoursToMinutes(4),
    exchange: parseDineroSnapshot(300)
}
export const SacredGeometry7: DefinitionStorage = {
    id: '0c53a237-0c48-43d1-87fb-126b0d955d7c',
    title: 'Sacred Geometry 7',
    durationMinutes: hoursToMinutes(4),
    exchange: parseDineroSnapshot(300)
}

export const builtInClasses: DefinitionStorage[] = [
    SacredGeometry1,
    AstralTravel,
    SpiritualIntuition,
    AwakenThyself,
    MysterySchools,
    EmpowerThyself,
    SanctuaryMeditation,
    StressRescue,
    JourneysOfTheSpirit,
    TwelveRacesOfEarth,
    AdvancedAstralTravel,
    SacredGeometry2,
    SacredGeometry3,
    SacredGeometry4,
    SacredGeometry5,
    SacredGeometry6,
    SacredGeometry7,
]