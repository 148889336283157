import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {useGuideFlowProviderContext} from "../../../../../GuideFlowProviderContext";
import {ClassAttendee, ClassDefinition, ScheduledClass} from "../../../../../providers/definitions/ClassDefinitionProvider";
import DateTimeUtcText from "../../../../controls/renderers/DateTime/DateTimeUtcText";
import {LoadingPanel} from "../../../../controls/vidence/blanks/LoadingPanel";
import {ContactProfileHeader} from "../../../../controls/renderers/Contacts/ContactProfileHeader";
import {t} from "../../../../../helpers/i18n/dictionary";
import {DropMenu, DropMenuItem} from "../../../../controls/vidence/menus/dropmenu/Dropmenu";
import LocationSelector from "../../../../controls/gf/LocationSelector";
import {LocationDefinition} from "../../../../../providers/definitions/LocationDefinitionProvider";
import {PlusCircleIcon} from "@heroicons/react/16/solid";
import ClassScheduleAttendeeDialog from "./ClassScheduleAttendeeDialog";

export default function ClassScheduleDetails() {
    const {id, classScheduleId} = useParams();
    const [loaded, setLoaded] = useState(false);
    const [scheduledClass, setScheduledClass] = useState<ScheduledClass | undefined>();
    const [relatedDefinition, setRelatedDefinition] = useState<ClassDefinition>();
    const context = useGuideFlowProviderContext();

    useEffect(() => {
        context.ClassDefinitions.getScheduledClass(classScheduleId).then((scheduledClass) => {
            context.ClassDefinitions.get(scheduledClass.classDefinitionId).then(def => {
                setScheduledClass(scheduledClass);
                setRelatedDefinition(def);

                // context.Locations.get(scheduledClass.locationId).then(location => {
                //     setRelatedLocation(location);
                // });

                setLoaded(true);
            });
        });
    }, [classScheduleId, context.ClassDefinitions, context.ClassDefinitions.StateChanged, id]);

    if (!loaded) {
        return <LoadingPanel text="Loading the class schedule..."/>
    }

    // const exchangeText = relatedDefinition?.exchange ? `${formatDinero(relatedDefinition.exchange)} ${generalConfig.currency.code}` : t("N/A");
    // const durationText = relatedDefinition?.durationMinutes ? `${formatHoursFromMinutes(relatedDefinition?.durationMinutes)} ${t("hours")}` : t("N/A");

    console.log(relatedDefinition);

    function handleLocationChange(location: LocationDefinition) {
        // setScheduledClass(p => ...p, locationId: location.id)
    }

    return (
        <>
            <div className="flex-shrink-0 border-b border-gray-200 bg-white">
                <div className="flex h-16 flex-col justify-center">
                    <div className="flex justify-between">
                        <div>
                            <DateTimeUtcText date time datetime={scheduledClass.startDateTimeUtc}/>
                            <span className="text-gray-400 text-sm"> (<DateTimeUtcText fromNow datetime={scheduledClass.startDateTimeUtc}/>)</span>
                        </div>
                        {/*<DropMenu/>*/}
                    </div>
                </div>

            </div>
            <dl className="grid grid-cols-1 sm:grid-cols-2">
                <div className="border-gray-100 py-6 sm:col-span-1 sm:px-0">
                    <dt className="text-sm font-bold leading-6 text-gray-900">Location</dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">
                        <LocationSelector selectedLocationId={scheduledClass.locationId} onSaveSelection={handleLocationChange}/>
                    </dd>
                </div>
            </dl>
            <AttendeeList scheduledClass={scheduledClass}/>
        </>
    )
}

function Status(props: { student: ClassAttendee }) {

    let color = "";
    let text = "";

    switch (props.student.status) {
        case "deposit-paid":
            color = "text-green-700 ring-green-600/20";
            text = t("Deposit");
            break;
        case "fully-paid":
            color = "text-green-700 ring-green-600/20";
            text = t("Paid");
            break;
        case "tentative":
            color = "text-green-700 ring-green-600/20";
            text = t("Tentative");
            break;
        case "candidate":
            color = "text-green-700 ring-green-600/20";
            text = t("Candidate");
            break;
    }

    return <span className={`inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium ring-1 ring-inset ${color}`}>
        {text}
    </span>
}

function AttendeeList(props: { scheduledClass: ScheduledClass }) {

    const [showDialog, setShowShowDialog] = useState(false);

    function handleAddEntry() {
        setShowShowDialog(true);
    }
    
    function handleOnClose(){
        setShowShowDialog(false);
    }

    return (
        <>
            <ClassScheduleAttendeeDialog showDialog={showDialog} onClose={handleOnClose}/>
            <div className="">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h1 className="text-base font-semibold leading-6 text-gray-900">Attendees</h1>
                        <p className="mt-2 text-sm text-gray-700">
                            Confirmed and candidate attendees
                        </p>
                    </div>
                    <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                        <button
                            type="button"
                            onClick={() => handleAddEntry()}
                            className="relative inline-flex rounded-md items-center gap-x-1.5 bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:z-10 hover:bg-gray-50 focus:z-10">
                            <PlusCircleIcon className="-ml-0.5 h-5 w-5 text-gray-400" aria-hidden="true"/>Add
                        </button>
                    </div>
                </div>
                <div className="-mx-4 mt-8 sm:-mx-0">
                    <table className="min-w-full divide-y divide-gray-300">
                        <thead>
                        <tr>
                            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                                Name
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                Status
                            </th>
                        </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                        {props.scheduledClass.students.map((student) => (
                            <tr key={student.contactId}>
                                <td>
                                    <ContactProfileHeader id={student.contactId}/>
                                </td>
                                <td className="px-3 py-4 text-sm text-gray-500">
                                    <Status student={student}/>
                                </td>
                                <td className="py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                                    <DropMenu>
                                        <DropMenuItem title="Mark as Candidate" onClick={undefined}/>
                                        <DropMenuItem title="Mark as Tentative" onClick={undefined}/>
                                        <DropMenuItem title="Mark Deposit" onClick={undefined}/>
                                        <DropMenuItem title="Mark Payment" onClick={undefined}/>
                                        <DropMenuItem title="Remove" onClick={undefined}/>
                                    </DropMenu>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}

