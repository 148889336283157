import {useGuideFlowProviderContext} from "../../../GuideFlowProviderContext";
import React, {useEffect, useState} from "react";
import {observer} from "mobx-react-lite";
import {Avatar} from "../../controls/tailwindui/avatar";
import {imageDataToUri} from "../../controls/vidence/image/ImageUtil";
import {Image64Data} from "../../../providers/core/storage/HomebaseStorageTypes";

interface DefinitionThumbnailProps {
    id: string,
    className: string,
    onClick?: () => void
}

export const ClassDefinitionThumbnail = observer((props: DefinitionThumbnailProps) => {
    const context = useGuideFlowProviderContext();
    const [image, setImage] = useState<Image64Data>();
    const [initials, setInitials] = useState("");

    useEffect(() => {
        context.ClassDefinitions.getProfileImage(props.id).then(img => {
            if (img === null) {
                context.ClassDefinitions.get(props.id).then(def => {
                    const title = def?.title;
                    if (title == null) {
                        setInitials("");
                    } else {
                        //strings are finicky =)
                        try {
                            const parts = title.trim().split(" ");
                            if (parts.length === 1) {
                                setInitials(title.substring(0, 2));
                            } else {
                                setInitials(`${parts[0][0]}${parts[1][0]}`);
                            }
                        }
                        catch
                        {
                            setInitials("");
                        }
                     
                    }
                });
            }
            setImage(img);
        });
    }, [context.ClassDefinitions, context.ClassDefinitions.StateChanged, props.id]);

    const imageUri = image ? imageDataToUri(image) : "";
    return (<Avatar onClick={props.onClick} initials={initials} square={false} className={`bg-white/75 ${props.className}`}
                    src={imageUri}/>)
})
