import {useEffect, useState} from "react";
import {InitiationDefinition} from "../../../../providers/definitions/InitiationDefinitionProvider";
import {useGuideFlowProviderContext} from "../../../../GuideFlowProviderContext";

interface Props {
    id: string
}

export default function InitiationTitle(props: Props) {

    const context = useGuideFlowProviderContext();
    const [definition, setDefinition] = useState<InitiationDefinition>()

    useEffect(() => {
        context.InitiationDefinitions.get(props.id).then(setDefinition);
    }, [context.InitiationDefinitions, context.InitiationDefinitions.StateChanged, props.id]);


    return (
        <span>{definition?.title}</span>
    )
}