import {useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {PlusCircleIcon} from "@heroicons/react/16/solid";
import {useGuideFlowProviderContext} from "../../../../../GuideFlowProviderContext";
import {ScheduledClass} from "../../../../../providers/definitions/ClassDefinitionProvider";
import {LoadingPanel} from "../../../../controls/vidence/blanks/LoadingPanel";
import DateTimeSelector from "../../../../controls/gf/DateTimeSelector";
import {nowUtcSeconds} from "../../../../../helpers/Dates";
import EmptyScreen from "../../../../controls/vidence/blanks/EmptyScreenAction";
import DateTimeUtcText from "../../../../controls/renderers/DateTime/DateTimeUtcText";
import {ArrowRightIcon} from "@heroicons/react/24/outline";

export default function ClassScheduleList() {
    const {id} = useParams();
    const navigate = useNavigate();
    const [entries, setEntries] = useState<ScheduledClass[]>();
    const [loaded, setLoaded] = useState(false);
    const [showDateDialog, setShowDateDialog] = useState(false);

    const context = useGuideFlowProviderContext();

    useEffect(() => {
        setLoaded(true);
        context.ClassDefinitions.getScheduledClasses(id).then((items) => {
            setLoaded(true);
            setEntries(items);
        });
    }, [id, context.Contacts.ProgressionEvents, context.Contacts.ProgressionEvents.StateChanged, context.ClassDefinitions, context.ClassDefinitions.StateChanged]);

    const handleAddEntry = async () => {
        setShowDateDialog(true);
    }

    function handleViewEntry(entry: ScheduledClass) {
        navigate(`${entry.id}`);
    }

    if (!loaded) {
        return (<LoadingPanel text="loading schedule..."/>);
    }

    function handleDateSelected(datetimeUtc: number) {
        alert(datetimeUtc);
        //schedule the class, navigate to edit
        navigate("edit");
    }

    if (loaded) {
        return (
            <>
                <DateTimeSelector title="Select a date time..." date={nowUtcSeconds()} show={showDateDialog} onSave={handleDateSelected}
                                  onClose={() => setShowDateDialog(false)}/>
                <div>
                    <div className="flex h-16 flex-col justify-center">
                        <div className="flex justify-between py-3">
                            <div className="isolate inline-flex rounded-md shadow-sm sm:shadow-none">
                                  <span className="inline-flex sm:shadow-sm">
                                    <button
                                        type="button"
                                        onClick={() => handleAddEntry()}
                                        className="relative inline-flex rounded-md items-center gap-x-1.5 bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:z-10 hover:bg-gray-50 focus:z-10">
                                      
                                        <PlusCircleIcon className="-ml-0.5 h-5 w-5 text-gray-400" aria-hidden="true"/> 
                                        Schedule
                                    </button>
                                  </span>
                            </div>
                        </div>
                    </div>
                </div>

                {loaded && entries?.length === 0 &&
                    <EmptyScreen text="Maybe it's time to put this class on the schedule..."/>}

                {loaded && entries?.length > 0 &&
                    <ul className="divide-y divide-gray-100">
                        {entries.map((entry) => (
                            <li key={entry.id} className="flex items-center justify-between gap-x-3 py-5">
                                <div className="flex flex-row w-full justify-between gap-x-1">
                                    <div className="min-w-0 max-w-80">
                                        <div className="flex items-start gap-x-3">
                                            <p className="truncate text-sm font-semibold leading-6 text-gray-900">
                                                <DateTimeUtcText date time year datetime={entry.startDateTimeUtc}/>
                                            </p>
                                        </div>
                                        <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                                            <p className="whitespace-nowrap">
                                                <DateTimeUtcText fromNow datetime={entry.startDateTimeUtc}/>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="flex flex-none items-center gap-x-4">
                                        <button
                                            onClick={() => {
                                                handleViewEntry(entry);
                                            }}
                                            className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block">
                                            <ArrowRightIcon className="h-5 w-3"/> <span className="sr-only"></span>
                                        </button>
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                }
            </>)
    }
}