import React, {useEffect, useState} from "react";
import {useGuideFlowProviderContext} from "../../../../GuideFlowProviderContext";
import {LoadingPanel} from "../../../controls/vidence/blanks/LoadingPanel";
import {NextStepRecommendation} from "../../../../providers/recomendations/RecommendationProvider";
import {ContactProfileHeader} from "../../../controls/renderers/Contacts/ContactProfileHeader";
import HealingTitle from "../../../controls/renderers/Healings/HealingTitle";

interface NextHealingsProps {

}

export function NextStepsPerContact(props: NextHealingsProps) {
    const context = useGuideFlowProviderContext();
    const [recommendations, setRecommendations] = useState<NextStepRecommendation[]>();

    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        context.Recommendations.getNextSteps().then(steps => {
            if (steps.length > 0) {
                setRecommendations(steps);
            }

            setLoaded(true);
        });

    }, [props, context.Recommendations.StateChanged, context.Recommendations]);

    if (!loaded) {
        return <LoadingPanel text="loading next steps..."/>
    }

    if ((recommendations?.length ?? 0) === 0) {
        return (<div>N/A</div>)
    }

    return (
        <ul className="divide-y divide-gray-100">
            {recommendations?.map((step) => {

                return (
                    <li key={step.id} className="flex justify-between gap-x-6 py-5">
                        <div className="flex min-w-0 gap-x-4">
                            <ContactProfileHeader id={step.contactId}/>

                        </div>
                        <div className="flex shrink-0 items-center gap-x-6">
                            <div className="hidden sm:flex sm:flex-col sm:items-end">
                                <div className="min-w-0 flex-auto">
                                    <p className="mt-1 flex text-xs leading-5 text-gray-500">
                                        <HealingTitle id={step.healingId}/>
                                    </p>
                                </div>
                            </div>

                        </div>
                    </li>
                )
            })}
        </ul>
    );
}