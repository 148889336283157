import {UserIcon} from "@heroicons/react/20/solid";
import Cropper, {Area} from "react-easy-crop";
import React, {useRef, useState} from "react";
import getCroppedImage from "./CroppingUtil";
import PlaceHolderIcon from "../../icons/PlaceHolderIcon";
import {Button} from "../../tailwindui/button";
import {uint8ArrayToBase64} from "@youfoundation/js-lib/helpers";
import {imageDataToUri} from "./ImageUtil";
import {Image64Data} from "../../../../providers/core/storage/HomebaseStorageTypes";
import {Dialog, DialogActions, DialogBody, DialogTitle} from "../../tailwindui/dialog";

interface ImageCaptureDialogProps {
    aspectRatio: number;
    header?: Element | string;
    isOpen: boolean,
    onClose: (value: boolean) => void,
    onSave: (image: Image64Data) => void;
}

export default function ImageCaptureDialog(props: ImageCaptureDialogProps) {

    // const context = useGuideFlowProviderContext();
    const [mode, setMode] = useState<"normal" | "cropping">("normal");
    const [crop, setCrop] = useState({x: 0, y: 0});
    const [zoom, setZoom] = useState(1);
    const [originalImageBase64, setOriginalImageBase64] = useState<Image64Data | null>(null);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | null>(null);
    const [croppedImage, setCroppedImage] = useState(null);

    const fileInputRef = useRef(null);
    const cameraFileRef = useRef(null);

    const loadImage = async (file: File): Promise<{ width: number, height: number }> => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.onload = (e) => {
                const img = new Image();
                img.onload = () => {
                    resolve({width: img.width, height: img.height});
                };

                img.onerror = reject;

                if (typeof e.target.result === "string") {
                    img.src = e.target.result;
                }
            };

            reader.onerror = reject;
            reader.readAsDataURL(file);
        });
    }

    const handleFileChange = async (event: any) => {
        const file = event.target.files[0] as File;

        if (file && file.type.startsWith('image/')) {
            let img = await loadImage(file);

            const data: Image64Data = {
                width: img.width,
                height: img.height,
                mimeType: file.type,
                base64Data: uint8ArrayToBase64(new Uint8Array(await file.arrayBuffer()))
            };

            setOriginalImageBase64(data)
        } else {
            setOriginalImageBase64(null);
        }
    };

    //@ ts-ignore
    const onCropComplete = (croppedArea, croppedAreaPixels) => {
        // console.log(croppedArea, croppedAreaPixels);
        setCroppedAreaPixels(croppedAreaPixels);
    }

    const handleCompletedCropping = async () => {
        const image = await getCroppedImage(imageDataToUri(originalImageBase64), croppedAreaPixels);

        const data: Image64Data = {
            width: croppedAreaPixels.width,
            height: croppedAreaPixels.height,
            mimeType: image.type,
            base64Data: uint8ArrayToBase64(new Uint8Array(await image.arrayBuffer()))
        };

        setCroppedImage(data);
        setMode("normal");
    }

    const handleUploadPhoto = () => {
        setCroppedImage(null);
        setOriginalImageBase64(null);
        fileInputRef.current.click();
    }

    const handleTakePhoto = () => {
        setCroppedImage(null);
        setOriginalImageBase64(null);
        cameraFileRef.current.click();
    }

    const handleCancel = () => {
        reset();
        props.onClose(false);
    }

    const getFinalImage = (): Image64Data | null => {
        // console.log('ci', croppedImage)
        // console.log('oi', originalImageBase64)
        return croppedImage ?? originalImageBase64;
    }

    const handleSaveFinalImage = () => {
        const final = getFinalImage();
        props.onSave(final);
        reset();
        props.onClose(false);
    }

    const reset = () => {
        setMode("normal");
        setOriginalImageBase64(null);
        setCroppedImage(null);
    }
    const handleDialogClose = () => {
        reset();
        props.onClose(false);
    }

    const img = getFinalImage();
    const hasImage = img != null;

    const previewImage = hasImage ? imageDataToUri(img) : null;

    return (
        <Dialog
            size="lg"
            onClose={handleDialogClose}
            initialFocus={undefined}
            open={props.isOpen}>

            <DialogTitle>
                <div className="flex flex-row gap-3 justify-center">
                    <UserIcon className="h-6 w-6" aria-hidden="true"/>
                    <h3 className="text-base font-semibold leading-6 text-gray-900">
                        Profile Image
                    </h3>
                </div>
            </DialogTitle>
            {/*<DialogDescription>Please agree to the following terms and conditions to continue.</DialogDescription>*/}
            <DialogBody className="text-sm/6 text-zinc-900 dark:text-white">
                <div>
                    <input ref={cameraFileRef} capture="environment" id="camera-upload" type="file" accept="image/*" className="hidden"
                           onChange={handleFileChange}/>
                    <input ref={fileInputRef} capture={undefined} id="file-upload" type="file" accept="image/*" className="hidden" onChange={handleFileChange}/>

                    <div className="text-center sm:mt-5">
                        <div className="mt-4">
                            <div
                                className="relative h-64 w-full overflow-hidden rounded-lg bg-white sm:aspect-h-1 sm:aspect-w-2 lg:aspect-h-1 lg:aspect-w-1 group-hover:opacity-75 sm:h-32">

                                {mode === "cropping" && <Cropper
                                    image={imageDataToUri(originalImageBase64)}
                                    crop={crop}
                                    zoom={zoom}
                                    aspect={props.aspectRatio}
                                    onCropChange={setCrop}
                                    onCropComplete={onCropComplete}
                                    onZoomChange={setZoom}
                                />}

                                {mode === "normal" && hasImage && <img alt="" className="object-contain" src={previewImage}/>}
                                {mode === "normal" && !hasImage && <PlaceHolderIcon/>}
                            </div>
                        </div>
                    </div>
                </div>
            </DialogBody>
            <DialogActions className="w-full">
                <div className="w-full">
                    {mode === "cropping" &&
                        <div className="">
                            <Button
                                type="button"
                                onClick={handleCompletedCropping}
                                className="mt-3 inline-flex w-full justify-center">
                                Done Cropping
                            </Button>

                            <Button
                                type="button"
                                onClick={handleCancel}
                                className="mt-3 inline-flex w-full justify-center">
                                Cancel
                            </Button>
                        </div>
                    }

                    {mode === "normal" &&
                        <div className="flex flex-col w-full">
                            <div className="w-full flex flex-col justify-stretch space-y-3">
                                {hasImage &&
                                    <Button
                                        type="button"
                                        onClick={() => setMode("cropping")}
                                        className="mt-3 inline-flex w-full justify-center">
                                        Crop
                                    </Button>}

                                <Button
                                    type="button"
                                    onClick={() => handleUploadPhoto()}
                                    className="mt-3 inline-flex w-full justify-center">
                                    Upload an image
                                </Button>

                                <Button
                                    type="button"
                                    onClick={() => handleTakePhoto()}
                                    className="mt-3 inline-flex w-full justify-center">
                                    Take a photo
                                </Button>
                            </div>
                            <div>
                                <Button
                                    type="button"
                                    disabled={!hasImage}
                                    onClick={handleSaveFinalImage}
                                    className="mt-1 inline-flex w-full justify-center">
                                    Save
                                </Button>
                            </div>
                            <div>
                                <Button
                                    type="button"
                                    onClick={handleCancel}
                                    className="mt-1 inline-flex w-full justify-center">
                                    Cancel
                                </Button>
                            </div>
                        </div>
                    }
                </div>
            </DialogActions>
        </Dialog>);
}