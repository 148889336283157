import React from "react";
import {Link} from "react-router-dom";
import {classNames} from "../../viewUtils";

export function Tab(props: { name: string, to: string, icon?: any }) {

    const path = window.location.pathname.toLowerCase();
    const to = props.to.toLowerCase();
    const pathMatchesTab = path.endsWith(to) || path.includes(`/${to}/`);

    const active = "border-pink-500 text-gray-900";
    const inactive = "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700";

    return (<Link
        to={props.to}
        className={classNames(pathMatchesTab ? active : inactive, 'group flex gap-x-1 rounded-md py-2 pl-1 pr-1 text-sm leading-6 font-semibold items-center justify-center')}
        aria-current={pathMatchesTab ? 'page' : undefined}>
        {props.icon && <props.icon
            className={classNames(pathMatchesTab ?
                    'text-indigo-600' :
                    'text-gray-400 group-hover:text-indigo-600',
                'h-4 w-4 shrink-0'
            )}
            aria-hidden="true"/>}
        {props.name}
    </Link>)
}