import React from "react";

export default function PackageSettingsTab() {
    return (
        <div className="mx-auto max-w-2xl space-y-16 sm:space-y-20 lg:mx-0 lg:max-w-none p-3">
            <div>
                <h2 className="text-base font-semibold leading-7 text-gray-900">Packages</h2>
                <p className="mt-1 text-sm leading-6 text-gray-500">
                    Packages let you bundle your healings and/or classes.
                </p>

            </div>

        </div>
    )
}