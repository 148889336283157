import React from 'react'
import {Dialog, DialogActions, DialogBody, DialogTitle} from "../../tailwindui/dialog";

interface ConfirmationDialogProps {
    isOpen: boolean,
    size?: string,
    title: string,
    children: any,
    onAnswerReceived: (answer: boolean) => void
}

export default function ConfirmationDialog(props: ConfirmationDialogProps) {

    const handleYes = () => {
        props.onAnswerReceived(true);
    }

    const handleNo = () => {
        props.onAnswerReceived(false);
    }

    return (
        <Dialog
            size="lg"
            onClose={handleNo}
            initialFocus={undefined}
            open={props.isOpen}>
            <DialogTitle>{props.title}</DialogTitle>
            {/*<DialogDescription>Please agree to the following terms and conditions to continue.</DialogDescription>*/}
            <DialogBody className="text-sm/6 text-zinc-900 dark:text-white">
                {props.children}
            </DialogBody>
            <DialogActions>
                <button onClick={handleYes}>
                    Yes
                </button>
                <button onClick={handleNo}>No</button>
            </DialogActions>
        </Dialog>
    )
}
