import {LocationDefinition} from "../LocationDefinitionProvider";

export const PrimaryLocation: LocationDefinition = {
    id: "11122223-0dc7-49a0-a07f-4bd65e5c66aa",
    title: "Primary Location",
    description: "My primary location",
    phone: "",
    address: "",
    imageUrl: "",
}


export const builtInLocations: LocationDefinition[] = [
    PrimaryLocation
]