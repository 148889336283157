import React, {useEffect, useState} from "react";
import {ContactProfileHeader} from "../../../controls/renderers/Contacts/ContactProfileHeader";
import {useGuideFlowProviderContext} from "../../../../GuideFlowProviderContext";
import {useParams} from "react-router-dom";
import {ContactProgressionEvent} from "../../../../providers/contact/ContactProgressionEventProvider";
import {LoadingPanel} from "../../../controls/vidence/blanks/LoadingPanel";
import DateTimeUtcText from "../../../controls/renderers/DateTime/DateTimeUtcText";
import {ArrowPathRoundedSquareIcon, PlusCircleIcon} from "@heroicons/react/16/solid";

export function HealingBookingConfigurationTab() {

    const {id} = useParams();
    const context = useGuideFlowProviderContext();

    const [loaded, setLoaded] = useState(false);

    useEffect(() => {

        context.CalDotComProvider.getEvent(id).then(calEvent=>{
            console.log(calEvent);
        });
        setLoaded(true);

    }, [id, context.CalDotComProvider.StateChanged]);

    const handleSyncEntry = async () => {
        

    }

    if (!loaded) {
        return <LoadingPanel text="loading history..."/>
    }

    return (
        <div className="mt-3 space-y-3">
            <h3>Scheduling with cal.com</h3>
            <button
                type="button"
                onClick={() => handleSyncEntry()}
                className="relative inline-flex rounded-md items-center gap-x-1.5 bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:z-10 hover:bg-gray-50 focus:z-10">
                <ArrowPathRoundedSquareIcon className="-ml-0.5 h-5 w-5 text-gray-400" aria-hidden="true"/>Sync
            </button>
        </div>
    )
}

