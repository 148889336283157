import {AstralTravel, EmpowerThyself, SacredGeometry1, SanctuaryMeditation, SpiritualIntuition} from "./builtin/BuiltInClasses";
import {unixTimeUtcFromDate} from "../../helpers/Dates";
import {buttercup, Fezzick, humperdink, Vizzini, Westley} from "../../components/Sections/Contact/data";
import {ScheduledClass} from "./ClassDefinitionProvider";
import {PrimaryLocation} from "./builtin/BuiltInLocations";

const et: ScheduledClass = {
    id: "6ea85a53-bb95-4a64-92b9-eeb80e5aaea6",
    classDefinitionId: EmpowerThyself.id,
    locationId: PrimaryLocation.id,
    startDateTimeUtc: unixTimeUtcFromDate("2024-06-29 10:00 AM"),
    students: [
        {
            contactId: Westley.id,
            status: "fully-paid"
        },
        {
            contactId: Fezzick.id,
            status: "deposit-paid"
        }]
};

const sg1: ScheduledClass = {
    id: "ea77cd90-cb6a-475c-a912-be1398c15e0a",
    classDefinitionId: SacredGeometry1.id,
    locationId: PrimaryLocation.id,
    startDateTimeUtc: unixTimeUtcFromDate("2024-04-21 10:00 AM"),
    students: [
        {
            contactId: Westley.id,
            status: "fully-paid"
        },
        {
            contactId: Fezzick.id,
            status: "deposit-paid"
        },
        {
            contactId: Vizzini.id,
            status: "tentative"
        },]
}

const at1: ScheduledClass = {
    id: "69c0c420-22cf-42af-8a13-0b1fa5499f16",
    classDefinitionId: AstralTravel.id,
    locationId: PrimaryLocation.id,
    startDateTimeUtc: unixTimeUtcFromDate("2024-05-06 12:01 AM"),
    students: [
        {
            contactId: Westley.id,
            status: "fully-paid"
        },
        {
            contactId: Fezzick.id,
            status: "deposit-paid"
        },
        {
            contactId: Vizzini.id,
            status: "tentative"
        },]
}


const sm: ScheduledClass = {
    id: "69c0c420-4444-42af-8a13-0b1fa5499f16",
    classDefinitionId: SanctuaryMeditation.id,
    locationId: PrimaryLocation.id,
    startDateTimeUtc: unixTimeUtcFromDate("2024-04-27 10:00 AM"),
    students: [
        {
            contactId: Fezzick.id,
            status: "deposit-paid"
        },
        {
            contactId: Vizzini.id,
            status: "tentative"
        },]
}


const si: ScheduledClass = {
    id: "49c0c420-22cf-42af-8a13-0b1fa5499f16",
    classDefinitionId: SpiritualIntuition.id,
    locationId: PrimaryLocation.id,
    startDateTimeUtc: unixTimeUtcFromDate("2024-04-27 3:00 PM"),
    students: [
        {
            contactId: buttercup.id,
            status: "fully-paid"
        },
        {
            contactId: humperdink.id,
            status: "deposit-paid"
        }]
}


export const allClasses = [et, sg1, at1, sm, si];