import {useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {useGuideFlowProviderContext} from "../../../../../GuideFlowProviderContext";
import {naah} from "../../../../viewUtils";
import {PhysicalAddressText} from "../../../../controls/vidence/address/PhysicalAddressText";
import {useFriendlyText} from "../../../../../hooks/friendlyText/useFriendlyText";
import {Contact} from "../../../../../providers/contact/ContactProvider";
import {ContactStatusText} from "../../../../controls/renderers/Contacts/ContactStatusText";
import {DropMenu, DropMenuItem} from "../../../../controls/vidence/menus/dropmenu/Dropmenu";
import ConfirmationDialog from "../../../../controls/vidence/modal/ConfirmationDialog";

export default function ContactDetailsScreen() {

    // const navigate = useNavigate();
    const {id} = useParams();
    const [contact, setContact] = useState<Contact | undefined>();
    const context = useGuideFlowProviderContext();
    const {asShortDateWithYear, fromNow} = useFriendlyText();
    const [showArchiveDialog, setShowArchiveDialog] = useState(false);
    const [showUnarchiveDialog, setShowUnarchiveDialog] = useState(false);
    useEffect(() => {
        context.Contacts.get(id).then(c => {
            setContact(c);
        });
    }, [context.Contacts, context.Contacts.StateChanged, id]);


    const handleArchive = () => {
        setShowArchiveDialog(true);
        setShowUnarchiveDialog(false);
    }

    const handleUnarchive = () => {
        setShowUnarchiveDialog(true);
        setShowArchiveDialog(false);
    }
    
    const handleFinalizeArchive = async (answer: boolean) => {
        if (answer) {
            await context.Contacts.delete(id);
        }

        setShowArchiveDialog(false);
    }

    const handleFinalizeUnarchive = async (answer: boolean) => {
        if (answer) {
            await context.Contacts.undelete(id);
        }

        setShowUnarchiveDialog(false);
    }

    return (
        <>
            <ConfirmationDialog
                isOpen={showArchiveDialog}
                title="Are you sure?"
                onAnswerReceived={handleFinalizeArchive}>
                Are you sure you want to archive <b>{contact?.firstname} {contact?.surname}</b>?
                <br/>
            </ConfirmationDialog>

            <ConfirmationDialog
                isOpen={showUnarchiveDialog}
                title="Are you sure?"
                onAnswerReceived={handleFinalizeUnarchive}>
                Are you sure you want to unarchive <b>{contact?.firstname} {contact?.surname}</b>?
                <br/>
            </ConfirmationDialog>
            
            <div className="w-full overflow-hidden">
                <div className="mt-0">
                    <div className="flex-shrink-0 border-b border-gray-200 bg-white">
                        <div className="flex h-16 flex-col justify-center">
                            <div className="flex justify-between">
                                <div className="text-si text-sm text-gray-500">
                                    Latest progression: {contact?.id && <ContactStatusText id={contact?.id}/>}
                                </div>
                                <DropMenu>
                                    {contact?.storage.isArchived === false &&
                                        <>
                                            <DropMenuItem title="Edit" to="edit"/>
                                            <DropMenuItem title="Archive" onClick={handleArchive}/>
                                        </>}

                                    {contact?.storage.isArchived &&
                                        <>
                                            <DropMenuItem title="Unarchive" onClick={handleUnarchive}/>
                                        </>}
                                </DropMenu>
                            </div>
                        </div>
                    </div>
                    <dl className="grid grid-cols-1 sm:grid-cols-2">
                        <div className="border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
                            <dt className="text-sm font-medium leading-6 text-gray-900">Email address</dt>
                            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{naah(contact?.email)}</dd>
                        </div>
                        <div className="border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
                            <dt className="text-sm font-medium leading-6 text-gray-900">Phone</dt>
                            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{naah(contact?.phone)}</dd>
                        </div>
                        <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
                            <dt className="text-sm font-medium leading-6 text-gray-900">MMS Student Number</dt>
                            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{naah(contact?.mmsStudentNumber)}</dd>
                        </div>
                        <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
                            <dt className="text-sm font-medium leading-6 text-gray-900">Birthdate</dt>
                            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{naah(contact?.dateOfBirth, `${asShortDateWithYear(contact?.dateOfBirth)} (${fromNow(contact?.dateOfBirth).replace("ago", "").trim()})`)}</dd>
                        </div>
                        <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
                            <dt className="text-sm font-medium leading-6 text-gray-900">Primary Address</dt>
                            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{naah(contact?.primaryAddress, <PhysicalAddressText
                                address={contact?.primaryAddress}/>)}</dd>
                        </div>
                        {/*<div className="border-t border-gray-100 px-4 py-6 sm:col-span-2 sm:px-0">*/}
                        {/*    <dt className="text-sm font-medium leading-6 text-gray-900">About</dt>*/}
                        {/*    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">*/}
                        {/*        Fugiat ipsum ipsum deserunt culpa aute sint do nostrud anim incididunt cillum culpa consequat. Excepteur*/}
                        {/*        qui ipsum aliquip consequat sint. Sit id mollit nulla mollit nostrud in ea officia proident. Irure nostrud*/}
                        {/*        pariatur mollit ad adipisicing reprehenderit deserunt qui eu.*/}
                        {/*    </dd>*/}
                        {/*</div>*/}
                    </dl>
                </div>
            </div>
        </>
    )
}