import {
    appendDataToFile,
    AppendInstructionSet, decryptJsonContent, decryptKeyHeader,
    deleteFile,
    deletePayload,
    DotYouClient,
    HomebaseFile,
    ExternalFileIdentifier,
    FileQueryParams,
    getFileHeader,
    getFileHeaderBytes,
    getPayloadBytes,
    getThumbBytesByUniqueId,
    PayloadFile,
    queryBatch,
    StorageOptions,
    TargetDrive,
    ThumbnailFile,
    uploadFile,
    UploadFileMetadata,
    UploadInstructionSet,
    UploadResult
} from "@youfoundation/js-lib/core";
import {base64ToUint8Array, getRandom16ByteArray, uint8ArrayToBase64} from "@youfoundation/js-lib/helpers";
import axios, {AxiosProgressEvent, AxiosRequestConfig, AxiosError} from "axios";
import {find} from "lodash";
import {IndexDBProvider, UpsertPayloadRequest} from "./IndexedDb/IndexDBProvider";
import {CassiePayloadResult, CassieQueryOptions, CassieSavePayloadData} from "./CassieTypes";
import {isValidGuid} from "../GuidValidators";
import {Image64Data} from "../storage/HomebaseStorageTypes";
import {GetBatchQueryResultOptions} from "@youfoundation/js-lib/dist/core/DriveData/Drive/DriveTypes";

export interface CassieUploadOptions {
    onVersionConflict?: () => void,
    onUploadProgress?: (progressEvent: AxiosProgressEvent) => void
}

export interface CassieDownloadOptions {
    onDownloadProgress?: (progressEvent: AxiosProgressEvent) => void
}

// Connection aware storage; Note - this works with the fileId as the identifier
export class CassieProvider {

    private static instance: CassieProvider

    static getInstance() {
        if (CassieProvider.instance == null) {
            CassieProvider.instance = new CassieProvider();
        }

        return CassieProvider.instance;
    }

    public async getPayload(dotYouClient: DotYouClient, file: ExternalFileIdentifier, key: string, options?: CassieDownloadOptions): Promise<CassiePayloadResult | null> {

        const ldp = IndexDBProvider.getInstance();
        const payloadRecord = await ldp.GetPayload(file, key);

        if (payloadRecord != null) {
            return {
                key: key,
                descriptorContent: payloadRecord.descriptor,
                contentType: payloadRecord.contentType,
                content64: payloadRecord.data
            }
        }

        if (!window.navigator.onLine) {
            return Promise.resolve(null);
        }

        // Get from server
        // console.debug("No local payload found, Getting payload from server", file.fileId, key);

        const header = await getFileHeader(dotYouClient, file.targetDrive, file.fileId);
        if (header == null) {
            console.debug("No header on server", file.fileId, key);
            return Promise.resolve(null);
        }

        const descriptor = find(header.fileMetadata.payloads, p => p.key.toLowerCase() === key.toLowerCase());
        if (null == descriptor) {
            return Promise.resolve(null);
        }

        const payload = await getPayloadBytes(dotYouClient, file.targetDrive, file.fileId, key, {
            decrypt: true,
            axiosConfig: this.getAxiosRequestConfig(options)
        });

        if (payload == null) {
            return Promise.resolve(null);
        }

        const saveData: CassieSavePayloadData = {
            key: key,
            contentType: descriptor.contentType,
            descriptorContent: descriptor.descriptorContent ?? "",
            content: uint8ArrayToBase64(payload.bytes),
            thumbnails: []
        }

        await this.cachePayload(file, saveData);

        return {
            key: key,
            contentType: descriptor.contentType,
            descriptorContent: descriptor.descriptorContent ?? "",
            content64: uint8ArrayToBase64(payload.bytes)
        }
    }

    public async queryFiles(dotYouClient: DotYouClient, params: FileQueryParams, options: CassieQueryOptions): Promise<HomebaseFile[]> {

        const db = IndexDBProvider.getInstance();
        // options.forceServerCall = true;
        const opResult = await this.tryQueryServerAndSync(dotYouClient, params, options);

        if (opResult.serverWasQueried) {
            return opResult.results;
        }

        //since we hit the server, sync the results
        const results = await db.Query(params);

        if (results.length === 0) {
            const ops2 = await this.tryQueryServerAndSync(dotYouClient, params, {...options, forceServerCall: true});
            return ops2.serverWasQueried ? ops2.results : [];
        }

        return results;
    };

    public async getByIdList(dotYouClient: DotYouClient, idList: string[], targetDrive: TargetDrive, options: CassieQueryOptions): Promise<HomebaseFile[]> {
        const params: FileQueryParams = {
            targetDrive: targetDrive,
            clientUniqueIdAtLeastOne: idList
        }

        const opResult = await this.tryQueryServerAndSync(dotYouClient, params, options);

        if (opResult.serverWasQueried) {
            return opResult.results;
        }

        const db = IndexDBProvider.getInstance();
        const results = await db.QueryUniqueIdByList(idList, targetDrive);
        if (results.length === 0) {
            const ops2 = await this.tryQueryServerAndSync(dotYouClient, params, {...options, forceServerCall: true});
            return ops2.serverWasQueried ? ops2.results : [];
        }

        return results;
    };

    //Queries the server if the options allow and if we're online
    private async tryQueryServerAndSync(dotYouClient: DotYouClient, params: FileQueryParams, options: CassieQueryOptions): Promise<{
        serverWasQueried: boolean,
        results: HomebaseFile[]
    }> {
        if (options.forceServerCall && window.navigator.onLine) {
            const resultOptions: GetBatchQueryResultOptions = {maxRecords: 10000, ordering: "newestFirst", sorting: "fileId", includeMetadataHeader: true};

            const batchResponse = await queryBatch(dotYouClient, params, resultOptions);

            // console.log('br', batchResponse);

            let decryptedHeaders = (
                await Promise.all(batchResponse.searchResults.map(async (dsr: HomebaseFile) => {
                    if (dsr.fileMetadata.isEncrypted) {
                        const key = await decryptKeyHeader(dotYouClient, dsr.sharedSecretEncryptedKeyHeader);
                        dsr.fileMetadata.appData.content = await decryptJsonContent(dsr.fileMetadata, key);
                    }
                }))
            ).filter(Boolean) as [];

            const db = IndexDBProvider.getInstance();
            await db.UpsertHeaders(decryptedHeaders, params.targetDrive);
            return {
                serverWasQueried: true,
                results: batchResponse.searchResults
            };

        }

        return {
            serverWasQueried: false,
            results: []
        };
    }

    public async getFileHeader(dotYouClient: DotYouClient, file: ExternalFileIdentifier, options?: CassieQueryOptions): Promise<HomebaseFile | null> {
        let dsr = await IndexDBProvider.getInstance().GetByFileId(file);

        if (null == dsr && window.navigator.onLine) {
            dsr = await getFileHeader(dotYouClient, file.targetDrive, file.fileId);
        }

        return dsr;
    }

    public async saveHeaderFile(dotYouClient: DotYouClient, metadata: UploadFileMetadata, storage: StorageOptions,
                                payload: CassieSavePayloadData | undefined,
                                options: CassieUploadOptions | undefined): Promise<UploadResult | void> {

        this.assertIsOnline();

        // If updating a file, you must have a version tag
        if (storage?.overwriteFileId && !isValidGuid(metadata.versionTag)) {
            throw Error(`Version tag is missing or invalid:[${metadata.versionTag}]`,);
        }

        const instructionSet: UploadInstructionSet = {
            transferIv: getRandom16ByteArray(),
            storageOptions: {
                ...storage,
                storageIntent: undefined
            },
            transitOptions: undefined,
        };

        function handleVersionConflict() {
            if (options?.onVersionConflict) {
                options.onVersionConflict();
            }
        }

        let thumbnails: ThumbnailFile[];
        const payloads: PayloadFile[] = [];
        if (payload) {
            const p = this.cassiePayloadToPayloadFile(payload);
            payloads.push(p.payloadFile);
            thumbnails = p.thumbnails;
        }

        try {
            const encrypt = metadata.isEncrypted;

            const uploadResult: UploadResult | void = await uploadFile(dotYouClient, instructionSet, metadata, payloads, thumbnails, encrypt, handleVersionConflict, this.getAxiosRequestConfig(options));

            if (uploadResult) {
                // Store it locally
                const targetDrive = uploadResult.file.targetDrive;
                // const newHeader = await getFileHeader(dotYouClient, targetDrive, uploadResult.file.fileId);
                const newHeaderEncrypted = await getFileHeaderBytes(dotYouClient, targetDrive, uploadResult.file.fileId, {decrypt: true});

                if (null != newHeaderEncrypted) {
                    await IndexDBProvider.getInstance().UpsertHeaders([newHeaderEncrypted], targetDrive);
                }

                return uploadResult;
            }
        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.log(error.status)
                console.error(error.response);
                // Do something with this error...
            } else {
                console.error(error);
            }

            throw error;
        }
    };

    public async savePayload(dotYouClient: DotYouClient, file: ExternalFileIdentifier, versionTag: string, payload: CassieSavePayloadData, options?: CassieUploadOptions): Promise<boolean> {

        this.assertIsOnline();

        const targetFile = await this.getFileHeader(dotYouClient, file);

        if (null == targetFile) {
            throw new Error("Invalid file");
        }

        const keyHeader = targetFile.sharedSecretEncryptedKeyHeader;
        const instructions: AppendInstructionSet = {
            targetFile: {
                fileId: file.fileId,
                targetDrive: file.targetDrive
            },
            versionTag: versionTag
        }

        const p = this.cassiePayloadToPayloadFile(payload);

        //var thumbnailKey = $"{payloadDefinition.Key}{thumbnail.PixelWidth}{thumbnail.PixelHeight}";
        // const thumbnails: ThumbnailFile[] = payload.thumbnails?.map(t => ({
        //     ...t,
        //     key: `${t.key}${t.pixelWidth}`
        // }));

        const o = options == null ? {
            onVersionConflict: undefined
        } : options;

        const result = await appendDataToFile(dotYouClient, keyHeader, instructions, [p.payloadFile], p.thumbnails, o.onVersionConflict, this.getAxiosRequestConfig(o))

        if (result?.newVersionTag) {
            //Sync the whole header because uploading a payload means changing file header
            const syncResult = await this.syncFileHeader(dotYouClient, file);

            if (syncResult.header) {
                await this.cachePayload(file, payload);
            }

            return true;
        }

        return false;
    }

    public async hardDeleteFile(dotYouClient: DotYouClient, fileId: string, targetDrive: TargetDrive): Promise<boolean> {

        this.assertIsOnline();

        const serverDeletionResult = await deleteFile(dotYouClient, targetDrive, fileId);
        if (serverDeletionResult) {
            await IndexDBProvider.getInstance().HardDeleteFile(fileId, targetDrive);
            return true;
        }
        return false;
    };

    public async deletePayload(dotYouClient: DotYouClient, fileId: string, versionTag: string, targetDrive: TargetDrive, payloadKey: string): Promise<{
        newVersionTag: string
    }> {

        this.assertIsOnline();

        const serverDeletePayloadResult = await deletePayload(dotYouClient, targetDrive, fileId, payloadKey, versionTag);

        if (serverDeletePayloadResult) {
            await IndexDBProvider.getInstance().DeletePayload(fileId, targetDrive, payloadKey);
        }

        return serverDeletePayloadResult;
    }

    public async getThumbnailByUniqueId(dotYouClient: DotYouClient, uniqueId: string, targetDrive: TargetDrive, payloadKey: string, width: number, height: number): Promise<Image64Data> {

        // Get a local thumbnail first, fall back to calling the server if online 
        const ldp = IndexDBProvider.getInstance();
        let localThumbnail = await ldp.GetThumbnailByUniqueId(uniqueId, targetDrive, payloadKey, width, height);

        if (localThumbnail) {
            return {
                width: width,
                height: height,
                base64Data: localThumbnail.data,
                mimeType: localThumbnail.contentType
            };
        }

        if (!window.navigator.onLine) {
            return Promise.resolve(null);
        }

        const options = undefined;
        const thumb = await getThumbBytesByUniqueId(dotYouClient, targetDrive, uniqueId, payloadKey, width, height, options);

        if (thumb) {
            return {
                width: width,
                height: height,
                base64Data: uint8ArrayToBase64(new Uint8Array(thumb.bytes)),
                mimeType: thumb.contentType
            };
        }
        return null;
    }

    // Syncs the file header from the server and stores in local database
    private async syncFileHeader(dotYouClient: DotYouClient, file: ExternalFileIdentifier,): Promise<{
        success: boolean,
        header: HomebaseFile | null
    }> {
        const targetDrive = file.targetDrive;

        const dsr = await getFileHeaderBytes(dotYouClient, targetDrive, file.fileId, {decrypt: true});

        //TODO: Handle if file is hard deleted?
        if (null == dsr) {
            return {success: false, header: null}
        }

        await IndexDBProvider.getInstance().UpsertHeaders([dsr], targetDrive);
        return {success: true, header: dsr}
    }

    private async cachePayload(file: ExternalFileIdentifier, payload: CassieSavePayloadData) {

        const request: UpsertPayloadRequest = {
            fileId: file.fileId,
            targetDrive: file.targetDrive,
            payloadKey: payload.key,
            contentType: payload.contentType,
            thumbnails: payload.thumbnails,
            descriptor: payload.descriptorContent,
            payloadData: this.isBlobOrString(payload.content) === "string" ?
                payload.content as string :
                uint8ArrayToBase64(new Uint8Array(await (payload.content as Blob).arrayBuffer())),
        };

        await IndexDBProvider.getInstance().UpsertPayload(request);
    }

    private isBlobOrString(value: any): "blob" | "string" | "unknown" {
        if (typeof value === 'string') {
            return 'string';
        } else if (value instanceof Blob) {
            return 'blob';
        } else {
            return 'unknown';
        }
    }

    private getAxiosRequestConfig(options?: CassieDownloadOptions | CassieUploadOptions): AxiosRequestConfig {
        if (!options) {
            return {}
        }

        return {
            onDownloadProgress: (options as CassieDownloadOptions)?.onDownloadProgress,
            onUploadProgress: (options as CassieUploadOptions)?.onUploadProgress
        }
    }

    private cassiePayloadToPayloadFile(payload: CassieSavePayloadData): { payloadFile: PayloadFile, thumbnails: ThumbnailFile[] } {
        const blob = this.isBlobOrString(payload.content) === "blob" ?
            payload.content as Blob :
            new Blob([base64ToUint8Array(payload.content as string)], {
                type: payload.contentType,
            }) as any as Blob;


        return {
            payloadFile: {
                key: payload.key,
                descriptorContent: payload.descriptorContent,
                payload: blob
            },
            thumbnails: payload.thumbnails
        }
    }

    private assertIsOnline() {
        if (!window.navigator.onLine) {
            throw new Error("Cannot save file offline");
        }
    }

    private handleRequestError(error: AxiosError) {
        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log("data", error.response.data);
            console.log("status", error.response.status);
            console.log("headers", error.response.headers);
        } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
        }
        console.log(error.config);

        return null;
    }

}