import {useState} from "react";
import {useYouAuthAuthorization} from "../hooks/auth/useAuth";
import {useQuery} from "@tanstack/react-query";
import {stringifyToQueryParams} from "@youfoundation/js-lib/helpers";
import {t} from "../helpers/i18n/dictionary";
import {getAppDefinition} from "../system/AppDefinition";

const useParams = (returnUrl: string) => {
    const {getAuthorizationParameters} = useYouAuthAuthorization();

    return useQuery({
        queryKey: ['params'],
        queryFn: () => getAuthorizationParameters(returnUrl),
        refetchOnWindowFocus: false,
        refetchOnMount: false,
    });
};

export default function Login() {
    const [odinId, setOdinId] = useState<string>("");
    // const domainRegex = /^(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9]{2,25}(?::\d{1,5})?$/i;

    const appDefinition = getAppDefinition();
    // console.log('aa', appDefinition);

    const {data: authParams} = useParams('/'); //TODO: update to support deep link

    const handleLogin = async (e) => {
        e.preventDefault();

        const stringifiedAuthParams = authParams && stringifyToQueryParams(authParams as any);
        window.top.location.href = `https://${odinId}/api/owner/v1/youauth/authorize?${stringifiedAuthParams}`;
    }

    return (
        <div className="flex min-h-full flex-1">
            <div className="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
                <div className="mx-auto w-full max-w-sm lg:w-96">
                    <div>
                        <div className="flex-row flex">
                            <img
                                className="h-10 w-auto"
                                src="/icons/android-icon-144x144.png"
                                alt="Guideflow"
                            />
                            <span className="ml-3 text-2xl font-bold leading-9 tracking-tight text-gray-900">
                                {appDefinition.name}
                            </span>
                        </div>
                        <h2 className="mt-8 text-xl leading-9 tracking-tight text-gray-900">
                            Sign in using your Homebase Identity
                        </h2>
                        <p className="mt-2 text-sm leading-6 text-gray-500">
                            <a href="https://homebase.id/" className="font-semibold text-indigo-600 hover:text-indigo-500">
                                Create a free Homebase Account
                            </a>
                        </p>
                    </div>

                    <div className="mt-10">
                        <div>
                            <form onSubmit={handleLogin} className="space-y-6">
                                <div>
                                    <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                                        Homebase Id
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            id="homebaseId"
                                            name="homebaseId"
                                            type="text"
                                            autoComplete="none"
                                            required
                                            onChange={e => setOdinId(e.target.value)}
                                            className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        />
                                    </div>
                                </div>

                                {/*<div className="flex items-center justify-between">*/}
                                {/*    <div className="flex items-center">*/}
                                {/*        <input*/}
                                {/*            id="remember-me"*/}
                                {/*            name="remember-me"*/}
                                {/*            type="checkbox"*/}
                                {/*            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"*/}
                                {/*        />*/}
                                {/*        <label htmlFor="remember-me" className="ml-3 block text-sm leading-6 text-gray-700">*/}
                                {/*            Remember me*/}
                                {/*        </label>*/}
                                {/*    </div>*/}

                                {/*    <div className="text-sm leading-6">*/}
                                {/*    </div>*/}
                                {/*</div>*/}

                                <div>
                                    <button
                                        type="submit"
                                        className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                                        {t('login')}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div className="relative hidden w-0 flex-1 lg:block">
                <img
                    className="absolute inset-0 h-full w-full object-cover"
                    src="https://images.unsplash.com/photo-1702261834413-3d457d7cf683?q=80&w=2828&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                    alt="Angel Initiation"
                />
            </div>
        </div>
    )
}