import {DropEvent, useDropzone} from "react-dropzone";
import React, {useState} from "react";
import {DocumentArrowUpIcon} from "@heroicons/react/20/solid";
import {Dialog, DialogActions, DialogBody} from "../../../tailwindui/dialog";
import SpinnerIcon from "../../../icons/SpinnerIcon";

interface FileUploadDialogProps {
    maxFiles?: number;
    header?: Element | string;
    isOpen: boolean,
    onClose: (value: boolean) => void,
    onFilesSelected: (files: File[]) => void;
}

export default function FileUploadDialog(props: FileUploadDialogProps) {

    const [availableFiles, setAvailableFiles] = useState<File[]>([]);

    const handleDropAccepted = (files: File[], event: DropEvent) => {
        setAvailableFiles(files);
    }

    const {getRootProps, getInputProps} = useDropzone(
        {
            onDropAccepted: handleDropAccepted,
            accept: {
                // 'image/png': ['.png'],
                // 'text/html': ['.html', '.htm'],
            }
        });

    const handleDialogClose = () => {
        setAvailableFiles([]);
        props.onClose(false);
    }

    const handleUploadPressed = () => {
        props.onFilesSelected(availableFiles);
        setAvailableFiles([]);
        props.onClose(false);
    }

    return (<Dialog
        size="3xl"
        onClose={props.onClose}
        initialFocus={undefined}
        open={props.isOpen}>

        {/*<DialogTitle>Terms and conditions</DialogTitle>*/}
        {/*<DialogDescription>Please agree to the following terms and conditions to continue.</DialogDescription>*/}

        <DialogBody className="text-sm/6 text-zinc-900 dark:text-white">
            <div className="flex flex-col items-center justify-center">
                {availableFiles?.length === 0 &&
                    <div {...getRootProps({className: 'dropzone'})}>
                        <input {...getInputProps()} />
                        <div className="flex flex-col items-center justify-center pt-5 pb-6">
                            <svg className="w-10 h-10 mb-3 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                      d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path>
                            </svg>
                            <p className="mb-2 text-sm text-gray-500 dark:text-gray-400"><span className="font-semibold">Click to upload</span> or drag and drop
                            </p>
                            {/*<p className="text-xs text-gray-500 dark:text-gray-400">SVG, PNG, JPG or GIF (MAX. 800x400px)</p>*/}
                        </div>
                    </div>}
            </div>

        </DialogBody>
        <DialogActions>
            {availableFiles?.length > 0 &&
                <div>
                    <div>
                        {availableFiles.map((file) => (
                            <li key={file.name} className="flex items-center justify-between gap-x-6">
                                <div className="flex flex-none gap-x-4">
                                    <SpinnerIcon/>
                                </div>
                                <div className="min-w-0">
                                    <div className="flex items-start gap-x-3">
                                        <p className="text-sm font-semibold leading-6 text-gray-600">{file.name}</p>
                                    </div>
                                </div>
                            </li>
                        ))}
                    </div>

                    <div className="flex flex-row mt-3 justify-between w-full">
                        <button
                            type="button"
                            onClick={handleUploadPressed}
                            className="relative inline-flex items-center gap-x-1.5 bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:z-10 hover:bg-gray-50 focus:z-10">
                            <DocumentArrowUpIcon className="-ml-0.5 h-5 w-5 text-gray-400" aria-hidden="true"/>
                            Upload
                        </button>

                        <button
                            type="button"
                            onClick={handleDialogClose}
                            className="relative inline-flex items-center gap-x-1.5 bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:z-10 hover:bg-gray-50 focus:z-10">
                            Cancel
                        </button>
                    </div>
                </div>
            }
        </DialogActions>
    </Dialog>)
}