import React, {useEffect, useState} from "react";
import {observer} from "mobx-react-lite";
import {LocationDefinition} from "../../../../../providers/definitions/LocationDefinitionProvider";
import {useGuideFlowProviderContext} from "../../../../../GuideFlowProviderContext";
import {LoadingPanel} from "../../../../controls/vidence/blanks/LoadingPanel";
import EmptyScreen from "../../../../controls/vidence/blanks/EmptyScreenAction";
import {DropMenu, DropMenuItem} from "../../../../controls/vidence/menus/dropmenu/Dropmenu";
import {PlusCircleIcon} from "@heroicons/react/16/solid";
import ConfirmationDialog from "../../../../controls/vidence/modal/ConfirmationDialog";
import AddEditLocationDialog from "./AddEditLocationDialog";
import DateTimeUtcText from "../../../../controls/renderers/DateTime/DateTimeUtcText";

export const LocationsList = observer(() => {

    const [locationForDeletion, setLocationForDeletion] = useState<LocationDefinition | null>(null);
    const [showAddLocation, setShowAddLocation] = useState(false);
    const [selectedLocation, setSelectedLocation] = useState<string | null>(null);
    const [locations, setLocations] = useState<LocationDefinition[]>();
    const [loaded, setLoaded] = useState(false);
    const context = useGuideFlowProviderContext();

    useEffect(() => {
        context.Locations.getAll().then((locations) => {
            setLoaded(true);
            setLocations(locations);
        });
    }, [context.Locations, context.Locations.StateChanged]);

    const deleteLocation = async (document: LocationDefinition) => {
        setLocationForDeletion(document);
    }

    const handleFinalizeDelete = async (answer: boolean) => {
        if (answer) {
            await context.Locations.delete(locationForDeletion.id);
        }

        setLocationForDeletion(null);
    }

    if (!loaded) {
        return (<LoadingPanel text="loading locations"/>);
    }

    if (loaded) {
        return (
            <div className="w-full">

                <ConfirmationDialog
                    isOpen={locationForDeletion !== null}
                    title="Are you sure?"
                    onAnswerReceived={handleFinalizeDelete}>
                    Are you sure you want to delete <b>{locationForDeletion?.title}</b>
                </ConfirmationDialog>

                <AddEditLocationDialog
                    locationDefinitionId={selectedLocation}
                    isOpen={(selectedLocation !== null) || showAddLocation}
                    onClose={() => {
                        setSelectedLocation(null);
                        setShowAddLocation(false)
                    }}/>

                <div className="flex h-16 flex-col justify-center">
                    <div className="flex justify-between py-3">
                        {/* Left buttons */}
                        <div className="isolate inline-flex rounded-md shadow-sm sm:space-x-3 sm:shadow-none">
                              <span className="inline-flex sm:shadow-sm">
                                <button
                                    type="button"
                                    onClick={() => setShowAddLocation(true)}
                                    className="relative inline-flex rounded-md items-center gap-x-1.5 bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:z-10 hover:bg-gray-50 focus:z-10">
                                  <PlusCircleIcon className="-ml-0.5 h-5 w-5 text-gray-400" aria-hidden="true"/> Add
                                </button>
                              </span>
                        </div>
                    </div>
                </div>

                {loaded && locations?.length === 0 &&
                    <EmptyScreen text="Looks empty in here... Add a location" onClick={() => setShowAddLocation(true)}/>}

                {loaded && locations?.length > 0 &&
                    <ul className="divide-y divide-gray-100">
                        {locations.map((loc) => (
                            <li key={loc.id} className="flex items-center justify-between gap-x-3 py-5">
                                {/*<div className="min-w-16">*/}
                                {/*    <Thumbnail document={doc} className="h-16 w-16 border border-gray-300 bg-white text-gray-300"/>*/}
                                {/*</div>*/}
                                <div className="flex flex-row w-full justify-between gap-x-1">
                                    <div className="min-w-0 max-w-80">
                                        <div className="flex items-start gap-x-3">
                                            <p className="truncate text-sm font-semibold leading-6 text-gray-900">{loc.title}</p>
                                            {/*<p*/}
                                            {/*    className={classNames(*/}
                                            {/*        statuses[doc.status],*/}
                                            {/*        'rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset'*/}
                                            {/*    )}>*/}
                                            {/*    {doc.status}*/}
                                            {/*</p>*/}
                                            {/*<p className="rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset">*/}
                                            {/*    abcd*/}
                                            {/*</p>*/}
                                        </div>
                                        <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                                            <p className="whitespace-nowrap">
                                                Created <DateTimeUtcText date time fromNow datetime={loc.storage.created}/>
                                            </p>
                                            <p className="truncate">{loc.description}</p>
                                        </div>
                                    </div>
                                    <div className="flex flex-none items-center gap-x-4">
                                        <DropMenu>
                                            <DropMenuItem title="Edit" onClick={() => setSelectedLocation(loc.id)}/>
                                            <DropMenuItem title="Delete" onClick={() => deleteLocation(loc)}/>
                                        </DropMenu>
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                }
            </div>)
    }
});