import {ReactElement} from "react";

interface DashboardHeaderProps {
    title: string,
    description: string,
    children: any,
    menu?: ReactElement
}

export default function DashboardHeader(props: DashboardHeaderProps) {
    return (
        <div className="mt-3 bg-white p-5 rounded w-full">
            <div className="border-b border-gray-200 ">
                <div className="sm:flex sm:items-baseline sm:justify-between">
                    <div className="sm:w-0 sm:flex-1 mb-2">
                        <h1 id="message-heading" className="text-base font-semibold leading-6 text-gray-900">
                            {props.title}
                        </h1>
                        <p className="mt-1 truncate text-xs text-gray-500">{props.description}</p>
                    </div>
                    
                    <div className="mt-4 flex items-center justify-between sm:ml-3 sm:mt-0 sm:flex-shrink-0 sm:justify-start">
                        {props.menu}
                    </div>
                </div>
            </div>
            {props.children}
        </div>
    )
}