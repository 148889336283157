import React, {useEffect, useState} from "react";
import {useGuideFlowProviderContext} from "../../../../../GuideFlowProviderContext";
import {Dialog, DialogActions, DialogBody, DialogDescription, DialogTitle} from "../../../../controls/tailwindui/dialog";
import SpinnerIcon from "../../../../controls/icons/SpinnerIcon";
import {useForm} from "react-hook-form";
import toast from "react-hot-toast";
import {ErrorMessage, Field, Label} from "../../../../controls/tailwindui/fieldset";
import {Input} from "../../../../controls/tailwindui/input";
import {Textarea} from "../../../../controls/tailwindui/textarea";
import {Button} from "../../../../controls/tailwindui/button";
import {emptyLocationDefinition} from "./LocationFormTypes";
import {LocationDefinition} from "../../../../../providers/definitions/LocationDefinitionProvider";

interface AddLocationDialogProps {
    isOpen: boolean,
    locationDefinitionId: string;
    onClose: (value: boolean) => void,
}

export default function AddEditLocationDialog(props: AddLocationDialogProps) {

    const context = useGuideFlowProviderContext();
    const [loaded, setLoaded] = useState(false);
    const [isSaving, setIsSaving] = useState(false);

    const {register, reset, handleSubmit, formState: {errors}} = useForm({defaultValues: emptyLocationDefinition});

    const isAddingLocation = !props.locationDefinitionId;

    useEffect(() => {
        if (!isAddingLocation) {
            context.Locations.get(props.locationDefinitionId).then(location => {
                reset(location);
                setLoaded(true);
            });
        }
    }, [props, isAddingLocation, reset, context.Locations, context.Locations.StateChanged]);

    const onSubmit = async (formData: LocationDefinition) => {
        setIsSaving(true);
        await context.Locations.save(formData);
        reset(emptyLocationDefinition);
        toast.success(isAddingLocation ? "Location Added" : "Location Updated", {icon: "🪄"});
        setIsSaving(false);
        props.onClose(true);
    };

    const handleDialogClose = () => {
        props.onClose(false);
    }

    if (!isAddingLocation && !loaded) {
        return (<Dialog
            size="5xl"
            onClose={handleDialogClose}
            initialFocus={undefined}
            open={props.isOpen}>
            <DialogTitle>Loading</DialogTitle>
            <DialogBody>
                <SpinnerIcon/> Loading...
            </DialogBody>
        </Dialog>);
    }

    const dialogTitle = isAddingLocation ? "New Location" : "TODO";
    return (

        <Dialog
            size="lg"
            onClose={handleDialogClose}
            initialFocus={undefined}
            open={props.isOpen}>
            <DialogTitle>{dialogTitle}</DialogTitle>
            <DialogDescription>Locations are places you do your work.</DialogDescription>
            <DialogBody className="text-sm/6 text-zinc-900 dark:text-white">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="xl:gap-x-16">
                        <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
                            <div>
                                <Field>
                                    <Label>Title</Label>
                                    <Input {...register("title", {required: true,})} invalid={errors?.title != null} autoFocus={true}/>
                                    {errors?.title && <ErrorMessage>*{errors.title.message}</ErrorMessage>}
                                </Field>
                            </div>
                            <div>
                                <Field>
                                    <Label>Phone</Label>
                                    <Input {...register("phone", {required: false,})}/>
                                </Field>
                            </div>
                            <div className="sm:col-span-2">
                                <Field>
                                    <Label>Description</Label>
                                </Field>
                                <Textarea {...register("description")}/>
                            </div>
                            <div className="sm:col-span-2">
                                <Field>
                                    <Label>Address</Label>
                                </Field>
                                <Textarea {...register("address")}/>
                            </div>
                        </div>

                        <div className="mt-3 space-x-3 justify-end flex-row flex w-full">
                            <Button type="button" disabled={isSaving} onClick={() => props.onClose(false)} outline={true}>
                                Cancel
                            </Button>
                            <Button
                                type="submit"
                                disabled={isSaving}
                                color="indigo">
                                {isSaving && <SpinnerIcon/>}
                                Save
                            </Button>
                        </div>
                    </div>
                </form>

            </DialogBody>
            <DialogActions>


            </DialogActions>

        </Dialog>
    );
}