import {
    decryptJsonContent,
    decryptKeyHeader,
    DotYouClient,
    ExternalFileIdentifier,
    FileQueryParams,
    GetBatchQueryResultOptions,
    getFileHeaderByUniqueId,
    HomebaseFile,
    queryBatch,
    queryModified,
    SecurityGroupType,
    StorageOptions,
    UploadFileMetadata,
    UploadResult
} from "@youfoundation/js-lib/core";
import {jsonStringify64, tryJsonParse} from "@youfoundation/js-lib/helpers";
import {GetModifiedResultOptions} from "@youfoundation/js-lib/dist/core/DriveData/Drive/DriveTypes";
import {CassieProvider, CassieUploadOptions} from "../cassie/CassieProvider";
import {
    ARCHIVAL_STATUS_DELETED,
    ARCHIVAL_STATUS_NONE_SPECIFIED,
    HomebaseStorage,
    Image64Data,
    ImageHeader,
    SaveFileConfig,
    SaveOptions
} from "./HomebaseStorageTypes";
import {CassiePayloadResult, CassieQueryOptions, CassieSavePayloadData} from "../cassie/CassieTypes";
import {IndexDBProvider} from "../cassie/IndexedDb/IndexDBProvider";
import {isValidGuid} from "../GuidValidators";
import {isArray} from "lodash";
import {normalizeGuid} from "../../DataHelpers";

export type FileQueryParamsWithoutTargetDrive = Omit<FileQueryParams, 'targetDrive'>;

interface SimpleStorageExport {
    name: string,
    docs: any
    payloads: any
}

//Storage provider that uses the uniqueId as the primary key for entities; wraps Cassie
export class SimplifiedStorageProvider<T extends HomebaseStorage> {

    private config!: SaveFileConfig;
    private readonly dotYouClient!: DotYouClient;

    constructor(config: SaveFileConfig, dotYouClient: DotYouClient) {
        this.config = config;
        this.dotYouClient = dotYouClient;
    }

    private GetCassie() {
        return CassieProvider.getInstance();
    }

    public async getAll<T extends HomebaseStorage>(options?: {
        groupId?: string | string[],
        dataType?: number,
        includeArchived?: boolean,
        tagsMatchAtLeastOne?: string[] | undefined;
        tagsMatchAll?: string[] | undefined;
        cassieQueryOptions?: CassieQueryOptions
    }): Promise<T[]> {

        const params: FileQueryParams = {
            targetDrive: this.config.targetDrive,
            fileType: [this.config.fileType],
            archivalStatus: [ARCHIVAL_STATUS_NONE_SPECIFIED]
        };
        
        if(options?.includeArchived)
        {
            params.archivalStatus.push(ARCHIVAL_STATUS_DELETED);
        }

        if (options?.dataType) {
            params.dataType = [options.dataType]
        }

        if (options?.groupId) {
            //TODO: do i need to call normalizeGuid here?
            params.groupId = isArray(options.groupId) ? options.groupId : [options.groupId];
        }

        if (options?.tagsMatchAtLeastOne) {
            params.tagsMatchAtLeastOne = options.tagsMatchAtLeastOne;
        }

        if (options?.tagsMatchAll) {
            params.tagsMatchAll = options.tagsMatchAll;
        }

        const queryOptions: CassieQueryOptions = {
            ...options?.cassieQueryOptions
        }

        const results = await this.GetCassie().queryFiles(this.dotYouClient, params, queryOptions);

        // console.log('r', results);
        return (
            await Promise.all(results.map(async (dsr) => {
                // console.log('tt', dsr.fileMetadata.appData.tags)
                return await this.getContent<T>(dsr);
            }))
        ).filter(Boolean) as T[];
    }

    public async getList<T extends HomebaseStorage>(idList: string[]): Promise<T[]> {

        if (idList.length === 0) {
            return [];
        }

        const queryOptions: CassieQueryOptions = {
            forceServerCall: false
        }

        const results = await this.GetCassie().getByIdList(this.dotYouClient, idList, this.config.targetDrive, queryOptions);

        return (
            await Promise.all(results.map(async (dsr) => {
                return await this.getContent<T>(dsr);
            }))
        ).filter(Boolean) as T[];
    }

    public async getByUniqueId<T extends HomebaseStorage>(id: string): Promise<T | null> {

        const params: FileQueryParams = {
            targetDrive: this.config.targetDrive,
            fileType: [this.config.fileType],
            clientUniqueIdAtLeastOne: [normalizeGuid(id)]
        };

        const options: CassieQueryOptions = {
            forceServerCall: false
        }

        const results = await this.GetCassie().queryFiles(this.dotYouClient, params, options);

        if (results.length > 0) {
            return await this.getContent<T>(results[0]);
        }

        return null;
    }

    async getPayload(uniqueId: string, key: string): Promise<CassiePayloadResult | null> {

        const o = await this.getByUniqueId(uniqueId);
        if (null == o?.storage?.fileId) {
            // throw new Error(`No file found with uniqueId: [${uniqueId}]`);
            console.warn(`No file found with uniqueId: [${uniqueId}] while attempting to get payload`);
            return null;
        }

        const file: ExternalFileIdentifier = {
            fileId: o.storage.fileId,
            targetDrive: this.config.targetDrive
        }

        const cassieResult = await this.GetCassie().getPayload(this.dotYouClient, file, key);

        if (cassieResult) {
            return {
                key: key,
                contentType: cassieResult.contentType,
                content64: cassieResult.content64,
                descriptorContent: cassieResult.descriptorContent ?? ""
            }
        }

        return null;
    }

    async getImagePayload(uniqueId: string, key: string): Promise<Image64Data | null> {

        const data: CassiePayloadResult | null = await this.getPayload(uniqueId, key);

        if (!data) {
            return null;
        }

        const header: ImageHeader = tryJsonParse<ImageHeader>(data.descriptorContent || "");

        return {
            ...header,
            base64Data: data.content64
        }
    }

    async getThumbnailByUniqueId(uniqueId: string, payloadKey: string, width: number, height: number): Promise<Image64Data> {
        return await this.GetCassie().getThumbnailByUniqueId(this.dotYouClient, uniqueId, this.config.targetDrive, payloadKey, width, height)
    }

    public async saveHeaderFile(headerData: T, payload?: CassieSavePayloadData | undefined, options?: SaveOptions | undefined): Promise<UploadResult | void> {
        const headerJson: string = jsonStringify64({
            ...headerData,
            storage: undefined //remove fileId and other storage related stuff
        } as T);

        if (headerData.storage?.fileId && !isValidGuid(headerData.storage.versionTag)) {
            throw Error(`Version tag is missing or invalid:[${headerData.storage.versionTag}]`,);
        }

        if (!headerData?.id) {
            throw Error("Id field is required and must be unique");
        }

        if (!isValidGuid(headerData.id)) {
            throw Error("Id field must be a valid GUID");
        }

        if (headerData.storage?.fileId) {
            // console.log('Overwriting file', headerData.storage);
        }

        const metadata: UploadFileMetadata = {
            allowDistribution: false,
            versionTag: headerData.storage?.versionTag ?? "",
            appData: {
                uniqueId: headerData.id,
                fileType: this.config.fileType,
                dataType: options?.overrideDataType ?? this.config.dataType,
                groupId: options?.groupId,
                userDate: options?.userDate,
                content: headerJson,
                archivalStatus: ARCHIVAL_STATUS_NONE_SPECIFIED,
                previewThumbnail: options?.previewThumbnail,
                tags: options?.tags
            },
            isEncrypted: true,
            accessControlList: {requiredSecurityGroup: SecurityGroupType.Owner},
        };

        const storageOptions: StorageOptions = {
            overwriteFileId: headerData.storage?.fileId || undefined,
            drive: this.config.targetDrive
        }

        const saveOptions: CassieUploadOptions = {
            onUploadProgress: undefined,
            onVersionConflict: options?.onVersionConflict
        }

        const uploadResult: UploadResult | void = await this.GetCassie().saveHeaderFile(this.dotYouClient, metadata, storageOptions, payload, saveOptions);

        if (uploadResult) {
            this.updateHomebaseStorageInfo(headerData, uploadResult);
            return uploadResult;
        }
    }

    //Saves a payload to an existing file.  Returns the new version tag
    public async savePayload(uniqueId: string, versionTag: string, payload: CassieSavePayloadData): Promise<boolean> {

        const o = await this.getByUniqueId(uniqueId);
        if (null == o?.storage?.fileId) {
            throw new Error(`Invalid UniqueId: [${uniqueId}]`);
        }

        const file: ExternalFileIdentifier = {
            fileId: o.storage.fileId,
            targetDrive: this.config.targetDrive
        }

        return await this.GetCassie().savePayload(this.dotYouClient, file, versionTag, payload);
    }

    public async setArchived(id: string, archived: boolean): Promise<boolean> {

        const params: FileQueryParams = {
            targetDrive: this.config.targetDrive,
            fileType: [this.config.fileType],
            clientUniqueIdAtLeastOne: [id]
        };

        const options: CassieQueryOptions = {
            forceServerCall: false
        }

        const searchResults = await this.GetCassie().queryFiles(this.dotYouClient, params, options);
        if (searchResults.length === 1) {
            const dsr = searchResults[0];

            const metadata: UploadFileMetadata = {
                allowDistribution: false,
                versionTag: dsr.fileMetadata.versionTag,
                appData: {
                    ...dsr.fileMetadata.appData,
                    archivalStatus: archived ? ARCHIVAL_STATUS_DELETED : ARCHIVAL_STATUS_NONE_SPECIFIED,
                },
                isEncrypted: true,
                accessControlList: {requiredSecurityGroup: SecurityGroupType.Owner},
            };

            const storageOptions: StorageOptions = {
                overwriteFileId: dsr.fileId || undefined,
                drive: this.config.targetDrive
            }

            const uploadResult: UploadResult | void = await this.GetCassie().saveHeaderFile(this.dotYouClient, metadata, storageOptions, undefined, undefined);

            if (uploadResult) {
                return true;
            }
        }
        
        return false;

    };

    public async hardDeleteFile(id: string): Promise<boolean> {
        const header = await this.getHeaderInternalFromServer(id);

        if (null != header && header.fileId) {
            if (header.fileMetadata.appData.fileType !== this.config.fileType) {
                throw Error("The file returned with the Id given does not match the expected fileType");
            }

            return await this.GetCassie().hardDeleteFile(this.dotYouClient, header.fileId, this.config.targetDrive);

        } else {

            const ldp = IndexDBProvider.getInstance();
            const dsr = await ldp.GetByUniqueId(id, this.config.targetDrive);

            //delete the local entry in case we're out of sync
            if (dsr) {
                await ldp.HardDeleteFile(dsr.fileId, this.config.targetDrive);
                return true;
            }
        }

        return false;
    };

    // Synchronizes all server records for the configured filetype to the LocalDb
    public async syncFileType(asOfTimestamp: number): Promise<number> {
        const params: FileQueryParamsWithoutTargetDrive = {
            fileType: [this.config.fileType]
        };

        return await this.syncByQuery(params, asOfTimestamp);
    }

    public async syncByQuery(queryParams: FileQueryParamsWithoutTargetDrive, asOfTimestamp: number): Promise<number> {
        const params: FileQueryParams = {
            targetDrive: this.config.targetDrive,
            ...queryParams
        };

        const resultOptions: GetBatchQueryResultOptions = {
            maxRecords: 100000, //TODO: chunk
            includeMetadataHeader: true,
            sorting: "fileId",
            ordering: "newestFirst"
        };

        const response = await queryBatch(this.dotYouClient, params, resultOptions)

        let fileCount: number = response.searchResults.length;
        if (fileCount === 0) {
            return fileCount;
        }

        const headers = [];
        for (let i = 0; i < response.searchResults.length; i++) {
            let header = response.searchResults[i];

            let sync = false;
            if (header.fileMetadata.updated > 0) {
                sync = header.fileMetadata.updated > asOfTimestamp;
            } else {
                sync = header.fileMetadata.created > asOfTimestamp;
            }

            if (sync) {

                if (header.fileMetadata.isEncrypted) {
                    const key = await decryptKeyHeader(this.dotYouClient, header.sharedSecretEncryptedKeyHeader);
                    header.fileMetadata.appData.content = await decryptJsonContent(header.fileMetadata, key);
                }

                headers.push(header);
            }
        }

        await IndexDBProvider.getInstance().UpsertHeaders(headers, this.config.targetDrive);
        return fileCount;
    }

    public async syncLastModified(stopAtModifiedUnixTimeSeconds: number) {

        // console.log("seconds", stopAtModifiedUnixTimeSeconds);
        // console.log("Synchronizing as of", moment(stopAtModifiedUnixTimeSeconds).fromNow());

        const targetDrive = this.config.targetDrive;
        const params: FileQueryParams = {
            targetDrive: targetDrive,
            fileType: [this.config.fileType]
        };

        const resultOptions: GetModifiedResultOptions = {
            maxRecords: 100000, //TODO: chunk
            includeHeaderContent: true,
            maxDate: stopAtModifiedUnixTimeSeconds
        };

        const response = await queryModified(this.dotYouClient, params, resultOptions);

        let fileCount = response.searchResults.length;
        // console.log("sync filecount", fileCount);

        if (fileCount === 0) {
            return fileCount;
        }

        const db = IndexDBProvider.getInstance();
        await db.UpsertHeaders(response.searchResults, targetDrive);

        // If option to sync payloads is turned ok
        // if (options.syncPayloads) {
        //     for (let i = 0; i < response.searchResults.length; i++) {
        //
        //         const dsr = response.searchResults[i];
        //
        //         for (let p = 0; p < dsr.fileMetadata.payloads.length; p++) {
        //             let payload = dsr.fileMetadata.payloads[p];
        //
        //             // let pr: PullPayloadRequest = {
        //             //     fileId: dsr.fileId,
        //             //     targetDrive: targetDrive,
        //             //     ...payload
        //             // };
        //             //
        //             // await pullPayload(pr, options.dotYouClient);
        //         }
        //     }
        // }
        throw new Error("remember you need to decrypt the headers");

        // return fileCount;
    }

    public async deletePayload(id: string, key: string) {

        const fileHeader = await this.getByUniqueId(id);

        if (!fileHeader) {
            return;
        }

        await this.GetCassie().deletePayload(this.dotYouClient, fileHeader.storage.fileId, fileHeader.storage.versionTag, this.config.targetDrive, key);
    }

    public async exportGraph(context?: string): Promise<SimpleStorageExport> {
        const files = await this.getAll();

        const getAllPayloads = [];
        const docs = files.map(async (doc) => {
            const getPayloads = doc.storage.payloads.map(async (descriptor) => {

                const p = await this.getPayload(doc.id, descriptor.key);

                const thumbs = descriptor.thumbnails.map(async (t) => {
                    return {
                        ...t,
                        data: await this.getThumbnailByUniqueId(doc.id, descriptor.key, t.pixelWidth, t.pixelHeight)
                    }
                });

                return {
                    headerUniqueId: doc.id,
                    payload: p,
                    thumbnails: await Promise.all(thumbs)
                };
            });

            getAllPayloads.push(...getPayloads);

            return doc;
        });

        return {
            name: context,
            docs: await Promise.all(docs),
            payloads: await Promise.all(getAllPayloads)
        };
    }

    /////

    private async getHeaderInternalFromServer(id: string): Promise<HomebaseFile | null> {
        return await getFileHeaderByUniqueId(this.dotYouClient, this.config.targetDrive, id);
    }

    private updateHomebaseStorageInfo = <T extends HomebaseStorage>(o: T, result: UploadResult): T => {
        o.storage = {
            fileId: result.file.fileId,
            versionTag: result.newVersionTag,
            updated: undefined,
            created: undefined,

            //TODO: should i update this some way?
            // uniqueId: undefined,
            // archivalStatus: undefined,
            // dataType: undefined,
            // fileType: undefined,
            // userDate: undefined,
            // content: undefined,
            // contentType: undefined,
            // previewThumbnail: undefined,
            // payloads: undefined
        }
        return o;
    }

    private async getContent<T extends HomebaseStorage>(result: HomebaseFile): Promise<T | null> {

        // let json;
        // if (result.fileMetadata.isEncrypted) {
        //     const key = await decryptKeyHeader(this.dotYouClient, result.sharedSecretEncryptedKeyHeader);
        //     json = await decryptJsonContent(result.fileMetadata, key);
        // } else {
        //     json = result.fileMetadata.appData.content;
        // }

        let o: T | null = null;

        try {
            o = JSON.parse(result.fileMetadata.appData.content) as T;
            o.storage = {
                fileId: result.fileId,
                versionTag: result.fileMetadata.versionTag,
                payloads: result.fileMetadata.payloads,
                updated: result.fileMetadata.updated,
                created: result.fileMetadata.created,
                isArchived: result.fileMetadata.appData.archivalStatus === ARCHIVAL_STATUS_DELETED,

                //TODO: should i update this some way?
                // uniqueId: undefined,
                // archivalStatus: undefined,
                // dataType: undefined,
                // fileType: undefined,
                // userDate: undefined,
                // content: undefined,
                // contentType: undefined,
                // previewThumbnail: undefined,
                // payloads: undefined
            }
        } catch (ex) {
            console.error('Deserialization Error', ex);
        }

        return o;
    }
}