import {InitiationDefinition} from "../InitiationDefinitionProvider";
import {LifeActivation} from "./BuiltInHealings";
import {JourneysOfTheSpirit, SacredGeometry1} from "./BuiltInClasses";

export const AdeptInitiation: InitiationDefinition = {
    id: "ddd9a1df-0dc7-49a0-a07f-4bd65e5c66aa",
    title: "Adept",
    description: "",
    prerequisiteHealings: [LifeActivation.id],
    prerequisiteClasses: [],
    prerequisiteInitiations: []
}

export const Healer1Initiation: InitiationDefinition = {
    id: "ac639864-3f4d-4b99-8798-7e671f82b876",
    title: "Life activation Practitioner",
    description: "",
    prerequisiteHealings: [],
    prerequisiteClasses: [JourneysOfTheSpirit.id, SacredGeometry1.id],
    prerequisiteInitiations: [AdeptInitiation.id]
}

export const Healer2Initiation: InitiationDefinition = {
    id: "3786d21f-3cbe-44a2-8635-91d6722ab87e",
    title: "Healers 2",
    description: "",
    prerequisiteHealings: [],
    prerequisiteClasses: [],
    prerequisiteInitiations: [Healer1Initiation.id]
}

export const RitualMaster1Initiation: InitiationDefinition = {
    id: "a1b87bd7-d477-4623-88ad-ee9c882f8295",
    title: "1st Step RM",
    description: "",
    prerequisiteHealings: [],
    prerequisiteClasses: [],
    prerequisiteInitiations: [Healer1Initiation.id]
}

export const RitualMaster2Initiation: InitiationDefinition = {
    id: "eca629e7-a28d-4ef8-bbf9-ea710d63e7aa",
    title: "2nd Step RM",
    description: "",
    prerequisiteHealings: [],
    prerequisiteClasses: [],
    prerequisiteInitiations: [RitualMaster1Initiation.id]
}

export const RitualMaster25Initiation: InitiationDefinition = {
    id: "f7f93e8c-2cba-4968-ad0c-e9483b577243",
    title: "Ritual Master 2.5",
    description: "",
    prerequisiteHealings: [],
    prerequisiteClasses: [],
    prerequisiteInitiations: [RitualMaster2Initiation.id]
}

export const RitualMaster3Initiation: InitiationDefinition = {
    id: "eb8c2670-faa5-4a5d-a2d1-10ba931d7853",
    title: "3rd Step RM",
    description: "",
    prerequisiteHealings: [],
    prerequisiteClasses: [],
    prerequisiteInitiations: [RitualMaster25Initiation.id]
}

export const GuideInitiation: InitiationDefinition = {
    id: "85f1262c-d695-493d-9f0c-4b0c83ffeaba",
    title: "Guide",
    description: "",
    prerequisiteHealings: [],
    prerequisiteClasses: [Healer2Initiation.id],
    prerequisiteInitiations: [Healer1Initiation.id]
}

export const builtInInitiations: InitiationDefinition[] = [
    AdeptInitiation,
    Healer1Initiation,
    Healer2Initiation,
    GuideInitiation,
    RitualMaster1Initiation,
    RitualMaster2Initiation,
    RitualMaster25Initiation,
    RitualMaster3Initiation
]