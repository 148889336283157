import {DotYouClient} from "@youfoundation/js-lib/core";
import {getNewId, jsonStringify64} from "@youfoundation/js-lib/helpers";
import {makeAutoObservable, runInAction} from "mobx";
import moment from "moment/moment";
import {HomebaseStorage, Image64Data, ImageHeader, SaveFileConfig, SaveOptions} from "../core/storage/HomebaseStorageTypes";
import {GuideFlowConfig} from "../../system/GuideFlowConfig";
import {PhysicalAddress} from "../MapTypes";
import {SimplifiedStorageProvider} from "../core/storage/SimplifiedStorageProvider";
import {CassieSavePayloadData} from "../core/cassie/CassieTypes";
import {ContactProgressionEventProvider} from "./ContactProgressionEventProvider";
import {ContactNotesProvider} from "./ContactNotesProvider";

const fileConfig: SaveFileConfig = {
    dataType: 400,
    fileType: 888,
    targetDrive: GuideFlowConfig.DataDrive
}

export const contactDocumentDataType = 1001;

export const ContactPayloadKeys = {
    primaryImage: "profileimg"
}

type ContactStatus = "LifeActivated" | "Initiated" | null;

export interface Contact extends HomebaseStorage {
    fileId?: string,
    id: string;
    firstname: string;
    surname: string;
    email: string;
    phone: string;
    mmsStudentNumber: string;
    status: ContactStatus;
    dateOfBirth: number;
    primaryAddress?: PhysicalAddress;
}

export class ContactProvider {
    private static instance: ContactProvider
    private readonly provider: SimplifiedStorageProvider<Contact>;
    private readonly dotYouClient: DotYouClient;
    private readonly progressionEventProvider: ContactProgressionEventProvider;
    private readonly notesProvider: ContactNotesProvider;
    public StateChanged: number = 0;

    // public static FileConfig: SaveFileConfig = fileConfig;

    constructor(config: SaveFileConfig, dotYouClient: DotYouClient) {
        this.dotYouClient = dotYouClient;
        this.provider = new SimplifiedStorageProvider<Contact>(config, dotYouClient);
        this.progressionEventProvider = new ContactProgressionEventProvider(this.dotYouClient);
        this.notesProvider = new ContactNotesProvider(this.dotYouClient);
        makeAutoObservable(this);
        this.synchronize = this.synchronize.bind(this);
    }

    static getInstance(dotYouClient: DotYouClient) {
        if (ContactProvider.instance == null) {
            ContactProvider.instance = new ContactProvider(fileConfig, dotYouClient);
        }
        return ContactProvider.instance;
    }

    get ProgressionEvents(): ContactProgressionEventProvider {
        return this.progressionEventProvider;
    }

    get Notes(): ContactNotesProvider {
        return this.notesProvider;
    }

    public async getAll(includeArchived?: boolean): Promise<Contact[]> {
        return await this.provider.getAll({includeArchived: includeArchived});
    }

    public async exportGraph() {
        return {
            Contacts: await this.provider.exportGraph("contacts"),
            ProgressionEvents: await this.progressionEventProvider.exportGraph("contact-progression"),
            Notes: await this.notesProvider.exportGraph("contact-notes")
        };
    }

    public async getList(list: string[]): Promise<Contact[]> {
        return await this.provider.getList(list);
    }

    public async syncFileType(): Promise<number> {
        const count = await this.provider.syncFileType(moment.utc().unix());
        if (count > 0) {
            this.notifyStateChange();
        }
        return count;
    }

    public async getAllGrouped(includeArchived?: boolean): Promise<Record<string, Contact[]>> {
        const groupedContacts: Record<string, Contact[]> = {};
        const contacts = await this.getAll(includeArchived);
        const sorted = contacts.sort((a, b) => a.firstname.localeCompare(b.firstname));
        sorted.forEach(contact => {
            const firstLetter = contact.firstname.charAt(0).toUpperCase();
            if (!groupedContacts[firstLetter]) {
                groupedContacts[firstLetter] = [];
            }
            groupedContacts[firstLetter].push(contact);
        });
        return groupedContacts;
    }

    public async saveProfileImage(contactId: string, versionTag: string, image: Image64Data) {
        const header: ImageHeader = {
            width: image.width,
            height: image.height,
            mimeType: image.mimeType
        }

        const payload: CassieSavePayloadData = {
            key: ContactPayloadKeys.primaryImage,
            contentType: image.mimeType,
            descriptorContent: jsonStringify64(header),
            thumbnails: [],
            content: image.base64Data
        };

        await this.provider.savePayload(contactId, versionTag, payload);
        this.notifyStateChange();
    }

    public async getProfileImage(contactId: string): Promise<Image64Data | null> {
        return await this.provider.getImagePayload(contactId, ContactPayloadKeys.primaryImage);
    }

    async save(contact: Contact): Promise<string> {
        const options: SaveOptions =
            {
                groupId: undefined,
                userDate: undefined,
                onVersionConflict: () => {
                    //TODO: how to handle this?
                }
            };

        if (!contact.id) {
            contact.id = getNewId();
        }

        const result = await this.provider.saveHeaderFile(contact, undefined, options);

        if (result) {
            this.notifyStateChange();
        }

        return "";
    }

    public async delete(id: string): Promise<boolean> {
        const result = await this.provider.setArchived(id, true);
        if (result) {
            this.notifyStateChange();
        }

        return result;
    };

    public async undelete(id: string): Promise<boolean> {
        const result = await this.provider.setArchived(id, false);
        if (result) {
            this.notifyStateChange();
        }

        return result;
    };


    public async get(contactId: string): Promise<Contact | null> {
        return await this.provider.getByUniqueId(contactId);
    }

    public async synchronize(asOfTime: number) {
        const count = await this.provider.syncFileType(asOfTime);
        if (count > 0) {
            this.notifyStateChange();
        }
    }

    private notifyStateChange() {
        runInAction(() => {
            this.StateChanged++;
        });
    }

}