import {ArrowPathRoundedSquareIcon, ClockIcon, MagnifyingGlassIcon, PlusIcon} from "@heroicons/react/16/solid";
import {useEffect, useState} from "react";
import {useGuideFlowProviderContext} from "../../../GuideFlowProviderContext";
import {observer} from "mobx-react-lite";
import {Link, useNavigate} from "react-router-dom";
import {XMarkIcon} from "@heroicons/react/24/outline";
import {ClassDefinition} from "../../../providers/definitions/ClassDefinitionProvider";

export const ClassList = observer(() => {

    const navigate = useNavigate();
    const [searchText, setSearchText] = useState<string>("");
    const [directoryData, setDirectoryData] = useState<Record<string, ClassDefinition[]>>();
    const [filteredDirectory, setFilteredDirectory] = useState<Record<string, ClassDefinition[]>>();
    const [isSyncing, setIsSyncing] = useState(false);
    const context = useGuideFlowProviderContext();
    
    useEffect(() => {
        context.ClassDefinitions.getAllGrouped().then(definitions => {
            setDirectoryData(definitions);
        });

    }, [context.ClassDefinitions, context.ClassDefinitions.StateChanged]);

    const handleSearch = (text: string) => {
        if (!directoryData) {
            return;
        }

        if (!text) {
            setFilteredDirectory(directoryData);
        }

        const fd: Record<string, ClassDefinition[]> = {};
        Object.keys(directoryData).map((letter) => {
            const matchingRecords = directoryData[letter].filter((record) => {
                const regex = new RegExp(text, 'i');
                return record.title.match(regex);
            });

            if (matchingRecords && matchingRecords.length > 0) {
                if (!fd[letter]) {
                    fd[letter] = [];
                }

                fd[letter] = matchingRecords;
            }

            return null;
            
        });

        setSearchText(text);
        setFilteredDirectory(fd);
    }

    const handleClearSearch = () => {
        setSearchText("");
        setFilteredDirectory(null);
    }

    const directory = searchText ? filteredDirectory : directoryData;
    
    const handleSyncRequest = async () => {
        setIsSyncing(true);
        context.ClassDefinitions.syncFileType().then(_ => {
            setIsSyncing(false);
        });
    }

    if (!directory) {
        return <>Loading..</>
    }
    
    return (
        <div className="h-full">
            <div className="px-6 pb-4 pt-6">
                <div className="flex flex-row space-x-3">
                    <h2 className="text-lg font-medium text-gray-900">Classes</h2>
                    <button
                        type="button"
                        disabled={isSyncing}
                        onClick={() => {
                            navigate("new");
                        }}
                        className="rounded-full bg-indigo-600 p-1 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                        <PlusIcon className="h-4 w-5" aria-hidden="true"/>
                    </button>
                    <button
                        type="button"
                        disabled={isSyncing}
                        onClick={handleSyncRequest}
                        className="rounded-full bg-indigo-600 p-1 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                        {isSyncing && <ClockIcon className="h-4 w-5" aria-hidden="true"/>}
                        {!isSyncing && <ArrowPathRoundedSquareIcon className="h-4 w-5" aria-hidden="true"/>}
                    </button>
                </div>
                <form className="mt-6 flex gap-x-4" action="#">
                    <div className="min-w-0 flex-1">
                        <label htmlFor="search" className="sr-only">
                            Search
                        </label>
                        <div className="relative rounded-md shadow-sm">
                            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" aria-hidden="true"/>
                            </div>
                            <input
                                type="search"
                                name="search"
                                id="search"
                                className="block w-full rounded-md border-0 py-1.5 pl-10 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-pink-500 sm:text-sm sm:leading-6"
                                placeholder="Search"
                                onChange={(e) => handleSearch(e.target.value)}
                                value={searchText}
                            />
                        </div>
                    </div>
                    <button
                        type="button"
                        onClick={handleClearSearch}
                        className="inline-flex justify-center rounded-md bg-white px-3 py-2 text-gray-400 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                        <XMarkIcon className="h-5 w-5" aria-hidden="true"/>
                        <span className="sr-only">Clear</span>
                    </button>
                </form>
            </div>

            <nav className="min-h-0 h-full flex-1 overflow-y-auto" aria-label="Directory">
                {Object.keys(directory).map((letter) => (
                    <div key={letter} className="relative">
                        <div
                            className="sticky top-0 z-10 border-b border-t border-gray-200 bg-gray-50 px-6 py-1 text-sm font-medium text-gray-500">
                            <h3>{letter}</h3>
                        </div>
                        <ul className="relative z-0 divide-y divide-gray-200">
                            {directory[letter].map((definition) => (
                                <Link key={definition.id} to={`${definition.id}/overview`}>
                                    <div
                                        className="relative flex items-center space-x-3 px-6 py-5 focus-within:ring-2 focus-within:ring-inset focus-within:ring-pink-500 hover:bg-gray-50">
                                        <div className="flex-shrink-0">
                                            {/*<img className="h-10 w-10 rounded-full" src={definition} alt=""/>*/}
                                        </div>
                                        <div className="min-w-0 flex-1">
                                            <button className="focus:outline-none">
                                                {/* Extend touch target to entire panel */}
                                                <span className="absolute inset-0" aria-hidden="true"/>
                                                <p className="text-sm font-medium text-gray-900">{definition.title}</p>
                                                <p className="truncate text-sm text-gray-500">{definition.description}</p>
                                            </button>
                                        </div>
                                    </div>
                                </Link>
                            ))}
                        </ul>
                    </div>
                ))} 
                <div className="h-32"/>
            </nav>
           
        </div>
    )
});