import {Button} from "../../../../controls/tailwindui/button";
import {ErrorMessage, Field, Label} from "../../../../controls/tailwindui/fieldset";
import {Input} from "../../../../controls/tailwindui/input";
import SpinnerIcon from "../../../../controls/icons/SpinnerIcon";
import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useGuideFlowProviderContext} from "../../../../../GuideFlowProviderContext";
import {useForm} from "react-hook-form";
import toast from "react-hot-toast";
import {SECTION_ROOT} from "../../FormTypes";
import {Contact} from "../../../../../providers/contact/ContactProvider";

export default function ContactEditDetailsScreen() {
    const navigate = useNavigate();
    const {id} = useParams();
    const context = useGuideFlowProviderContext();
    const [isSaving, setIsSaving] = useState(false);
    const {register, reset, handleSubmit, formState: {errors}} = useForm<Contact>({});

    useEffect(() => {
        context.Contacts.get(id).then(c => reset(c));
    }, [reset, context.Contacts, context.Contacts.StateChanged, id]);

    const handleCancel = () => {
        navigate(`${SECTION_ROOT}/${id}/overview`);
    }
    const onSubmit = async (data: Contact) => {
        setIsSaving(true)
        await context.Contacts.save(data);
        toast.success("Contact updated", {icon: '✅'});
        setIsSaving(false);
        navigate(`${SECTION_ROOT}/${id}/overview`);
    };

    return (
        <div className="p-3">
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="xl:gap-x-16">
                    <div>
                        <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
                            {/*<h2 className="text-lg font-medium text-gray-900">Contact information</h2>*/}
                            <div className="flex flex-row gap-3">
                                <Field>
                                    <Label>First Name</Label>
                                    <Input className="max-w-[10rem]" {...register("firstname", {required: true,})} invalid={errors?.firstname != null}
                                           autoFocus={true}/>
                                    {errors?.firstname && <ErrorMessage>*{errors.firstname.message}</ErrorMessage>}
                                </Field>
                                <Field>
                                    <Label>Surname</Label>
                                    <Input className="max-w-[10rem]" {...register("surname", {required: true,})} invalid={errors?.surname != null}/>
                                    {errors?.surname && <ErrorMessage>*{errors.surname.message}</ErrorMessage>}
                                </Field>
                            </div>
                            <div>
                                <Field>
                                    <Label>Email</Label>
                                    <Input className="max-w-[15rem]" {...register("email", {required: false,})} invalid={errors?.email != null}/>
                                    {errors?.email && <ErrorMessage>*{errors.email.message}</ErrorMessage>}
                                </Field>
                            </div>
                            <div>
                                <Field>
                                    <Label>Phone</Label>
                                    <Input className="max-w-[15rem]" {...register("phone", {required: false,})} invalid={errors?.phone != null}/>
                                    {errors?.phone && <ErrorMessage>*{errors.phone.message}</ErrorMessage>}
                                </Field>
                            </div>
                            <div>
                                <Field>
                                    <Label>Student Number</Label>
                                    <Input className="max-w-[10rem]" {...register("mmsStudentNumber", {required: false, pattern: /[0-9]/})}
                                           invalid={errors?.mmsStudentNumber != null}/>
                                    {errors?.mmsStudentNumber && <ErrorMessage>If specified, student number is.. well numbers only</ErrorMessage>}
                                </Field>
                            </div>
                        </div>
                    </div>

                    <div className="grid-cols-3 space-y-2 mt-10 border-t">
                        <div className="mt-5 col-span-full">
                            <Field>
                                <Label>Street address 1</Label>
                                <Input className="md:max-w-[33rem]" {...register("primaryAddress.addressLine1", {required: false,})}
                                       invalid={errors?.primaryAddress?.addressLine1 != null}/>
                            </Field>
                        </div>

                        <div className="col-span-full">
                            <Field>
                                <Label>Street address 2</Label>
                                <Input className="md:max-w-[33rem]" {...register("primaryAddress.addressLine2", {required: false,})}
                                       invalid={errors?.primaryAddress?.addressLine2 != null}/>
                            </Field>
                        </div>

                        <div className="justify-items-start">
                            <div className="md:flex md:flex-row gap-5 mt-3 justify-items-start">
                                <Field>
                                    <Label>City</Label>
                                    <Input className="md:max-w-[10rem]" {...register("primaryAddress.city", {required: false,})}
                                           invalid={errors?.primaryAddress?.city != null}/>
                                </Field>
                                <Field className="xs:mt-3">
                                    <Label>State/Province</Label>
                                    <Input className="md:max-w-[10rem]" {...register("primaryAddress.stateProvinceRegion", {required: false,})}
                                           invalid={errors?.primaryAddress?.stateProvinceRegion != null}/>
                                </Field>
                                <Field>
                                    <Label>Postal Code</Label>
                                    <Input className="md:max-w-[10rem]" {...register("primaryAddress.postalCode", {required: false,})}
                                           invalid={errors?.primaryAddress?.postalCode != null}/>
                                </Field>
                            </div>
                        </div>
                        <div className="">
                            <Field>
                                <Label>Country Code</Label>
                                <Input className="max-w-[10rem]" {...register("primaryAddress.country", {required: false,})}
                                       invalid={errors?.primaryAddress?.country != null}/>
                            </Field>
                        </div>
                    </div>
                </div>

                <div className="mt-6 flex items-center justify-end gap-x-6">
                    <Button type="button" disabled={isSaving} onClick={handleCancel} outline={true}>
                        Cancel
                    </Button>
                    <Button
                        type="submit"
                        disabled={isSaving}
                        color="indigo">
                        {isSaving && <SpinnerIcon/>}
                        Save
                    </Button>
                </div>
            </form>
        </div>
    )
}
