import {DotYouClient} from "@youfoundation/js-lib/core";
import {getNewId, jsonStringify64} from "@youfoundation/js-lib/helpers";
import {EmbeddedThumb, ThumbnailFile} from "@youfoundation/js-lib/dist/core/core";
import {makeAutoObservable, runInAction} from "mobx";
import {HomebaseStorage, SaveFileConfig, SaveOptions} from "../core/storage/HomebaseStorageTypes";
import {GuideFlowConfig} from "../../system/GuideFlowConfig";
import {CassiePayloadResult, CassieQueryOptions, CassieSavePayloadData} from "../core/cassie/CassieTypes";
import {FileQueryParamsWithoutTargetDrive, SimplifiedStorageProvider} from "../core/storage/SimplifiedStorageProvider";
import {ClassDefinition} from "../definitions/ClassDefinitionProvider";
import {HealingDefinition} from "../definitions/HealingDefinitionProvider";
import {InitiationDefinition} from "../definitions/InitiationDefinitionProvider";
import {FullSpiritActivation, GalacticActivation1, LifeActivation} from "../definitions/builtin/BuiltInHealings";
import {
    AdeptInitiation,
    Healer1Initiation,
    Healer2Initiation,
    RitualMaster1Initiation,
    RitualMaster25Initiation,
    RitualMaster2Initiation,
    RitualMaster3Initiation
} from "../definitions/builtin/BuiltInInitiations";
import {normalizeGuid} from "../DataHelpers";
import {t} from "../../helpers/i18n/dictionary";
import {DefinitionStorage} from "../definitions/DefinitionStorageProvider";
import mediator, {ProgressionEventChanged} from "../Mediator/Mediator";
import {isArray} from "lodash";

type ContactProgressionEventSaveFileConfig = SaveFileConfig;

const timelineSaveFileConfig: ContactProgressionEventSaveFileConfig = {
    fileType: 9931,
    dataType: 0,
    targetDrive: GuideFlowConfig.DataDrive
}

export const PayloadKeys = {
    singlePayloadDocumentKey: "udoc5544"
}

export type ContactProgressionEventType = "class" | "healing" | "initiation";

export interface ContactProgressionStatus {
    text: string,
    definition: DefinitionStorage
}

export interface ContactProgressionEvent extends HomebaseStorage {
    id: string;
    contactId: string;  //groupId
    eventDateUtcSeconds: number; //user date
    definitionId: string;
    definitionType: ContactProgressionEventType;
    locationId: string;
    studentManualNumber: string;
    //snapshot at the time the event was added
    definitionSnapshot?: ClassDefinition | HealingDefinition | InitiationDefinition
}

export interface ProgressionEventPayloadDescriptor {
    order: number;
    mimeType: string;
    width: number;
    height: number;
}

export interface ProgressionEventPayload extends ProgressionEventPayloadDescriptor {
    data: Blob
}

export interface ProgressionEventQueryOptions {
    dataType?: number,
    cassieQueryOptions?: CassieQueryOptions
}

//Manages the data for a contact's progress; including helper methods
// to indicate the big events (initiated, life activation, etc.)
export class ContactProgressionEventProvider {
    private readonly provider: SimplifiedStorageProvider<ContactProgressionEvent>;
    public StateChanged: number = 0;

    constructor(dotYouClient: DotYouClient) {
        this.provider = new SimplifiedStorageProvider<ContactProgressionEvent>({dataType: undefined, ...timelineSaveFileConfig}, dotYouClient);

        makeAutoObservable(this);
        this.synchronize = this.synchronize.bind(this);
    }

    public async getAll(contactId: string, options?: ProgressionEventQueryOptions): Promise<ContactProgressionEvent[]> {

        if (!contactId) {
            throw Error('Invalid contactId')
        }

        const records = await this.provider.getAll<ContactProgressionEvent>({
            groupId: contactId,
            dataType: options?.dataType,
            cassieQueryOptions: options?.cassieQueryOptions
        });
        return records.sort((a, b) => a.eventDateUtcSeconds - b.eventDateUtcSeconds);
    }

    public async getAllByDefinitionId(definitionId: string | string[], options?: ProgressionEventQueryOptions): Promise<ContactProgressionEvent[]> {
        if (!definitionId) {
            throw Error('Invalid definitionId')
        }

        return await this.provider.getAll({
            tagsMatchAtLeastOne: isArray(definitionId) ? definitionId.map(g => normalizeGuid(g)) : [normalizeGuid(definitionId)],
            dataType: options?.dataType,
            cassieQueryOptions: options?.cassieQueryOptions
        });
    }

    public async getPayload(documentId: string): Promise<CassiePayloadResult> {
        return await this.provider.getPayload(documentId, PayloadKeys.singlePayloadDocumentKey);
    }

    public async save(event: ContactProgressionEvent, content?: ProgressionEventPayload | null) {

        if (!event) {
            throw Error("Progression event is null");
        }

        if (!event.contactId || !event.eventDateUtcSeconds || !event.definitionId) {
            throw Error("One or more fields is missing");
        }

        if (!event.id) {
            event.id = getNewId();
        }

        const p = await this.itemPayloadToCassie(content);

        const tags = [event.definitionId];
        if (event.locationId) {
            tags.push(event.locationId);
        }

        //i could tag it, so that i would say give me all progression events tagged with classId 1234
        const options: SaveOptions =
            {
                groupId: event.contactId,
                userDate: event.eventDateUtcSeconds,
                tags: tags,
                // overrideDataType: event.dataType,
                previewThumbnail: p?.tinyThumb ?? undefined,
                onVersionConflict: () => {
                    //TODO: how to handle this?
                }
            };

        await this.provider.saveHeaderFile(event, p.payload, options);

        await mediator.publish(new ProgressionEventChanged(event.id, "added", event));
        // const latestStatus = await this.getStatus(event.contactId);

        this.notifyStateChange();
    }

    public async delete(id: string): Promise<boolean> {

        if (await this.provider.hardDeleteFile(id)) {
            this.notifyStateChange();
            await mediator.publish(new ProgressionEventChanged(id, "deleted", null));
            return true;
        }

        return false;
    };

    public async get(itemId: string): Promise<ContactProgressionEvent | null> {
        return await this.provider.getByUniqueId(itemId);
    }

    public async syncDataType(dataType: number) {

        const params: FileQueryParamsWithoutTargetDrive = {
            dataType: [dataType]
        };

        return await this.provider.syncByQuery(params, 0);
    }

    public async getStatus(contactId: string): Promise<ContactProgressionStatus> {

        // Scan events: seeking for initiation most advanced initiation first
        // then look for milestone healings (life activation, galactic activation)

        // This map is ordered by the dependency of healings, activations, etc 
        const PrioritizedIdMap = [
            LifeActivation,
            FullSpiritActivation,
            GalacticActivation1,
            AdeptInitiation,
            Healer1Initiation,
            Healer2Initiation,
            RitualMaster1Initiation,
            RitualMaster2Initiation,
            RitualMaster25Initiation,
            RitualMaster3Initiation,
        ];

        // here we're checking for the highest item in the events list
        // this will show the furthest initiation.

        const events = await this.getAll(contactId, {cassieQueryOptions: {forceServerCall: true}});

        // console.log('e', events);
        let highestMatch: number = -1;
        for (const event of events) {

            //find the event in the PrioritizedIdMap
            const idx = PrioritizedIdMap.findIndex(item => normalizeGuid(item.id) === normalizeGuid(event.definitionId));
            if (idx > -1) // found
            {
                highestMatch = idx > highestMatch ? idx : highestMatch;
            }
        }

        const match = highestMatch > -1 ? PrioritizedIdMap[highestMatch] : null;
        return {
            definition: match,
            text: match?.title ?? t("Holding Potential")
        };
    }

    ///

    private async addPayload(itemId: string, content: ProgressionEventPayload, additionalThumbnails: ThumbnailFile[]) {

        const descriptor = await this.getItemOrFail(itemId);

        // thumbnails.naturalSize
        const o: ProgressionEventPayloadDescriptor = {
            ...content
        }

        const payload = {
            key: PayloadKeys.singlePayloadDocumentKey,
            contentType: content.mimeType,
            descriptorContent: jsonStringify64(o),
            thumbnails: additionalThumbnails,
            content: content.data
        };

        await this.provider.savePayload(itemId, descriptor.storage.versionTag, payload);
    }

    private async itemPayloadToCassie(content: ProgressionEventPayload): Promise<{
        tinyThumb: EmbeddedThumb,
        payload: CassieSavePayloadData
    }> {

        if (!content) {
            return {
                tinyThumb: undefined,
                payload: undefined
            }
        }

        // let thumbnails: {
        //     naturalSize: ImageSize;
        //     tinyThumb: EmbeddedThumb;
        //     additionalThumbnails: ThumbnailFile[];
        // };

        // console.log('Add payload data type', content.data.type);
        // if (content.data.type.startsWith("image/")) {
        //     thumbnails = await createThumbnails(content.data, PayloadKeys.singlePayloadDocumentKey, thumbnailSizes);
        // }

        const o: ProgressionEventPayloadDescriptor = {
            ...content
        }

        const payload: CassieSavePayloadData = {
            key: PayloadKeys.singlePayloadDocumentKey,
            contentType: content.mimeType,
            descriptorContent: jsonStringify64(o),
            thumbnails: undefined,
            // thumbnails: thumbnails?.additionalThumbnails,
            content: content.data
        };

        return {
            tinyThumb: undefined,
            // tinyThumb: thumbnails?.tinyThumb,
            payload: payload
        }
    }

    private async deletePayload(documentId: string, key: string) {

        const document = await this.getItemOrFail(documentId);
        const payload = document.storage.payloads.find(p => p.key.toLowerCase() === key.toLowerCase());

        if (payload) {
            await this.provider.deletePayload(documentId, key);
            this.notifyStateChange();
        }
    }

    private async getItemOrFail(documentId: string) {
        if (!documentId) {
            throw Error("Missing documentId");
        }

        const document = await this.get(documentId);
        if (!document) {
            throw new Error("No document exists for the specified documentId");
        }

        return document;
    }

    public async synchronize(asOfTime: number) {
        const count = await this.provider.syncFileType(asOfTime);
        if (count > 0) {
            this.notifyStateChange();
        }
    }

    private notifyStateChange() {
        runInAction(() => {
            this.StateChanged++;
        });
    }

    public async exportGraph(context?: string) {
        return await this.provider.exportGraph(context)
    }
}