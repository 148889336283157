import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {useGuideFlowProviderContext} from "../../../../../GuideFlowProviderContext";
import {formatDinero} from "../../../../../helpers/Money";
import {generalConfig} from "../../../../../system/config";
import {t} from "../../../../../helpers/i18n/dictionary";
import {HealingDefinition} from "../../../../../providers/definitions/HealingDefinitionProvider";
import {formatHoursFromMinutes} from "../../../../../helpers/Dates";
import HealingTitleList from "../../../../controls/renderers/Healings/HealingTitleList";
import {DropMenu, DropMenuItem} from "../../../../controls/vidence/menus/dropmenu/Dropmenu";

export default function ClassDetailsView() {
    const {id} = useParams();
    const [definition, setDefinition] = useState<HealingDefinition | undefined>();
    const context = useGuideFlowProviderContext();

    useEffect(() => {
        context.ClassDefinitions.get(id).then(setDefinition);
    }, [context.ClassDefinitions, context.ClassDefinitions.StateChanged, id]);

    const exchangeText = definition?.exchange ? `${formatDinero(definition.exchange)} ${generalConfig.currency.code}` : t("N/A");
    const durationText = definition?.durationMinutes ? `${formatHoursFromMinutes(definition?.durationMinutes)} ${t("hours")}` : t("N/A");

    return (
        <div className=" overflow-hidden">
            <div className="mt-6">
                <div className="flex-shrink-0 border-b border-gray-200 bg-white">
                    <div className="flex h-16 flex-col justify-center">
                        <div className="flex justify-between">
                            <div>
                                Details
                            </div>
                            <DropMenu>
                                <DropMenuItem title="Edit" to="edit"/>
                            </DropMenu>
                        </div>
                    </div>
                </div>
                <dl className="grid grid-cols-1 sm:grid-cols-2">
                    <div className="border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900">Exchange</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">
                            {exchangeText}
                        </dd>
                    </div>
                    <div className="border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900">Duration (hours)</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">
                            {durationText}
                        </dd>
                    </div>
                    <div className="border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900">Pre-reqs</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">
                            {definition?.prerequisiteHealings && <HealingTitleList idList={definition?.prerequisiteHealings}/>}
                            {!definition?.prerequisiteHealings && t("None")}
                        </dd>
                    </div>
                </dl>
                <div>
                    <div className="border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900">Description</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{definition?.description}</dd>
                    </div>
                </div>
            </div>
        </div>
    )

}
