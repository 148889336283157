import {useEffect, useState} from "react";
import {useGuideFlowProviderContext} from "../../../../GuideFlowProviderContext";
import {HealingDefinition} from "../../../../providers/definitions/HealingDefinitionProvider";

interface HealingTitleProps {
    id: string
}

export default function HealingTitle(props: HealingTitleProps) {

    const [definition, setDefinition] = useState<HealingDefinition>()
    const context = useGuideFlowProviderContext();

    useEffect(() => {
        if (props.id) {
            context.HealingDefinitions.get(props.id).then(setDefinition);
        }
    }, [context.HealingDefinitions, context.HealingDefinitions.StateChanged, props.id]);

    return (
        <span>{definition?.title}</span>
    )
}