import {Outlet} from "react-router-dom";

export default function ContactProgressionTab() {
    //this class exists to let me switch between overview and 
    // editing the profile details from with-in the same "overview" tab
    return (<div className="flex-1 flex-row flex-grow h-full">
        <Outlet/>
    </div> )
}

