import Dexie from "dexie";
import {IndexedHeaderRecord, IndexedPayloadRecord, IndexedThumbnailRecord} from "./LocalStorageTypes";
import {TargetDrive} from "@youfoundation/js-lib/core";
import {normalizeGuid} from "../../../DataHelpers";

// const dbname = "local-gf-db"

export class LocalStorageDb extends Dexie {
    public files!: Dexie.Table<IndexedHeaderRecord, string>;
    public payloads!: Dexie.Table<IndexedPayloadRecord, string[]>;
    public thumbnails!: Dexie.Table<IndexedThumbnailRecord, string[]>;

    constructor(name: string) {
        super(name);
        this.ensureSchema();
    }

    private ensureSchema() {
        // https://dexie.org/docs/API-Reference
        /*
            ++	Auto-incremented primary key
            &	Unique
            *	Multi-entry index
            [A+B]	Compound index
        */

        // NOTE: You only declare properties you want to index, that 
        // is properties you want to use in a where(…) query.
        this.version(1).stores({
            files: "&fileId, created, updated, globalTransitId, senderOdinId, fileType, dataType, groupId, userDate, uniqueId, *tags, archivalStatus",
            payloads: "&[fileId+payloadKey], contentType",
            thumbnails:"&[fileId+payloadKey+width+height], contentType"
        });
    }
}

export default class DataStorage {

    private static instance: DataStorage

    static getInstance() {
        if (DataStorage.instance == null) {
            // console.log("Creating new DataStorage instance");
            DataStorage.instance = new DataStorage();
        }

        return DataStorage.instance;
    }

    dbInstance: LocalStorageDb | undefined;

    public async getDb(targetDrive: TargetDrive): Promise<LocalStorageDb> {
        if (this.dbInstance !== undefined) {
            return Promise.resolve(this.dbInstance);
        }

        return await this.open(targetDrive);
    }

    private getDbName(targetDrive: TargetDrive) {
        return `${normalizeGuid(targetDrive.alias)}_${normalizeGuid(targetDrive.type)}`
    }

    private async open(targetDrive: TargetDrive): Promise<LocalStorageDb> {

        if (this.dbInstance) {
            // console.debug("returning existing instance");
            return this.dbInstance;
        }

        const dbName = this.getDbName(targetDrive);
        this.dbInstance = new LocalStorageDb(dbName);
        
        // this.dbInstance.open().then(function () {
        //     // Success
        // }).catch(Dexie.OpenFailedError, function (e) {
        //     // Failed with OpenFailedError
        //     console.error("open failed due to: " + e.inner);
        // }).catch(Error, function (e) {
        //     // Any other error derived from standard Error
        //     console.error("Error: " + e.message);
        // }).catch(function (e) {
        //     // Other error such as a string was thrown
        //     console.error(e);
        // });

        return this.dbInstance;
    }

    // public async close() {
    //     if (this.dbInstance) {
    //         this.dbInstance.close();
    //         this.dbInstance = undefined;
    //     }
    // };
}