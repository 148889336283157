import {DotYouClient} from "@youfoundation/js-lib/core";
import {DefinitionStorageProvider} from "./DefinitionStorageProvider";
import {HomebaseStorage, SaveFileConfig} from "../core/storage/HomebaseStorageTypes";
import {GuideFlowConfig} from "../../system/GuideFlowConfig";
import {builtInLocations} from "./builtin/BuiltInLocations";

const fileConfig: SaveFileConfig = {
    dataType: 909,
    fileType: 882,
    targetDrive: GuideFlowConfig.DataDrive
}

export interface LocationDefinition extends HomebaseStorage {
    id: string,
    title: string,
    description: string,
    phone: string,
    address: string,
    imageUrl: string,
}

type TDefinition = LocationDefinition;

export class LocationDefinitionProvider extends DefinitionStorageProvider<TDefinition> {
    private static instance: DefinitionStorageProvider<TDefinition>

    static getInstance(dotYouClient: DotYouClient): DefinitionStorageProvider<TDefinition> {
        if (LocationDefinitionProvider.instance == null) {
            LocationDefinitionProvider.instance = new LocationDefinitionProvider(fileConfig, dotYouClient, builtInLocations);
        }

        return LocationDefinitionProvider.instance;
    }
}