import {Contact} from "../../../providers/contact/ContactProvider";
import {normalizeGuid} from "../../../providers/DataHelpers";

export const Westley = {
    id: normalizeGuid("47d6681a-c1ad-4680-a89c-8be1f2a4af01"),
    firstname: 'Westley',
    surname: 'Blackmask',
    email: 'blackmask@yahoo.com',
    phone: '+1-611-961-2806',
    mmsStudentNumber: '7938133',
    status: 'Initiated',
    dateOfBirth: -1166745600,
};

export const Vizzini = {
    id: normalizeGuid("47d6681a-1111-4680-a89c-8be1f2a4af01"),
    firstname: 'Vizzini',
    surname: 'the Bald',
    email: 'anitameyer@yahoo.com',
    phone: '322-355-3940',
    mmsStudentNumber: '213066',
    status: null,
    dateOfBirth: -1832025600,
};

export const Fezzick = {
    id: normalizeGuid("47d6681a-4444-4444-a89c-8be1f2a4af01"),
    firstname: 'Fezzik',
    surname: 'Watts',
    email: 'fezzik@hotmail.com',
    phone: '(451)191-3376',
    mmsStudentNumber: '59392622',
    status: 'LifeActivated',
    dateOfBirth: -1641254400,
}
export const humperdink = {
    id: normalizeGuid("7925c9cc-99df-52b5-ca7e-722abb18abb3"),
    firstname: 'Prince',
    surname: 'Humperdinck',
    email: 'humperdinck@gmail.com',
    phone: '+1-679-868-7748x45846',
    mmsStudentNumber: '8921767',
    status: 'Initiated',
    dateOfBirth: 614736000,
}
export const buttercup = {
    id: normalizeGuid("fc0e6393-aa59-fad9-b3fd-560d955ad03c"),
    firstname: 'Buttercup',
    surname: 'Anderson',
    email: 'lrobinson@hotmail.com',
    phone: '018.599.0424',
    mmsStudentNumber: '27195420',
    status: "LifeActivated",
    dateOfBirth: -161913600,
};

export const sampleContacts: Contact[] = [
    Westley,
    Vizzini,
    Fezzick,

    humperdink,
    buttercup,
    {
        id: undefined,
        firstname: 'Deborah',
        surname: 'Ford',
        email: 'andrewwhite@espinoza.com',
        phone: '001-913-237-5389x906',
        mmsStudentNumber: '41663004',
        status: 'LifeActivated',
        dateOfBirth: 214704000,
    },
    {
        id: undefined,
        firstname: 'Miracle',
        surname: 'Max',
        email: 'tyronethomas@gmail.com',
        phone: '001-337-581-2704x08436',
        mmsStudentNumber: '57345607',
        status: 'Initiated',
        dateOfBirth: 1657929600,
    },
    {
        id: undefined,
        firstname: 'Austin',
        surname: 'Spears',
        email: 'brandonle@yahoo.com',
        phone: '+1-510-020-9893x802',
        mmsStudentNumber: '63916660',
        status: 'LifeActivated',
        dateOfBirth: -1042761600,
    },
    {
        id: undefined,
        firstname: 'Kristina',
        surname: 'Rubio',
        email: 'pamelahernandez@yahoo.com',
        phone: '+1-934-664-1586x23553',
        mmsStudentNumber: '86083222',
        status: null,
        dateOfBirth: 922060800,
    }
];
