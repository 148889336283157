import {add, dinero, DineroSnapshot, toDecimal, toSnapshot} from "dinero.js";
import {generalConfig} from "../system/config";

export const dineroOrZero = (data: DineroSnapshot<any>): DineroSnapshot<any> => {
    if (data) {
        return data;
    }
    return dinero({amount: 0, currency: generalConfig.currency}).toJSON();
}
export const calculateTotal = (snapshots: DineroSnapshot<number>[]): DineroSnapshot<any> => {

    // console.log('sn', snapshots);

    let total = dinero({amount: 0, currency: generalConfig.currency});

    if (!snapshots || snapshots.length === 0) {
        return total.toJSON();
    }

    const addMany = (addends: any[]) => addends.reduce(add);
    total = addMany(snapshots.map(dinero));

    return total.toJSON()
}

export const isDineroSnapshot = (obj: any): obj is DineroSnapshot<any> => {
    return (
        typeof obj === 'object' &&
        obj !== null &&
        'amount' in obj &&
        'currency' in obj &&
        'scale' in obj &&
        typeof obj.amount === 'number' &&
        typeof obj.currency === 'object' &&
        'code' in obj.currency &&
        'exponent' in obj.currency &&
        typeof obj.currency.code === 'string' &&
        typeof obj.currency.exponent === 'number' &&
        typeof obj.scale === 'number'
    );
}

export const parseDineroSnapshot = (value: any) => {

    let v = isDineroSnapshot(value) ? value.amount : value;
    const amount = parseFloat(v) * Math.pow(10, generalConfig.currency.exponent);
    const d = dinero({amount: amount, currency: generalConfig.currency});
    return toSnapshot(d);
}

export const formatDinero = (snapshot: DineroSnapshot<any>): Number => {

    if (!snapshot) {
        return Number(0);
    }
    const transformer = (amt: any) => {
        return `${Number(amt.value)}`;
    };

    return Number(toDecimal(dinero(snapshot), transformer));
    // return Number(toDecimal(dinero(snapshot), transformer)) * Math.pow(10, generalConfig.currency.exponent);
}
