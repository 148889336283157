import React, {useState} from "react";
import {useGuideFlowProviderContext} from "../../../GuideFlowProviderContext";
import {useForm} from "react-hook-form";
import {ErrorMessage, Field, Label} from "../../controls/tailwindui/fieldset";
import {Input} from "../../controls/tailwindui/input";
import {useNavigate} from "react-router-dom";
import toast from "react-hot-toast";
import {Button} from "../../controls/tailwindui/button";
import SpinnerIcon from "../../controls/icons/SpinnerIcon";
import {SECTION_ROOT} from "./FormTypes";
import {Contact} from "../../../providers/contact/ContactProvider";

const emptyContact: Contact = {
    id: "",
    firstname: "",
    surname: "",
    email: "",
    phone: "",
    mmsStudentNumber: "",
    status: null,
    dateOfBirth: 0
};


export default function AddNewContactView() {
    const navigate = useNavigate();
    const context = useGuideFlowProviderContext();
    const {register, reset, handleSubmit, formState: {errors}} = useForm<Contact>({defaultValues: emptyContact});

    const [isSaving, setIsSaving] = useState(false);

    const onSubmit = async (data: Contact) => {
        setIsSaving(true)
        await context.Contacts.save(data);
        reset(emptyContact);
        toast.success("New contact added", {icon: '🥰'});
        setIsSaving(false);
        navigate(SECTION_ROOT);
    };

    return (
        <div className="p-3">
            <div>
                <h2 className="text-2xl font-bold tracking-tight text-gray-900">New Contact</h2>
                <p className="mt-1 text-sm leading-6 text-gray-400">
                    You can add images and other information later.
                </p>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="xl:gap-x-16">
                    <div>
                        <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
                            {/*<h2 className="text-lg font-medium text-gray-900">Contact information</h2>*/}
                            <div>
                                <Field>
                                    <Label>First Name</Label>
                                    <Input {...register("firstname", {required: true,})} invalid={errors?.firstname != null} autoFocus={true}/>
                                    {errors?.firstname && <ErrorMessage>*{errors.firstname.message}</ErrorMessage>}
                                </Field>
                            </div>

                            <div>
                                <Field>
                                    <Label>Surname</Label>
                                    <Input {...register("surname", {required: true,})} invalid={errors?.surname != null}/>
                                    {errors?.surname && <ErrorMessage>*{errors.surname.message}</ErrorMessage>}
                                </Field>
                            </div>
                            <div>
                                <Field>
                                    <Label>Email</Label>
                                    <Input {...register("email", {required: false,})} invalid={errors?.email != null}/>
                                    {errors?.email && <ErrorMessage>*{errors.email.message}</ErrorMessage>}
                                </Field>
                            </div>
                            <div>
                                <Field>
                                    <Label>Phone</Label>
                                    <Input {...register("phone", {required: false,})} invalid={errors?.phone != null}/>
                                    {errors?.phone && <ErrorMessage>*{errors.phone.message}</ErrorMessage>}
                                </Field>
                            </div>

                            <div>
                                <Field>
                                    <Label>Student Number</Label>
                                    <Input {...register("mmsStudentNumber", {required: false,})} invalid={errors?.mmsStudentNumber != null}/>
                                    {errors?.mmsStudentNumber && <ErrorMessage>*{errors.mmsStudentNumber.message}</ErrorMessage>}
                                </Field>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mt-6 flex items-center justify-end gap-x-6">
                    <Button type="button" disabled={isSaving} onClick={() => navigate(SECTION_ROOT)} outline={true}>
                        Cancel
                    </Button>
                    <Button
                        type="submit"
                        disabled={isSaving}
                        color="indigo">
                        {isSaving && <SpinnerIcon/>}
                        Save
                    </Button>
                </div>
            </form>
        </div>
    )
}
