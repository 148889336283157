import {ChevronLeftIcon} from "@heroicons/react/20/solid";
import React, {useEffect, useState} from "react";
import {Outlet, useNavigate, useParams} from "react-router-dom";
import {useGuideFlowProviderContext} from "../../../GuideFlowProviderContext";
import AddHealingDefinitionView from "./AddHealingDefinitionView";
import {HealingDefinition} from "../../../providers/definitions/HealingDefinitionProvider";
import {Tab} from "../controls/Tab";
import {Bars3Icon, ClockIcon, DocumentIcon} from "@heroicons/react/24/outline";
import {HealingDefinitionThumbnail} from "./HealingDefinitionThumbnail";
import ImageCaptureDialog from "../../controls/vidence/image/ImageCaptureDialog";
import {Image64Data} from "../../../providers/core/storage/HomebaseStorageTypes";

export default function HealingOverview() {

    const navigate = useNavigate();
    const {id} = useParams();
    const [definition, setDefinition] = useState<HealingDefinition>()
    const context = useGuideFlowProviderContext();
    const [showImageCapture, setShowImageCapture] = useState(false);

    const handleUpdateProfileImage = () => {
        setShowImageCapture(true);
    }

    const handleSaveProfileImage = async (data: Image64Data) => {
        await context.HealingDefinitions.saveProfileImage(id, definition.storage.versionTag, data);
    }
    
    useEffect(() => {
        if (id !== "new") {
            context.HealingDefinitions.get(id).then(definition => {
                setDefinition(definition);
            });
        }
    }, [context.HealingDefinitions, context.HealingDefinitions.StateChanged, id]);

 
    const backToList = () => {
        navigate("/healings");
    };

    if (id === "new") {
        return <AddHealingDefinitionView/>
    }

    const coverImageUri = "/images/demo/healingbg.png";
    return (
        <>
            <ImageCaptureDialog aspectRatio={1}
                                isOpen={showImageCapture}
                                onSave={handleSaveProfileImage}
                                onClose={() => setShowImageCapture(false)}/>
            {/* Breadcrumb */}
            <nav className="flex items-start px-4 py-3 sm:px-6 lg:px-8 xl:hidden" aria-label="Breadcrumb">
                <button onClick={backToList} className="inline-flex items-center space-x-3 text-sm font-medium text-gray-900">
                    <ChevronLeftIcon className="-ml-2 h-5 w-5 text-gray-400" aria-hidden="true"/>
                    <span>Healings</span>
                </button>
            </nav>

            <article>
                {/* definition header */}
                <div>
                    <div className="relative">
                        <img className="h-32 w-full object-cover lg:h-48" src={coverImageUri} alt=""/>
                        {/*<div className="absolute bottom-0 right-0 px-4 py-1 flex flex-row justify-end">*/}
                        {/*    <button*/}
                        {/*        onClick={handleUpdateCoverImage}*/}
                        {/*        type="button"*/}
                        {/*        className="inline-flex justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">*/}
                        {/*        <PencilIcon className="-ml-0.5 h-4 w-4 text-gray-400" aria-hidden="true"/>*/}
                        {/*    </button>*/}
                        {/*</div>*/}
                    </div>
                    <div className="mx-auto max-w-5xl px-4 sm:px-6 lg:px-8">
                        <div className="-mt-12 sm:-mt-16 sm:flex sm:items-end sm:space-x-5">
                            <HealingDefinitionThumbnail onClick={handleUpdateProfileImage} id={id} className="size-24 lg:size-36"/>
                            <div className="mt-6 sm:flex sm:min-w-0 sm:flex-1 sm:items-center sm:justify-end sm:space-x-6 sm:pb-1">
                                <div className="mt-6 min-w-0 flex-1 sm:hidden 2xl:block">
                                    <h1 className="truncate text-2xl font-bold text-gray-900">{definition?.title}</h1>
                                </div>
                            </div>
                        </div>
                        <div className="mt-6 hidden min-w-0 flex-1 sm:block 2xl:hidden">
                            <h1 className="truncate text-2xl font-bold text-gray-900">{definition?.title}</h1>
                        </div>
                    </div>
                </div>

                <div className="mt-6 sm:mt-2 2xl:mt-5 w-full overflow-x-scroll">
                    <div className="flex border-b border-gray-200 w-full">
                        <nav className="-mb-px flex space-x-4 whitespace-nowrap pr-4" aria-label="Tabs">
                            <Tab name="Profile" icon={Bars3Icon} to="overview"/>
                            <Tab name="Documents" icon={DocumentIcon} to="documents"/>
                            <Tab name="Booking" icon={ClockIcon} to="booking-config"/>
                            <Tab name="History" icon={ClockIcon} to="booking-history"/>
                        </nav>
                    </div>
                </div>
                <div className="mx-auto mt-2 max-w-5xl px-4 sm:px-6 lg:px-8 overflow-y-auto">
                    <Outlet/>
                </div>
            </article>
        </>
    )
}