import React, {useEffect, useState} from "react";
import {ExclamationTriangleIcon} from "@heroicons/react/16/solid";

export function CassieOfflineBanner() {

    const [isOffline, setIsOffline] = useState(true);

    useEffect(() => {
        setIsOffline(!window.navigator.onLine);
    }, []);

    if (isOffline) {
        return (<div className="border-l-4 border-yellow-400 bg-yellow-50 p-4">
                <div className="flex">
                    <div className="flex-shrink-0">
                        <ExclamationTriangleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true"/>
                    </div>
                    <div className="ml-3">
                        <p className="text-sm text-yellow-700">
                            You're offline. You cannot save until you have an internet connection
                        </p>
                    </div>
                </div>
            </div>
        )

    }

    return (<div></div>);
}
