import {useParams} from "react-router-dom";
import {useGuideFlowProviderContext} from "../../../../GuideFlowProviderContext";
import React, {useEffect, useState} from "react";
import {ContactProgressionEvent} from "../../../../providers/contact/ContactProgressionEventProvider";
import {LoadingPanel} from "../../../controls/vidence/blanks/LoadingPanel";
import {ContactProfileHeader} from "../../../controls/renderers/Contacts/ContactProfileHeader";
import DateTimeUtcText from "../../../controls/renderers/DateTime/DateTimeUtcText";
import LocationTitle from "../../../controls/renderers/Locations/LocationTitle";

export default function ClassHistoryTab() {


    const {id} = useParams();
    const context = useGuideFlowProviderContext();

    const [history, setHistory] = useState<ContactProgressionEvent[]>();
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        context.Contacts.ProgressionEvents.getAllByDefinitionId(id, {cassieQueryOptions: {forceServerCall: true}}).then(history => {
            setHistory(history);
            setLoaded(true);
        });
    }, [id, context.Contacts.ProgressionEvents.StateChanged, context.Contacts.ProgressionEvents]);


    if (!loaded) {
        return <LoadingPanel text="loading history..."/>
    }

    // console.log(history)

    return (
        <>
            <div className="">
                <div className="sm:flex sm:items-center">
                    {/*<div className="sm:flex-auto">*/}
                    {/*    <h1 className="text-base font-semibold leading-6 text-gray-900">Attendees</h1>*/}
                    {/*    <p className="mt-2 text-sm text-gray-700">*/}
                    {/*        Confirmed and candidate attendees*/}
                    {/*    </p>*/}
                    {/*</div>*/}
                    <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                        {/*<button*/}
                        {/*    type="button"*/}
                        {/*    onClick={() => handleAddEntry()}*/}
                        {/*    className="relative inline-flex rounded-md items-center gap-x-1.5 bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:z-10 hover:bg-gray-50 focus:z-10">*/}
                        {/*    <PlusCircleIcon className="-ml-0.5 h-5 w-5 text-gray-400" aria-hidden="true"/>Add*/}
                        {/*</button>*/}
                    </div>
                </div>
                <div className="-mx-4 mt-8 sm:-mx-0">
                    <table className="min-w-full divide-y divide-gray-300">
                        <thead>
                        <tr>
                            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                                Name
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                When
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                Where
                            </th>
                        </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                        {history.map((historyItem) => (
                            <tr key={historyItem.id}>
                                <td>
                                    <ContactProfileHeader id={historyItem.contactId}/>
                                </td>
                                <td className="px-3 py-4 text-sm text-gray-500">
                                    <DateTimeUtcText datetime={historyItem.eventDateUtcSeconds} date year fromNow/>
                                </td>
                                <td className="py-4 pl-3 pr-4 text-sm sm:pr-0">
                                    <LocationTitle id={historyItem.locationId}/>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )

}
