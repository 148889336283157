import React from 'react'
import {Outlet, useNavigate} from "react-router-dom";
import {isMediumScreen, isSmallScreen} from "../../../helpers/breakpoint";
import {SettingsList} from "./SettingsList";
import {ChevronLeftIcon} from "@heroicons/react/20/solid";

export default function SettingsSectionIndex() {
    const tiny = isSmallScreen() || isMediumScreen();

    const navigate = useNavigate();
    const backToList = () => {
        navigate("/settings/");
    };

    const path = window.location.pathname;
    const isRoot = path.endsWith("settings/") || path.endsWith("settings");


    const showDirectory = (tiny && isRoot);

    return (
        <div className="relative z-0 flex flex-1 overflow-hidden">
            {showDirectory &&
                <aside className="w-full flex-shrink-0 border-r border-gray-200 order-first flex flex-col">
                    <SettingsList/>
                </aside>
            }

            {!tiny &&
                <aside className="w-96 flex-shrink-0 border-r border-gray-200 xl:order-first xl:flex xl:flex-col">
                    <SettingsList/>
                </aside>}

            <main className="relative z-0 flex-1 overflow-y-auto focus:outline-none xl:order-last">
                <nav className="flex items-start px-4 py-3 sm:px-6 lg:px-8 xl:hidden" aria-label="Breadcrumb">
                    <button onClick={backToList} className="inline-flex items-center space-x-3 text-sm font-medium text-gray-900">
                        <ChevronLeftIcon className="-ml-2 h-5 w-5 text-gray-400" aria-hidden="true"/>
                        <span>Settings</span>
                    </button>
                </nav>
                <Outlet/>
            </main>
        </div>
    )
}
