import React, {useState} from "react";
import {LoadingPanel} from "../../../controls/vidence/blanks/LoadingPanel";
import {Button} from "../../../controls/tailwindui/button";
import {InitProvider} from "../../../../providers/InitProvider";
import {useGuideFlowProviderContext} from "../../../../GuideFlowProviderContext";
import useAuth from "../../../../hooks/auth/useAuth";
import fileDownload from "js-file-download";
import {useFriendlyText} from "../../../../hooks/friendlyText/useFriendlyText";
import moment from "moment";
import DateTimeUtcText from "../../../controls/renderers/DateTime/DateTimeUtcText";
import {jsonStringify64} from "@youfoundation/js-lib/helpers";

interface ExportedFile {
    name: string,
    data: any
}

export default function DataSettingsTab() {

    const context = useGuideFlowProviderContext();
    const {getDotYouClient} = useAuth();
    const client = getDotYouClient();

    const [workingState, setWorkingState] = useState<"prompt" | "working" | "work-complete">("prompt");
    const [workType, setWorkType] = useState<"export" | "init" | "">("");
    const [workingMessage, setWorkingMessage] = useState<string>("");
    const [exportData, setExportData] = useState<ExportedFile[]>(null);

    const runExport = async () => {
        setWorkingState("working");
        setWorkType("export");
        setWorkingMessage("Exporting your contacts...");

        const files: ExportedFile[] = []
        const contactData = await context.Contacts.exportGraph();

        files.push({
            name: `contacts/${contactData.Contacts.name}.headers.json`,
            data: contactData.Contacts.docs
        });

        files.push({
            name: `contacts/${contactData.Contacts.name}.payloads.json`,
            data: contactData.Contacts.payloads
        });

        files.push({
            name: `contacts/${contactData.Contacts.name}-progression.headers.json`,
            data: contactData.ProgressionEvents.docs
        });

        files.push({
            name: `contacts/${contactData.Contacts.name}-progression.payloads.json`,
            data: contactData.ProgressionEvents.payloads
        });

        
        files.push({
            name: `contacts/${contactData.Contacts.name}-notes.headers.json`,
            data: contactData.Notes.docs
        });

        files.push({
            name: `contacts/${contactData.Contacts.name}-notes.payloads.json`,
            data: contactData.Notes.payloads
        });

        setWorkingMessage("Exporting your classes...");

        const classData = await context.ClassDefinitions.exportGraph("classes");
        files.push({
            name: `classes/${classData.name}.headers.json`,
            data: classData.docs
        });

        files.push({
            name: `classes/${classData.name}.payloads.json`,
            data: classData.payloads
        });

        setWorkingMessage("Exporting your documents...");
        const documentData = await context.Documents.exportGraph("documents");
        files.push({
            name: `documents/${documentData.name}.headers.json`,
            data: documentData.docs
        });

        files.push({
            name: `documents/${documentData.name}.payloads.json`,
            data: documentData.payloads
        });

        setExportData(files);
        setWorkingState("work-complete");
    }

    const runDataInitializer = async () => {
        setWorkingState("working");
        setWorkType("init");
        setWorkingMessage("Re-running initialization");

        const initProvider = InitProvider.getInstance(client, context);
        await initProvider.performInit(onStatusMessage);

        setWorkingState("work-complete");
    }

    function onStatusMessage(message: string) {
        setWorkingMessage(message);
    }

    if (workingState === "working") {
        return (<div className="p-3 mx-auto max-w-2xl sm:space-y-5 lg:mx-0 lg:max-w-none">
            <LoadingPanel text={workingMessage}/>
        </div>);
    }

    if (workingState === "work-complete") {
        switch (workType) {
            case "export":
                return <DataExportComplete data={exportData} exportDatetime={moment().unix()} onComplete={() => setWorkingState("prompt")}/>

            case "init":
                return <DataInitComplete onComplete={() => setWorkingState("prompt")}/>
        }
    }

    return (
        <div className="p-3 mx-auto max-w-2xl sm:space-y-5 lg:mx-0 lg:max-w-none">
            <div className="sm:space-y-5">
                <h2 className="text-base font-semibold leading-7 text-gray-900">Data Export</h2>
                <p className="mt-1 text-sm leading-6 text-gray-500">
                    This will export your data <b>unencrypted</b> (meaning anyone with the file can see your client data). You can save this back up somewhere.
                    This is required during the alpha and beta
                    phases; if you do not do this (regularly) and the unlikely event occurs that you need to restore the data, I cannot help you </p>
                <Button onClick={runExport}>Run export now</Button>
            </div>
            <hr className="my-3"/>

            <div className="sm:space-y-5">
                <h2 className="text-base font-semibold leading-7 text-gray-900">Built-In Data</h2>
                <p className="mt-1 text-sm leading-6 text-gray-500">
                    Built-in data includes healings, classes, initiations, and other amongst other data. You can rerun
                    this installer to include updated definitions.
                </p>
                <p className="mt-1 text-sm leading-6 text-gray-500">
                    This will not overwrite your existing items or changes you've made to existing items.
                </p>
                <Button onClick={runDataInitializer}>Run Updates Now</Button>
            </div>

        </div>
    )
}

function DataInitComplete(props: { onComplete: Function }) {

    return (
        <div className="p-3 mx-auto max-w-2xl sm:space-y-5 lg:mx-0 lg:max-w-none">
            <div className="sm:space-y-5">
                <h2 className="text-base font-semibold leading-7 text-gray-900">Data Init complete</h2>
                <p className="mt-1 text-sm leading-6 text-gray-500">
                    Data initialization has been executed. You should have any new definitions
                    in their respective lists
                </p>
                <div className="space-x-3">
                    <Button onClick={() => props.onComplete()}>Done</Button>
                </div>
            </div>
        </div>)
}

function DataExportComplete(props: { data: ExportedFile[], exportDatetime: number, onComplete: Function }) {

    const text = useFriendlyText();

    const downloadData = async () => {
        const t = text.asShortDateWithYear(props.exportDatetime);

        const zip = require('jszip')();

        for (let i = 0; i < props.data.length; i++) {
            const file = props.data[i];
            zip.file(file.name, new Blob([jsonStringify64(file.data)], {type: 'application/json'}));
        }

        const content = await zip.generateAsync({
            type: "blob",
            compression: "DEFLATE",
            compressionOptions: {
                level: 9
            }
        });

        fileDownload(content, "guideflow-export-" + t + "-" + text.asTime(props.exportDatetime) + ".zip");
        // fileDownload(JSON.stringify(props.data), "guideflow-export-" + t + "-" + text.asTime(props.exportDatetime) + ".json");
    }

    return (
        <div className="p-3 mx-auto max-w-2xl sm:space-y-5 lg:mx-0 lg:max-w-none">
            <div className="sm:space-y-5">
                <h2 className="text-base font-semibold leading-7 text-gray-900">Export complete</h2>
                <p className="mt-1 text-sm leading-6 text-gray-500">
                    Your data has been exported as of <DateTimeUtcText datetime={props.exportDatetime} date time fromNow/>. You can now download
                    this data and save it securely.
                </p>
                <p className="mt-1 text-sm leading-6 text-gray-500">
                    Remember, it is unencrypted so anyone with the file can read
                    your client data.
                </p>
                <div className="space-x-3">
                    <Button onClick={downloadData}>Download</Button>
                    <Button onClick={() => props.onComplete()}>Done</Button>
                </div>
            </div>
        </div>)
}