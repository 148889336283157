import {Menu, Transition} from "@headlessui/react";
import {DocumentArrowUpIcon, CameraIcon, EllipsisVerticalIcon} from "@heroicons/react/20/solid";
import React, {Fragment, useEffect, useState} from "react";
import {classNames} from "../../../viewUtils";
import EmptyScreen from "../../../controls/vidence/blanks/EmptyScreenAction";
import {useGuideFlowProviderContext} from "../../../../GuideFlowProviderContext";
import {DocumentDescriptor, DocumentPayload} from "../../../../providers/core/documents/DocumentProvider";
import {getNewId} from "@youfoundation/js-lib/helpers";
import {useFriendlyText} from "../../../../hooks/friendlyText/useFriendlyText";
import {getTotalPayloadSize} from "../../../../providers/HomebaseHelpers";
import DocumentViewerModal from "./DocumentViewerModal";
import FileUploadDialog from "./fileUpload/FileUploadDialog";
import {LoadingPanel} from "../blanks/LoadingPanel";
import {Thumbnail} from "./images/Thumbnail";
import {observer} from "mobx-react-lite";
import ConfirmationDialog from "../modal/ConfirmationDialog";

interface DocumentListProps {
    ownerId: string;
    dataType: number;
}

export const DocumentsList = observer((props: DocumentListProps) => {

    const [documentForDeletion, setDocumentForDeletion] = useState<DocumentDescriptor | null>(null);
    const [showFileUpload, setShowFileUpload] = useState(false);
    const [documents, setDocuments] = useState<DocumentDescriptor[]>();
    const [loaded, setLoaded] = useState(false);
    const [selectedDocument, setSelectedDocument] = useState<DocumentDescriptor | null>(null);
    const context = useGuideFlowProviderContext();
    const text = useFriendlyText();

    useEffect(() => {
        context.Documents.getAll(props.ownerId, {dataType: props.dataType}).then((docs) => {
            setLoaded(true);
            setDocuments(docs);
        });
    }, [context.Documents, context.Documents.StateChanged, props]);

    const handleUploadDocument = () => {
        //
        // context.Contacts.saveDocument(id, doc, docContent).then(result => {
        //
        // });
    }

    const handleFilesSelected = async (files: File[]) => {

        const promises = [];

        files.forEach(f => {

            const doc: DocumentDescriptor = {
                id: getNewId(),
                ownerId: props.ownerId,
                title: f.name,
                date: 0,
                dataType: props.dataType,
            }

            const content: DocumentPayload = {
                order: 0,
                mimeType: f.type,
                width: 0,
                height: 0,
                data: f as Blob
            }

            promises.push(context.Documents.save(doc, content));

        });

        await Promise.allSettled(promises);
    }

    const viewDocument = async (document: DocumentDescriptor) => {
        setSelectedDocument(document);
    }

    const deleteDocument = async (document: DocumentDescriptor) => {
        setDocumentForDeletion(document);
    }

    const handleFinalizeDelete = async (answer: boolean) => {
        if (answer) {
            await context.Documents.delete(documentForDeletion.id);
        }

        setDocumentForDeletion(null);
    }

    if (!loaded) {
        return (<LoadingPanel text="loading docs"/>);
    }

    if (loaded) {
        return (
            <div className="w-full">

                <ConfirmationDialog
                    isOpen={documentForDeletion !== null}
                    title="Are you sure?"
                    onAnswerReceived={handleFinalizeDelete}>
                    Are you sure you want to delete <b>{documentForDeletion?.title}</b>
                </ConfirmationDialog>

                <DocumentViewerModal
                    documentDescriptor={selectedDocument}
                    isOpen={selectedDocument !== null}
                    onClose={() => setSelectedDocument(null)}/>

                <FileUploadDialog
                    isOpen={showFileUpload}
                    onFilesSelected={handleFilesSelected}
                    onClose={() => setShowFileUpload(false)}/>

                {/*toolbar*/}
                <div className="flex h-16 flex-col justify-center">
                    <div className="flex justify-between py-3">
                        {/* Left buttons */}
                        <div className="isolate inline-flex rounded-md shadow-sm sm:space-x-3 sm:shadow-none">
                              <span className="inline-flex sm:shadow-sm">
                                <button
                                    type="button"
                                    onClick={() => setShowFileUpload(true)}
                                    className="relative inline-flex rounded-l-md items-center gap-x-1.5 bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:z-10 hover:bg-gray-50 focus:z-10">
                                  <DocumentArrowUpIcon className="-ml-0.5 h-5 w-5 text-gray-400" aria-hidden="true"/> Upload
                                </button>
                                  <button
                                      type="button"
                                      className="relative inline-flex -ml-px rounded-r-md items-center gap-x-1.5 bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:z-10 hover:bg-gray-50 focus:z-10">
                                    <CameraIcon className="-ml-0.5 h-5 w-5 text-gray-400" aria-hidden="true"/>
                                    Take Photo
                                  </button>
                              </span>
                        </div>
                    </div>
                </div>

                {loaded && documents?.length === 0 &&
                    <EmptyScreen text="Looks empty in here... Upload documents" onClick={handleUploadDocument}/>}

                {loaded && documents?.length > 0 &&
                    <ul className="divide-y divide-gray-100">
                        {documents.map((doc) => (
                            <li key={doc.id} className="flex items-center justify-between gap-x-3 py-5">
                                <div className="min-w-16">
                                    <Thumbnail document={doc} className="h-16 w-16 border border-gray-300 bg-white text-gray-300"/>
                                </div>
                                <div className="flex flex-row w-full justify-between gap-x-1">
                                    <div className="min-w-0 max-w-80">
                                        <div className="flex items-start gap-x-3">
                                            <p className="truncate text-sm font-semibold leading-6 text-gray-900">{doc.title}</p>
                                            {/*<p*/}
                                            {/*    className={classNames(*/}
                                            {/*        statuses[doc.status],*/}
                                            {/*        'rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset'*/}
                                            {/*    )}>*/}
                                            {/*    {doc.status}*/}
                                            {/*</p>*/}
                                            {/*<p className="rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset">*/}
                                            {/*    abcd*/}
                                            {/*</p>*/}
                                        </div>
                                        <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                                            <p className="truncate">{text.bytesAsText(getTotalPayloadSize(doc.storage.payloads))}</p>
                                            <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-current">
                                                <circle cx={1} cy={1} r={1}/>
                                            </svg>
                                            <p className="whitespace-nowrap">
                                                Uploaded {text.homebaseCreatedLong(doc.storage)}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="flex flex-none items-center gap-x-4">
                                        <button
                                            onClick={() => viewDocument(doc)}
                                            className="hidden rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block">
                                            View <span className="sr-only">, {doc.title}</span>
                                        </button>
                                        <Menu as="div" className="relative flex-none">
                                            <Menu.Button className="-m-2.5 block p-2.5 text-gray-500 hover:text-gray-900">
                                                <span className="sr-only">Open options</span>
                                                <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true"/>
                                            </Menu.Button>
                                            <Transition
                                                as={Fragment}
                                                enter="transition ease-out duration-100"
                                                enterFrom="transform opacity-0 scale-95"
                                                enterTo="transform opacity-100 scale-100"
                                                leave="transition ease-in duration-75"
                                                leaveFrom="transform opacity-100 scale-100"
                                                leaveTo="transform opacity-0 scale-95">
                                                <Menu.Items
                                                    className="absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                                                    <Menu.Item>
                                                        {({focus}) => (
                                                            <button
                                                                onClick={() => viewDocument(doc)}
                                                                className={classNames(focus ? 'bg-gray-50' : '', 'w-full block px-3 py-1 text-sm leading-6 text-gray-900')}>View</button>
                                                        )}
                                                    </Menu.Item>
                                                    <Menu.Item>
                                                        {({focus}) => (
                                                            <button
                                                                onClick={() => deleteDocument(doc)}
                                                                className={classNames(focus ? 'bg-gray-50' : '', 'w-full block px-3 py-1 text-sm leading-6 text-gray-900')}>Delete</button>
                                                        )}
                                                    </Menu.Item>
                                                </Menu.Items>
                                            </Transition>
                                        </Menu>
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                }
            </div>)
    }
});