import {ChevronLeftIcon} from "@heroicons/react/20/solid";
import {Outlet, useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {useGuideFlowProviderContext} from "../../../GuideFlowProviderContext";
import AddNewContactView from "./AddNewContactView";
import ImageCaptureDialog from "../../controls/vidence/image/ImageCaptureDialog";
import {ContactProfileImage} from "./ContactProfileImage";
import {Contact} from "../../../providers/contact/ContactProvider";
import {Image64Data} from "../../../providers/core/storage/HomebaseStorageTypes";
import {Tab} from "../controls/Tab";
import {ArrowUpCircleIcon, LightBulbIcon, DocumentIcon, ListBulletIcon, QueueListIcon} from "@heroicons/react/24/outline";

export default function ContactOverview() {
    const navigate = useNavigate();
    const {id} = useParams();

    const context = useGuideFlowProviderContext();
    const [showImageCapture, setShowImageCapture] = useState(false);
    const [contact, setContact] = useState<Contact | undefined>();

    useEffect(() => {
        if (id !== "new") {
            context.Contacts.get(id).then(setContact);
        }
    }, [context.Contacts, context.Contacts.StateChanged, id]);

    const backToList = () => {
        navigate("/contacts");
    };

    const handleUpdateProfileImage = () => {
        setShowImageCapture(true);
    }

    const handleSaveProfileImage = async (data: Image64Data) => {
        await context.Contacts.saveProfileImage(id, contact.storage.versionTag, data);
    }

    // const tagForFollowup = async () => {}

    // const coverImageUri = "https://source.unsplash.com/random/600×600";
    // const coverImageUri = "/images/demo/westley-climbs.webp";
    const coverImageUri = "/images/demo/sunrise-sunset-facts.jpg";
    const displayName = `${contact?.firstname} ${contact?.surname}`;
    const initials = `${contact?.firstname[0]} ${contact?.surname[0]}`;

    if (id === "new") {
        return <AddNewContactView/>
    }

    return (
        // <div className="grid h-full grid-rows-[auto_auto_1fr] overflow-x-hidden">
        <div className="grid grid-flow-row auto-rows-max overflow-x-hidden">
            <div>
                <ImageCaptureDialog aspectRatio={1}
                                    isOpen={showImageCapture}
                                    onSave={handleSaveProfileImage}
                                    onClose={() => setShowImageCapture(false)}/>

                <nav className="flex items-start px-4 py-3 sm:px-6 lg:px-8 xl:hidden" aria-label="Breadcrumb">
                    <button onClick={backToList} className="inline-flex items-center space-x-3 text-sm font-medium text-gray-900">
                        <ChevronLeftIcon className="-ml-2 h-5 w-5 text-gray-400" aria-hidden="true"/>
                        <span>Contacts</span>
                    </button>
                </nav>

                <div>
                    <div>
                        <img className="h-32 w-full object-cover lg:h-48" src={coverImageUri} alt=""/>
                    </div>
                    <div className="mx-auto max-w-5xl px-4 sm:px-6 lg:px-8">
                        <div className="-mt-12 sm:-mt-16 sm:flex sm:items-end sm:space-x-5">
                            <ContactProfileImage onClick={handleUpdateProfileImage} id={id} initials={initials} className="size-24 lg:size-36"/>
                            <div className="mt-6 sm:flex sm:min-w-0 sm:flex-1 sm:items-center sm:justify-end sm:space-x-6 sm:pb-1">
                                <div className="mt-6 min-w-0 flex-1 sm:hidden 2xl:block">
                                    <h1 className="truncate text-2xl font-bold text-gray-900">{displayName}</h1>
                                </div>
                                <div className="mt-6 flex flex-col justify-stretch space-y-3 sm:flex-row sm:space-x-4 sm:space-y-0">
                                    {/*<Button*/}
                                    {/*    type="button"*/}
                                    {/*    onClick={tagForFollowup}*/}
                                    {/*    className="inline-flex justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">*/}
                                    {/*    <CalendarDaysIcon className="-ml-0.5 h-5 w-5 text-gray-400" aria-hidden="true"/>*/}
                                    {/*    Tag for followup*/}
                                    {/*</Button>*/}
                                    {/*{contact?.phone && <Button*/}
                                    {/*    type="button"*/}
                                    {/*    outline={true}*/}
                                    {/*    href={contact?.phone ? `tel:${contact?.phone}` : ''}*/}
                                    {/*    className="inline-flex justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">*/}
                                    {/*    <PhoneIcon className="-ml-0.5 h-5 w-5 text-gray-400" aria-hidden="true"/>*/}
                                    {/*    Call*/}
                                    {/*</Button>}*/}
                                </div>
                            </div>
                        </div>
                        <div className="mt-6 hidden min-w-0 flex-1 sm:block 2xl:hidden">
                            <h1 className="truncate text-2xl font-bold text-gray-900">{displayName}</h1>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mt-6 sm:mt-2 2xl:mt-5 w-full overflow-x-scroll">
                <div className="flex border-b border-gray-200 w-full">
                    {/*<div className="mx-auto max-w-5xl px-4 sm:px-6 lg:px-8">*/}
                    <nav className="-mb-px flex space-x-4 whitespace-nowrap pr-4" aria-label="Tabs">
                        <Tab icon={QueueListIcon} name="Details" to="overview"/>
                        <Tab icon={LightBulbIcon} name="Nurturing" to="nurturing"/>
                        <Tab icon={ArrowUpCircleIcon} name="Progression" to="progression"/>
                        <Tab icon={DocumentIcon} name="Documents" to="documents"/>
                        <Tab icon={ListBulletIcon} name="Notes" to="notes"/>
                        {/*<Tab name="Bookings" to="bookings"/>*/}
                    </nav>
                    {/*</div>*/}
                </div>
            </div>
            <div className="flex-1 flex-grow">
                <div className="flex h-full mx-auto max-w-5xl px-4 sm:px-6 lg:px-8 overflow-y-auto">
                    <Outlet/>
                </div>
            </div>
        </div>
    )
}