import {DotYouClient} from "@youfoundation/js-lib/core";
import {GuideFlowConfig} from "../system/GuideFlowConfig";
import {HomebaseStorage, SaveFileConfig} from "./core/storage/HomebaseStorageTypes";
import {SimplifiedStorageProvider} from "./core/storage/SimplifiedStorageProvider";
import {GFContext} from "../GuideFlowProviderContext";

const fileConfig = {
    dataType: 111,
    fileType: 909,
    initFileUniqueId: "77bff6c3-c06f-4da6-8846-1e75f90247c7",
    targetDrive: GuideFlowConfig.DataDrive
}

export interface InitFileInfo extends HomebaseStorage {
    id: string;
    version: number;
}

export class InitProvider {
    private static instance: InitProvider
    private readonly provider: SimplifiedStorageProvider<InitFileInfo>;
    private readonly context: GFContext;
    public StateChanged: number = 0;

    // public static FileConfig: SaveFileConfig = fileConfig;

    constructor(config: SaveFileConfig, dotYouClient: DotYouClient, context: GFContext) {
        this.provider = new SimplifiedStorageProvider<InitFileInfo>(config, dotYouClient);
        this.context = context;
    }

    static getInstance(dotYouClient: DotYouClient, context: GFContext) {
        if (InitProvider.instance == null) {
            InitProvider.instance = new InitProvider(fileConfig, dotYouClient, context);
        }
        return InitProvider.instance;
    }

    public async isFirstTimeRun(): Promise<InitFileInfo> {
        return this.provider.getByUniqueId<InitFileInfo>(fileConfig.initFileUniqueId).then(file => {
            return file;
        });
    }

    public async performFirstTimeOps(onStatusMessage: (message: string) => void) {

        const file = await this.isFirstTimeRun();
        if (file) {
            return;
        }

        await this.performInit(onStatusMessage);
        const finalFile: InitFileInfo = {
            id: fileConfig.initFileUniqueId,
            version: 1
        }

        await this.provider.saveHeaderFile(finalFile);
    }

    public async performInit(onStatusMessage: (message: string) => void) {

        const version = 1;
        await this.context.HealingDefinitions.initialize(version, onStatusMessage);
        await this.context.ClassDefinitions.initialize(version, onStatusMessage);
        await this.context.InitiationDefinitions.initialize(version, onStatusMessage);
        await this.context.Locations.initialize(version, onStatusMessage);
    }
}