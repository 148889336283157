import {useGuideFlowProviderContext} from "../../../../../GuideFlowProviderContext";
import {useForm} from "react-hook-form";
import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {formatDinero, parseDineroSnapshot} from "../../../../../helpers/Money";
import toast from "react-hot-toast";
import {Field, Label} from "../../../../controls/tailwindui/fieldset";
import {Input} from "../../../../controls/tailwindui/input";
import {Textarea} from "../../../../controls/tailwindui/textarea";
import {generalConfig} from "../../../../../system/config";
import {Button} from "../../../../controls/tailwindui/button";
import {ClassDefinitionForm, SECTION_ROOT} from "../../FormTypes";
import {CassieAwareSubmitButton} from "../../../../controls/vidence/cassie/CassieAwareSubmitButton";
import {CassieOfflineBanner} from "../../../../controls/vidence/cassie/CassieOfflineBanner";
import {ClassDefinition} from "../../../../../providers/definitions/ClassDefinitionProvider";
import {hoursToMinutes} from "../../../../../helpers/Dates";

export default function ClassDetailsEditView() {

    const {id} = useParams();
    const context = useGuideFlowProviderContext();
    const {register, reset, handleSubmit, formState: {errors}} = useForm<ClassDefinitionForm>();
    const [isSaving, setIsSaving] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        context.ClassDefinitions.get(id).then(def => {
            reset({
                ...def,
                exchangeRaw: formatDinero(def.exchange) as number
            });
        });
    }, [reset, context.ClassDefinitions, context.ClassDefinitions.StateChanged, id]);

    const handleCancel = () => {
        navigate(`${SECTION_ROOT}/${id}/overview`);
    }
    
    const onSubmit = async (formData: ClassDefinitionForm) => {

        setIsSaving(true);
        const exchange = parseDineroSnapshot(formData.exchangeRaw);
        delete formData.exchangeRaw;
        const definition: ClassDefinition = {
            ...formData,
            exchange: exchange
        };

        await context.ClassDefinitions.save(definition);
        toast.success("Healing updated", {icon: "🪄"});
        setIsSaving(false);
        navigate(`${SECTION_ROOT}/${id}/overview`);
    };

    return (
        <div>
            <CassieOfflineBanner/>
            <div className="p-3">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="xl:gap-x-16">
                        <div>
                            <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
                                <div>
                                    <Field>
                                        <Label>Title</Label>
                                        <Input {...register("title", {required: true,})} invalid={errors?.title != null} autoFocus={true}/>
                                        {/*{errors?.title && <ErrorMessage>*{errors.title.message}</ErrorMessage>}*/}
                                    </Field>
                                </div>
                                <div className="sm:col-span-2">
                                    <Field>
                                        <Label>Description</Label>
                                    </Field>
                                    <Textarea {...register("description")}/>
                                </div>
                                <div>
                                    <Field>
                                        <Label>Duration (hours)</Label>
                                    </Field>
                                    <Input type="number"
                                           autoComplete="off"
                                           autoCorrect="off"
                                           autoCapitalize="off"
                                           spellCheck="false"
                                           invalid={errors?.durationMinutes != null}
                                           {...register("durationMinutes", {
                                               required: true,
                                               pattern: /[0-9]/,
                                               setValueAs: v => hoursToMinutes(parseInt(v))
                                           })}/>
                                </div>
                                <div>
                                    <Field>
                                        <Label>Exchange</Label>
                                    </Field>
                                    <Input type="text"
                                           autoComplete="off"
                                           autoCorrect="off"
                                           autoCapitalize="off"
                                           spellCheck="false"
                                           invalid={errors?.exchangeRaw != null}
                                           {...register("exchangeRaw", {
                                               required: true,
                                               pattern: generalConfig.currencyRegEx,
                                           })}/>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="mt-6 flex items-center justify-end gap-x-6">
                        <Button type="button" disabled={isSaving} onClick={handleCancel} outline={true}>
                            Cancel
                        </Button>
                        <CassieAwareSubmitButton isSaving={isSaving}/>
                    </div>
                </form>
            </div>
        </div>
    )
}