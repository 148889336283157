import {useEffect, useState} from "react";
import {ContactNote} from "../../../../providers/contact/ContactNotesProvider";
import {useGuideFlowProviderContext} from "../../../../GuideFlowProviderContext";
import {useParams} from "react-router-dom";
import moment from "moment";
import {CassieAwareSubmitButton} from "../../../controls/vidence/cassie/CassieAwareSubmitButton";
import DateTimeUtcText from "../../../controls/renderers/DateTime/DateTimeUtcText";

export default function ContactNotesTab() {

    const {id} = useParams();
    const [loaded, setLoaded] = useState(false);
    const [isSaving, setIsSaving] = useState(false);

    const context = useGuideFlowProviderContext();
    const [notes, setNotes] = useState<ContactNote[]>([]);
    const [newNoteContent, setNewNoteContent] = useState("");

    useEffect(() => {
        context.Contacts.Notes.getAll(id).then((notes) => {
            const sorted = notes.sort((a, b) => a.timestamp - b.timestamp);
            setNotes(sorted);
            setLoaded(true);
        });

    }, [id, context.Contacts.Notes, context.Contacts.Notes.StateChanged]);

    const handleSubmit = async (event: any) => {
        event.preventDefault();
        if (!newNoteContent) {
            return;
        }

        setIsSaving(true);

        const note: ContactNote = {
            id: "",
            contactId: id,
            content: newNoteContent,
            timestamp: moment().unix()
        };

        await context.Contacts.Notes.save(note);
        setIsSaving(false)
        setNotes(ps => [...ps, note]);
        setNewNoteContent("");
    }
    const handleChange = (event: any) => {
        setNewNoteContent(event.target.value);
    };

    return (
        <div className="min-h-0 flex-1 overflow-y-auto">

            {!loaded && <ul className="space-y-2 py-4 sm:space-y-4">
                <li>loading notes...</li>
            </ul>}
            {loaded &&
                <ul className="space-y-2 py-4 sm:space-y-4">
                    {notes.map((item) => (
                        <li key={item.id} className="bg-white px-4 py-6 shadow sm:rounded-lg sm:px-6">
                            <div className="sm:flex sm:items-baseline sm:justify-between">
                                <h3 className="text-base font-medium">
                                    <DateTimeUtcText date time year fromNow datetime={item.timestamp}/>
                                </h3>
                                {/*<p className="mt-1 whitespace-nowrap text-sm text-gray-600 sm:ml-3 sm:mt-0">*/}
                                {/*</p>*/}
                            </div>
                            <div className="mt-4 space-y-6 text-sm text-gray-800"
                                 dangerouslySetInnerHTML={{__html: item.content}}/>
                        </li>
                    ))}
                </ul>
            }
            <div className="bg-white pb-6">
                <div className="px-4 sm:flex sm:items-baseline sm:justify-between sm:px-6 lg:px-8">
                    <div className="sm:w-0 sm:flex-1 mt-3">
                        <div className="flex items-start space-x-4 w-full">
                            <div className="min-w-0 flex-1">
                                <form onSubmit={handleSubmit} action="#" className="relative">
                                    <div
                                        className="overflow-hidden rounded-lg shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-indigo-600">
                                        <label htmlFor="comment" className="sr-only">
                                            Add your notes
                                        </label>
                                        <textarea
                                            rows={3}
                                            name="comment"
                                            id="comment"
                                            className="block w-full resize-none border-0 bg-transparent py-1.5 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                            placeholder="Add your notes..."
                                            value={newNoteContent}
                                            onChange={handleChange}
                                        />

                                        {/* Spacer element to match the height of the toolbar */}
                                        <div className="py-2" aria-hidden="true">
                                            {/* Matches height of button in toolbar (1px border + 36px content height) */}
                                            <div className="py-px">
                                                <div className="h-9"/>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="absolute inset-x-0 bottom-0 flex justify-between py-2 pl-3 pr-2">
                                        <div className="flex items-center space-x-5">
                                            <div className="flex items-center">
                                            </div>
                                            <div className="flex items-center">
                                            </div>
                                        </div>
                                        <div className="flex-shrink-0">
                                            <CassieAwareSubmitButton isSaving={isSaving}/>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}