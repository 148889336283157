import React from "react";
import {Outlet, useParams} from "react-router-dom";
import {isMediumScreen, isSmallScreen} from "../../../helpers/breakpoint";
import {HealingsList} from "./HealingsList";

export default function HealingsSectionIndex() {
    const {id} = useParams();
    const tiny = isSmallScreen() || isMediumScreen();
    const showDirectory = (tiny && !id);
    
    return (
        <div className="relative z-0 flex flex-1 overflow-hidden">
            {showDirectory &&
                <aside className="w-full flex-shrink-0 border-r border-gray-200 order-first flex flex-col">
                    <HealingsList/>
                </aside>
            }

            {!tiny &&
                <aside className="w-96 flex-shrink-0 border-r border-gray-200 xl:order-first xl:flex xl:flex-col">
                    <HealingsList/>
                </aside>}

            <main className="relative z-0 flex-1 overflow-y-auto focus:outline-none xl:order-last">
                <Outlet/>
            </main>
        </div>
    )
}