import React, {useEffect, useState} from "react";
import {Dialog, DialogActions, DialogBody, DialogDescription, DialogTitle} from "../../tailwindui/dialog";
import {DocumentDescriptor} from "../../../../providers/core/documents/DocumentProvider";
import {useGuideFlowProviderContext} from "../../../../GuideFlowProviderContext";
import {CassiePayloadResult} from "../../../../providers/core/cassie/CassieTypes";
import SpinnerIcon from "../../icons/SpinnerIcon";
import {Image} from "./images/Image";

interface DocumentViewerModalProps {
    isOpen: boolean,
    documentDescriptor: DocumentDescriptor;
    onClose: (value: boolean) => void,
}

export default function DocumentViewerModal(props: DocumentViewerModalProps) {
    
    const context = useGuideFlowProviderContext();
    const [loaded, setLoaded] = useState(false);
    const [payload, setPayload] = useState<CassiePayloadResult>();

    useEffect(() => {
        setPayload(null);
        if (props.documentDescriptor) {
            context.Documents.getPayload(props.documentDescriptor.id).then(payload => {
                setPayload(payload);
                setLoaded(true);
            });
        }
    }, [props, context.Documents, context.Documents.StateChanged]);

    const handleDialogClose = () => {
        props.onClose(false);
    }

    if (!props.documentDescriptor) {
        return (<Dialog
            size="5xl"
            onClose={handleDialogClose}
            initialFocus={undefined}
            open={props.isOpen}>
            <DialogTitle>Missing Document</DialogTitle>
            <DialogDescription>No document is selected</DialogDescription>
        </Dialog>);
    }

    if (!loaded) {
        return (<Dialog
            size="5xl"
            onClose={handleDialogClose}
            initialFocus={undefined}
            open={props.isOpen}>
            <DialogTitle>Loading</DialogTitle>
            <DialogBody>
                <SpinnerIcon/> Loading...
            </DialogBody>
        </Dialog>);
    }


    return (<Dialog
        size="5xl"
        onClose={handleDialogClose}
        initialFocus={undefined}
        open={props.isOpen}>
        <DialogTitle>{props.documentDescriptor.title}</DialogTitle>
        {/*<DialogDescription>Desc</DialogDescription>*/}
        <DialogBody className="text-sm/6 text-zinc-900 dark:text-white">
            {!payload && <div>No payload found</div>}
            {payload && <Image payload={payload} className="cover-full"/>}
        </DialogBody>
        <DialogActions>
            {/*<Button plain onClick={() => setIsOpen(false)}>*/}
            {/*    Cancel*/}
            {/*</Button>*/}
            {/*<Button onClick={() => setIsOpen(false)}>I agree</Button>*/}
        </DialogActions>
    </Dialog>);
}