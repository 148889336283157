import React from "react";

interface CallUponsProps {

}

const messages = [
    {
        id: 1,
        subject: 'Captured',
        sender: 'Buttercup Anderson',
        time: '1d ago',
        datetime: '2021-01-27T16:35',
        preview: 'Trapped by Prince Humperdink',
    },
    {
        id: 2,
        subject: 'Seeking Justice',
        sender: 'Inigo Montoya',
        time: '6d ago',
        datetime: '2021-01-27T16:35',
        preview: "Stuck in a loop saying 'ello, you killed my father...'",
    },
]

export function PrayersAndCallUpons(props: CallUponsProps) {
    return (
        <ul className="divide-y divide-gray-200">
            {messages.map((message) => (
                <li
                    key={message.id}
                    className="relative bg-white px-4 py-5 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 hover:bg-gray-50">
                    <div className="flex justify-between space-x-3">
                        <div className="m-w-0 flex-1">
                            <a href="/" className="block focus:outline-none">
                                <p className="truncate text-sm font-medium text-gray-900">{message.sender}</p>
                                <p className="truncate text-sm text-gray-500">{message.subject}</p>
                            </a>
                        </div>
                        <time dateTime={message.datetime} className="flex-shrink-0 whitespace-nowrap text-sm text-gray-500">
                            {message.time}
                        </time>
                    </div>
                    <div className="mt-1">
                        <p className="line-clamp-2 text-sm text-gray-600">{message.preview}</p>
                    </div>
                </li>
            ))}
        </ul>
    );
}