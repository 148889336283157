import {DotYouClient} from "@youfoundation/js-lib/core";

export class ConfigProvider {

    private readonly client: DotYouClient;
    public StateChanged: number = 0;

    constructor(dotYouClient: DotYouClient) {
        this.client = dotYouClient;
    }

    private static instance: ConfigProvider

    static getInstance(dotYouClient: DotYouClient): ConfigProvider {
        if (ConfigProvider.instance == null) {
            ConfigProvider.instance = new ConfigProvider(dotYouClient);
        }

        return ConfigProvider.instance;
    }
    
}