import {MouseEventHandler} from "react";

export default function EmptyScreen(props: { travolta?: boolean, text: string, onClick?: MouseEventHandler | undefined; }) {
    return (
        <button
            type="button"
            onClick={props.onClick}
            className="relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">

            {props.travolta &&
                <img className="w-32" src="/images/travolta-confused.gif" alt={"john travolta confused"}/>
            }

            <span className="mEmptyScreenActiont-2 block text-sm font-semibold text-gray-900">{props.text}</span>
        </button>
    )
}