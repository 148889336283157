import {Button as HeadlessButton, type ButtonProps as HeadlessButtonProps} from '@headlessui/react'
import clsx from 'clsx'
import React from 'react'
import {TouchTarget} from './button'
import {Link} from './link'
import {PencilIcon} from "@heroicons/react/20/solid";

type AvatarProps = {
    src?: string | null
    square?: boolean
    initials?: string
    alt?: string
    className?: string
}

export function Avatar({
                           onClick = null,
                           src = null,
                           square = false,
                           initials,
                           alt = '',
                           className,
                           ...props
                       }: AvatarProps & React.ComponentPropsWithoutRef<'span'>) {
    return (
        <span
            data-slot="avatar"
            className={clsx(
                className,

                // Basic layout
                'relative inline-grid align-middle *:col-start-1 *:row-start-1',

                // Add the correct border radius
                square ? 'rounded-[20%] *:rounded-[20%]' : 'rounded-full *:rounded-full'
            )}
            {...props}
        >
      {initials && (
          <svg
              className="select-none fill-current text-[39px] font-medium uppercase"
              viewBox="0 0 100 100"
              aria-hidden={alt ? undefined : 'true'}>
              {alt && <title>{alt}</title>}
              <text x="50%" y="50%" alignmentBaseline="middle" dominantBaseline="middle" textAnchor="middle" dy=".125em">
                  {initials}
              </text>
          </svg>
      )}
            {src && <img src={src} alt={alt}/>}
            {/* Add an inset border that sits on top of the image */}
            <span className="ring-1 ring-inset ring-black/5 dark:ring-white/5 forced-colors:outline" aria-hidden="true"/>
            {onClick && <button onClick={onClick}
                                className="absolute bottom-0 right-0 p-2 justify-center bg-white text-white text-sm rounded shadow-sm ring-1 font-semibold ring-inset ring-gray-300 hover:bg-gray-50">
                <PencilIcon className="-ml-0.5 h-4 w-4 text-gray-400" aria-hidden="true"/>
            </button>}
    </span>
    )
}

export const AvatarButton = React.forwardRef(function AvatarButton(
    {
        src,
        square = false,
        initials,
        alt,
        className,
        ...props
    }: AvatarProps & (HeadlessButtonProps | React.ComponentPropsWithoutRef<typeof Link>),
    ref: React.ForwardedRef<HTMLElement>
) {
    let classes = clsx(
        className,
        square ? 'rounded-lg' : 'rounded-full',
        'relative focus:outline-none data-[focus]:outline data-[focus]:outline-2 data-[focus]:outline-offset-2 data-[focus]:outline-blue-500'
    )

    return 'href' in props ? (
        <Link {...props} className={classes} ref={ref as React.ForwardedRef<HTMLAnchorElement>}>
            <TouchTarget>
                <Avatar src={src} square={square} initials={initials} alt={alt}/>
            </TouchTarget>
        </Link>
    ) : (
        <HeadlessButton {...props} className={classes} ref={ref}>
            <TouchTarget>
                <Avatar src={src} square={square} initials={initials} alt={alt}/>
            </TouchTarget>
        </HeadlessButton>
    )
})
