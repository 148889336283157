import {HomeIcon, UserGroupIcon, XMarkIcon} from "@heroicons/react/24/outline";
import {Fragment} from "react";
import {Dialog, Transition} from "@headlessui/react";
import {UsersIcon} from "@heroicons/react/16/solid";
import {Link} from "react-router-dom";

const navigation = [
    {name: 'Home', href: '/home', icon: HomeIcon},
    {name: 'Contacts', href: '/contacts', icon: UsersIcon},
    // {name: 'Schedule', href: '/schedule', icon: CalendarIcon},
    {name: 'Healings', href: '/healings', icon: UserGroupIcon},
    {name: 'Classes', href: '/classes', icon: UserGroupIcon},
]

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ');
}

interface SidebarProps {
    mobileMenuOpen?: boolean
    onMobileMenuClose: (value: boolean) => void
}

export default function Sidebar(props: SidebarProps) {

    const isOpen = props.mobileMenuOpen;

    const handleSidebarClose = (value: boolean) => {
        props.onMobileMenuClose(value);
    }

    const isCurrent = (href: string) => {
        const path = window.location.pathname;
        //TODO: need to determine if this ithe root
        return path.startsWith(href);
    }
    return (
        <>
            {/* Narrow sidebar */}
            <div className="hidden w-28 overflow-y-auto bg-indigo-700 md:block">
                <div className="flex w-full flex-col items-center py-6">
                    <div className="flex flex-shrink-0 items-center">
                        <img
                            className="h-8 w-auto"
                            src="/icons/android-icon-144x144.png"
                            alt="Guideflow"
                        />
                    </div>
                    <div className="mt-6 w-full flex-1 space-y-1 px-2">
                        {navigation.map((item) => {
                            const current = isCurrent(item.href);
                            return (
                                <Link
                                    onClick={() => handleSidebarClose(false)}
                                    to={item.href}
                                    key={item.name}
                                    className={classNames(
                                        current ? 'bg-indigo-800 text-white' : 'text-indigo-100 hover:bg-indigo-800 hover:text-white',
                                        'group flex w-full flex-col items-center rounded-md p-3 text-xs font-medium'
                                    )}
                                    aria-current={current ? 'page' : undefined}>
                                    <item.icon
                                        className={classNames(
                                            current ? 'text-white' : 'text-indigo-300 group-hover:text-white',
                                            'h-6 w-6'
                                        )}
                                        aria-hidden="true"/>
                                    <span className="mt-2">{item.name}</span>
                                </Link>
                            )
                        })}
                    </div>
                </div>
            </div>

            {/* Mobile menu */}
            <Transition.Root show={isOpen} as={Fragment}>
                <Dialog as="div" className="relative z-40 md:hidden" onClose={handleSidebarClose}>
                    <Transition.Child
                        as={Fragment}
                        enter="transition-opacity ease-linear duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity ease-linear duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-600 bg-opacity-75"/>
                    </Transition.Child>

                    <div className="fixed inset-0 z-40 flex">
                        <Transition.Child
                            as={Fragment}
                            enter="transition ease-in-out duration-300 transform"
                            enterFrom="-translate-x-full"
                            enterTo="translate-x-0"
                            leave="transition ease-in-out duration-300 transform"
                            leaveFrom="translate-x-0"
                            leaveTo="-translate-x-full"
                        >
                            <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-indigo-700 pb-4 pt-5">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-in-out duration-300"
                                    enterFrom="opacity-0"
                                    enterTo="opacity-100"
                                    leave="ease-in-out duration-300"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                >
                                    <div className="absolute right-0 top-1 -mr-14 p-1">
                                        <button
                                            type="button"
                                            className="flex h-12 w-12 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-white"
                                            onClick={() => handleSidebarClose(false)}>
                                            <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true"/>
                                            <span className="sr-only">Close sidebar</span>
                                        </button>
                                    </div>
                                </Transition.Child>
                                <div className="flex flex-shrink-0 items-center px-4">
                                    <img
                                        className="h-8 w-auto"
                                        src="/icons/android-icon-144x144.png"
                                        alt="Guideflow"
                                    />
                                </div>
                                <div className="mt-5 h-0 flex-1 overflow-y-auto px-2">
                                    <nav className="flex h-full flex-col">
                                        <div className="space-y-1">
                                            {navigation.map((item) => {
                                                const current = isCurrent(item.href);
                                                return (
                                                    <Link
                                                        onClick={() => handleSidebarClose(false)}
                                                        key={item.name}
                                                        to={item.href}
                                                        className={classNames(
                                                            current
                                                                ? 'bg-indigo-800 text-white'
                                                                : 'text-indigo-100 hover:bg-indigo-800 hover:text-white',
                                                            'group flex items-center rounded-md py-2 px-3 text-sm font-medium'
                                                        )}
                                                        aria-current={current ? 'page' : undefined}>
                                                        <item.icon
                                                            className={classNames(
                                                                current ? 'text-white' : 'text-indigo-300 group-hover:text-white',
                                                                'mr-3 h-6 w-6'
                                                            )}
                                                            aria-hidden="true"
                                                        />
                                                        <span>{item.name}</span>
                                                    </Link>
                                                )
                                            })}
                                        </div>
                                    </nav>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                        <div className="w-14 flex-shrink-0" aria-hidden="true">
                            {/* Dummy element to force sidebar to shrink to fit close icon */}
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </>
    )
}
