import {observer} from "mobx-react-lite";
import {Link} from "react-router-dom";

interface SettingsLink {
    title: string
    description: string
    to: string
}

export const SettingsList = observer(() => {

    const settings: SettingsLink[] = [
        // {
        //     title: "General",
        //     description: "General settings (currency, date formats, etc.)",
        //     to: "general"
        // },
        {
            title: "Locations",
            description: "Locations from which you work",
            to: "locations"
        },
        // {
        //     title: "Packages",
        //     description: "How you bundle your healings and classes into packages",
        //     to: "packages"
        // },
        {
            title: "Data",
            description: "Manage your data (export/re-run initialization, etc.)",
            to: "data"
        }
        
    ]

    return (
        <>
            <div className="px-6 pb-4 pt-6 border-b">
                <div className="flex flex-row space-x-3">
                    <h2 className="text-lg font-medium text-gray-900">Settings</h2>
                </div>
            </div>

            <nav className="min-h-0 flex-1 overflow-y-auto" aria-label="Directory">
                <ul className="relative z-0 divide-y divide-gray-200">
                    {settings.map((definition) => (
                        <Link key={definition.to}
                              to={`${definition.to}`}
                              className="hover:bg-blue-50 hover:bg-opacity-50 flex border-b p-6">

                            {/*<img className="h-10 w-10 rounded-full" src={definition} alt=""/>*/}
                            <div className="text-sm text-wrap">
                                <p className="mb-1 text-sm font-medium text-gray-900">{definition.title}</p>
                                <p className="text-sm text-gray-500">{definition.description}</p>
                            </div>

                        </Link>
                    ))}
                </ul>
            </nav>
        </>
    )
});