import {DotYouClient} from "@youfoundation/js-lib/core";
import axios, {AxiosRequestConfig} from "axios";
import {getAxiosClient} from "@youfoundation/js-lib/dist/core/DriveData/File/DriveFileHelper";

export class CalDotComProvider {

    private readonly client: DotYouClient;
    public StateChanged: number = 0;

    constructor(dotYouClient: DotYouClient) {
        this.client = dotYouClient;
    }

    private static instance: CalDotComProvider

    static getInstance(dotYouClient: DotYouClient): CalDotComProvider {
        if (CalDotComProvider.instance == null) {
            CalDotComProvider.instance = new CalDotComProvider(dotYouClient);
        }

        return CalDotComProvider.instance;
    }

    public CalDotComApiKey: string = "cal_live_1f0ea816466b01b0fdf4a5e2a4370834";

    public async getEvent(slug: string) {
        try {
            const url = `/events?eventTypeSlug=${slug}`;
            const response = await axios.get(url, this.getAxiosConfig());
            return response.data;
        } catch (error) {
            console.error('Error fetching events:', error.response?.data || error.message);
        }
    }

    private getAxiosConfig(): AxiosRequestConfig {
        return {
            baseURL: 'https://api.cal.com/v1',
            headers: {
                'Authorization': `Bearer ${this.CalDotComApiKey}`,
                'Content-Type': 'application/json'
            }
        }
    }

}