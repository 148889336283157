import {DotYouClient} from "@youfoundation/js-lib/core";
import {getNewId, jsonStringify64} from "@youfoundation/js-lib/helpers";
import {FileQueryParamsWithoutTargetDrive, SimplifiedStorageProvider} from "../storage/SimplifiedStorageProvider";
import {HomebaseStorage, Image64Data, SaveFileConfig, SaveOptions} from "../storage/HomebaseStorageTypes";
import {GuideFlowConfig} from "../../../system/GuideFlowConfig";
import {EmbeddedThumb, ImageSize, ThumbnailFile} from "@youfoundation/js-lib/dist/core/core";
import {ThumbnailInstruction} from "@youfoundation/js-lib/dist/media/MediaTypes";
import {CassiePayloadResult, CassieQueryOptions, CassieSavePayloadData} from "../cassie/CassieTypes";
import {makeAutoObservable, runInAction} from "mobx";
import {createThumbnails} from "@youfoundation/js-lib/media";

type DocumentSaveFileConfig = Omit<SaveFileConfig, 'dataType'>;

const documentConfig: DocumentSaveFileConfig = {
    // fileType: 1122,
    fileType: 1188,
    targetDrive: GuideFlowConfig.DataDrive
}

const tabletThumbnailSize: ThumbnailInstruction = {
    quality: 90,
    width: 100,
    height: 100
}

const phoneThumbnailSize: ThumbnailInstruction = {
    quality: 90,
    width: 50,
    height: 50
}

const thumbnailSizes: ThumbnailInstruction[] = [
    phoneThumbnailSize,
    tabletThumbnailSize
]

export type ThumbnailSize = "Tablet" | "Mobile";

export const DocumentPayloadKeys = {
    // primaryImage: "document",
    // multiDocumentPayloadPrefix: "d",
    singlePayloadDocumentKey: "sdocument"
}

export interface DocumentDescriptor extends HomebaseStorage {
    id: string;
    ownerId: string;
    title: string;
    date: number;
    dataType: number; //maps to homebasestorage.dataType
}

export interface DocumentPayloadDescriptor {
    order: number;
    mimeType: string;
    width: number;
    height: number;
}

export interface DocumentPayload extends DocumentPayloadDescriptor {
    // thumbnails: thumbnail
    // base64Data: string;
    data: Blob
}

export interface DocumentQueryOptions {
    dataType?: number,
    cassieQueryOptions?: CassieQueryOptions
}

// Documents are files with a payload describing the document
// they each have their own uniqueId and the entity id (contact, 
// healing definition, etc...) that owns the document is set as the groupId in 
// the homebase system
//  - This allows us to get all documents by ownerId

export class DocumentProvider {
    private readonly provider: SimplifiedStorageProvider<DocumentDescriptor>;

    private static instance: DocumentProvider;
    public StateChanged: number = 0;

    static getInstance(dotYouClient: DotYouClient): DocumentProvider {
        if (DocumentProvider.instance == null) {
            DocumentProvider.instance = new DocumentProvider(dotYouClient);
        }

        return DocumentProvider.instance;
    }

    constructor(dotYouClient: DotYouClient) {
        this.provider = new SimplifiedStorageProvider<DocumentDescriptor>({dataType: undefined, ...documentConfig}, dotYouClient);
        makeAutoObservable(this);
        this.synchronize = this.synchronize.bind(this);
    }

    public async getAll(ownerId: string, options?: DocumentQueryOptions): Promise<DocumentDescriptor[]> {
        return await this.provider.getAll({groupId: ownerId, dataType: options?.dataType, cassieQueryOptions: options?.cassieQueryOptions});
    }

    public async exportGraph(context?: string) {
        return await this.provider.exportGraph(context);
    }

    public async getPayload(documentId: string): Promise<CassiePayloadResult> {
        return await this.provider.getPayload(documentId, DocumentPayloadKeys.singlePayloadDocumentKey);
    }

    public async save(document: DocumentDescriptor, content: DocumentPayload) {

        if (!document?.ownerId) {
            throw Error("OwnerId is required");
        }

        if (!document.dataType) {
            throw Error("dataType is required");
        }

        if (!document.id) {
            document.id = getNewId();
        }

        const p = await this.documentPayloadToCassie(content);

        const options: SaveOptions =
            {
                groupId: document.ownerId,
                userDate: document.date,
                overrideDataType: document.dataType,
                previewThumbnail: p?.tinyThumb ?? undefined,
                onVersionConflict: () => {
                    //TODO: how to handle this?
                }
            };

        await this.provider.saveHeaderFile(document, p.payload, options);
        // await this.addPayload(document.id, content, thumbnails.additionalThumbnails);
        this.notifyStateChange();
    }

    public async delete(documentId: string): Promise<boolean> {

        if (await this.provider.hardDeleteFile(documentId)) {
            this.notifyStateChange();
            return true;
        }

        return false;
    };

    public async getDocumentDescriptor(documentId: string): Promise<DocumentDescriptor | null> {
        return await this.provider.getByUniqueId(documentId);
    }

    public async getThumbnail(documentId: string, payloadKey: string = DocumentPayloadKeys.singlePayloadDocumentKey, thumbnailSize: ThumbnailSize = "Tablet"): Promise<Image64Data> {
        const {width, height} = thumbnailSize === "Tablet" ? tabletThumbnailSize : phoneThumbnailSize;
        return await this.provider.getThumbnailByUniqueId(documentId, payloadKey, width, height);
    }


    public async syncDataType(dataType: number) {

        const params: FileQueryParamsWithoutTargetDrive = {
            dataType: [dataType]
        };

        return await this.provider.syncByQuery(params, 0);
    }

    ///

    private async addPayload(documentId: string, content: DocumentPayload, additionalThumbnails: ThumbnailFile[]) {

        const descriptor = await this.getDocumentDescriptorOrFail(documentId);

        // thumbnails.naturalSize
        const o: DocumentPayloadDescriptor = {
            ...content
        }

        const payload = {
            key: DocumentPayloadKeys.singlePayloadDocumentKey,
            contentType: content.mimeType,
            descriptorContent: jsonStringify64(o),
            thumbnails: additionalThumbnails,
            content: content.data
        };

        await this.provider.savePayload(documentId, descriptor.storage.versionTag, payload);
    }

    private async documentPayloadToCassie(content: DocumentPayload): Promise<{
        tinyThumb: EmbeddedThumb,
        payload: CassieSavePayloadData
    }> {
        let thumbnails: {
            naturalSize: ImageSize;
            tinyThumb: EmbeddedThumb;
            additionalThumbnails: ThumbnailFile[];
        };

        // console.log('Add payload data type', content.data.type);
        if (content.data.type.startsWith("image/")) {
            thumbnails = await createThumbnails(content.data, DocumentPayloadKeys.singlePayloadDocumentKey, thumbnailSizes);
            console.log('thumbs', thumbnails);
        }

        const o: DocumentPayloadDescriptor = {
            ...content
        }

        const payload: CassieSavePayloadData = {
            key: DocumentPayloadKeys.singlePayloadDocumentKey,
            contentType: content.mimeType,
            descriptorContent: jsonStringify64(o),
            thumbnails: thumbnails?.additionalThumbnails,
            content: content.data
        };

        return {
            tinyThumb: thumbnails?.tinyThumb,
            payload: payload
        }
    }

    private async deletePayload(documentId: string, key: string) {

        const document = await this.getDocumentDescriptorOrFail(documentId);
        const payload = document.storage.payloads.find(p => p.key.toLowerCase() === key.toLowerCase());

        if (payload) {
            await this.provider.deletePayload(documentId, key);
            this.notifyStateChange();
        }
    }

    private async getDocumentDescriptorOrFail(documentId: string) {
        if (!documentId) {
            throw Error("Missing documentId");
        }

        const document = await this.getDocumentDescriptor(documentId);
        if (!document) {
            throw new Error("No document exists for the specified documentId");
        }

        return document;
    }

    public async synchronize(asOfTime: number) {
        const count = await this.provider.syncFileType(asOfTime);
        if (count > 0) {
            this.notifyStateChange();
        }
    }

    private notifyStateChange() {
        runInAction(() => {
            this.StateChanged++;
            // console.log('happening now', this.StateChanged);
        });
    }

}