import React, {useEffect, useState} from "react";
import {observer} from "mobx-react-lite";
import {useGuideFlowProviderContext} from "../../../../GuideFlowProviderContext";
import {Contact} from "../../../../providers/contact/ContactProvider";
import {LoadingPanel} from "../../vidence/blanks/LoadingPanel";
import {ContactProfileImage} from "../../../Sections/Contact/ContactProfileImage";
import {ContactStatusText} from "./ContactStatusText";

interface ContactProfileHeaderProps {
    id: string,
    className?: string,
    onClick?: () => void
}

export const ContactProfileHeader = observer((props: ContactProfileHeaderProps) => {
    const context = useGuideFlowProviderContext();
    const [contact, setContact] = useState<Contact>();
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        context.Contacts.get(props.id).then((contact) => {
            setContact(contact);
            setLoaded(true);
        });
    }, [context.Contacts, context.Contacts.StateChanged, props.id]);

    if (!loaded) {
        return <LoadingPanel text="..."/>
    }

    if (!contact) {
        return <div>info missing</div>
    }

    const initials = `${contact?.firstname[0]} ${contact?.surname[0]}`;

    return (
        <div className="flex flex-nowrap items-center space-x-2">
            <ContactProfileImage id={props.id} initials={initials} className="size-12 lg:size-12"/>
            <div className="min-w-0 flex-auto">
                <p className="text-sm font-semibold leading-6 text-gray-900">
                    {contact.firstname} {contact.surname}
                </p>
                <p className="mt-1 flex text-xs leading-5 text-gray-500">
                    <ContactStatusText id={contact.id}/>
                </p>
            </div>
        </div>
    )
})
