import {useEffect, useState} from "react";
import {useGuideFlowProviderContext} from "../../../../GuideFlowProviderContext";
import {ClassDefinition} from "../../../../providers/definitions/ClassDefinitionProvider";

interface Props {
    id: string
}

export default function ClassTitle(props: Props) {

    const [definition, setDefinition] = useState<ClassDefinition>()
    const context = useGuideFlowProviderContext();

    useEffect(() => {

        if (props.id) {
            context.ClassDefinitions.get(props.id).then(setDefinition);
        }
    }, [context.ClassDefinitions, context.ClassDefinitions.StateChanged, props.id]);

    return (
        <span>{definition?.title}</span>
    )
}