import {useEffect, useState} from "react";
import {useGuideFlowProviderContext} from "../../../../GuideFlowProviderContext";
import {LocationDefinition} from "../../../../providers/definitions/LocationDefinitionProvider";

interface LocationTitleProps {
    id: string
}

export default function LocationTitle(props: LocationTitleProps) {

    const [definition, setDefinition] = useState<LocationDefinition>()
    const context = useGuideFlowProviderContext();
    
    useEffect(() => {
        if(props.id) {
            context.Locations.get(props.id).then(setDefinition);
        }
    }, [context.Locations, context.Locations.StateChanged, props.id]);

    return (
        <span>{definition?.title}</span>
    )
}