import React, { useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {useFriendlyText} from "../../../../../hooks/friendlyText/useFriendlyText";
import {LoadingPanel} from "../../../../controls/vidence/blanks/LoadingPanel";
import {naah} from "../../../../viewUtils";
import moment from "moment";

interface NurturingInfo {
    nextCheckinUtc: number
}

export default function ContactNurturingInfo() {

    const {id} = useParams();
    const [info, setInfo] = useState<NurturingInfo>();
    const [loaded, setLoaded] = useState(false);
    const {fromNow} = useFriendlyText();

    useEffect(() => {
        setLoaded(true);

        const n: NurturingInfo = {
            nextCheckinUtc: moment().add(53, "days").unix()
        };

        setInfo(n);

    }, [id]);

    if (!loaded) {
        return (<LoadingPanel text="loading nurtuing data history..."/>);
    }

    if (loaded) {
        return (
            <div className="w-full overflow-hidden">
                <div className="mt-0">
                    <div className="flex-shrink-0 border-b border-gray-200 bg-white">
                        <div className="flex h-16 flex-col justify-center">
                            <div className="flex justify-between">
                                <div className="text-si text-sm text-gray-500">
                                    Next Checkin: {naah(info.nextCheckinUtc, `${fromNow(info?.nextCheckinUtc)}`)}
                                </div>
                                {/*<DropMenu>*/}
                                {/*    <DropMenuItem title="Edit" to="edit"/>*/}
                                {/*</DropMenu>*/}
                            </div>
                        </div>
                    </div>
                    <dl className="grid grid-cols-1 sm:grid-cols-2">
                        <div className="border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
                            <dt className="text-sm font-medium leading-6 text-gray-900">Eligible Classes</dt>
                            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">TODO: list of classes not already taken</dd>
                        </div>
                        <div className="border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
                            <dt className="text-sm font-medium leading-6 text-gray-900">Healings</dt>
                            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">TODO: list of healings not yet received</dd>
                        </div>
                    </dl>
                </div>
            </div>
            // <div>
            //     <div>
            //         <div className="flex h-16 flex-col justify-center">
            //             <div className="flex justify-between py-3">
            //                 {/* Left buttons */}
            //                 <div className="isolate inline-flex rounded-md shadow-sm sm:space-x-3 sm:shadow-none">
            //                       <span className="inline-flex sm:shadow-sm">
            //                         <button
            //                             type="button"
            //                             onClick={() => handleAddEntry("healing")}
            //                             className="relative inline-flex rounded-l-md items-center gap-x-1.5 bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:z-10 hover:bg-gray-50 focus:z-10">
            //                           <PlusCircleIcon className="-ml-0.5 h-5 w-5 text-gray-400" aria-hidden="true"/> Healing
            //                         </button>
            //                           <button
            //                               type="button"
            //                               onClick={() => handleAddEntry("class")}
            //                               className="relative inline-flex items-center gap-x-1.5 bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:z-10 hover:bg-gray-50 focus:z-10">
            //                           <PlusCircleIcon className="-ml-0.5 h-5 w-5 text-gray-400" aria-hidden="true"/> Class
            //                         </button>
            //                      
            //                           <button
            //                               type="button"
            //                               onClick={() => handleAddEntry("initiation")}
            //                               className="relative inline-flex -ml-px rounded-r-md items-center gap-x-1.5 bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:z-10 hover:bg-gray-50 focus:z-10">
            //                               <PlusCircleIcon className="-ml-0.5 h-5 w-5 text-gray-400" aria-hidden="true"/> Initiation
            //                           </button>
            //                       </span>
            //                 </div>
            //             </div>
            //         </div>
            //     </div>

            // {classEntries?.length > 0 &&
            //     <ul className="divide-y divide-gray-100">
            //         {classEntries.map((entry) => (
            //             <li key={entry.id} className="flex items-center justify-between gap-x-3 py-5">
            //                 <div className="min-w-16">
            //                     {/*<Thumbnail document={entry} className="h-16 w-16 border border-gray-300 bg-white text-gray-300"/>*/}
            //                 </div>
            //                 <div className="flex flex-row w-full justify-between gap-x-1">
            //                     <div className="min-w-0 max-w-80">
            //                         <div className="flex items-start gap-x-3">
            //                             <p className="truncate text-sm font-semibold leading-6 text-gray-900">
            //                                 <EventTitle event={entry}/>
            //                             </p>
            //                         </div>
            //                         <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
            //                             <p className="whitespace-nowrap">
            //                                 {entry.eventDateUtcSeconds && text.asShortDateWithYear(entry.eventDateUtcSeconds)}
            //                             </p>
            //                         </div>
            //                     </div>
            //                     <div className="flex flex-none items-center gap-x-4">
            //                         <button
            //                             onClick={() => {
            //                                 handleEditEntry(entry);
            //                             }}
            //                             className="hidden rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block">
            //                             Edit <span className="sr-only"></span>
            //                         </button>
            //                         <Menu as="div" className="sm:hidden relative flex-none">
            //                             <Menu.Button className="-m-2.5 block p-2.5 text-gray-500 hover:text-gray-900">
            //                                 <span className="sr-only">Open options</span>
            //                                 <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true"/>
            //                             </Menu.Button>
            //                             <Transition
            //                                 as={Fragment}
            //                                 enter="transition ease-out duration-100"
            //                                 enterFrom="transform opacity-0 scale-95"
            //                                 enterTo="transform opacity-100 scale-100"
            //                                 leave="transition ease-in duration-75"
            //                                 leaveFrom="transform opacity-100 scale-100"
            //                                 leaveTo="transform opacity-0 scale-95">
            //                                 <Menu.Items
            //                                     className="absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
            //                                     <Menu.Item>
            //                                         {({focus}) => (
            //                                             <button
            //                                                 onClick={() => {
            //                                                     handleEditEntry(entry);
            //                                                 }}
            //                                                 className={classNames(focus ? 'bg-gray-50' : '', 'w-full block px-3 py-1 text-sm leading-6 text-gray-900')}>Edit</button>
            //                                         )}
            //                                     </Menu.Item>
            //                                 </Menu.Items>
            //                             </Transition>
            //                         </Menu>
            //                     </div>
            //                 </div>
            //             </li>
            //         ))}
            //     </ul>
            // }
        )
    }
}