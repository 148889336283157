import {useEffect, useState} from "react";
import {useGuideFlowProviderContext} from "../../../../GuideFlowProviderContext";
import {Link} from "react-router-dom";
import {HealingDefinition} from "../../../../providers/definitions/HealingDefinitionProvider";

interface HealingListSmallProps {
    idList: string[]
}

export default function HealingTitleList(props: HealingListSmallProps) {

    const [list, setList] = useState<HealingDefinition[]>()
    const context = useGuideFlowProviderContext();

    useEffect(() => {
        context.HealingDefinitions.getList(props.idList ?? []).then(setList);
    }, [context.HealingDefinitions, context.HealingDefinitions.StateChanged, props.idList]);

    return (
        <ul>{list?.map(d =>
            <li key={d.id} title={d.description}>
                <Link to={`/healings/${d.id}`}>
                    {d.title}
                </Link>
            </li>
        )}</ul>
    )
}