import {getDataDrive} from "./GuideFlowConfig";
import {DrivePermissionType} from '@youfoundation/js-lib/core';
import {AppPermissionType} from "@youfoundation/js-lib/network";

interface AppDefinition {
    id: string
    name: string
    moniker: string
    permissionKeys: number[],
    circlePermissionKeys: number[],
    drives: AppDrivePermissionRequest[],
    circleDrives: any[],
    host: string
}

interface AppDrivePermissionRequest {
    a: string,
    t: string,
    n: string,
    d: string,
    p: number
}

const dataDrive = getDataDrive();

const drives: AppDrivePermissionRequest[] = [{
    a: dataDrive.drive.alias,
    t: dataDrive.drive.type,
    n: dataDrive.name,
    d: dataDrive.description,
    p: DrivePermissionType.Read + DrivePermissionType.Write
}];

const permissionKeys = [
    AppPermissionType.SendPushNotifications,
    AppPermissionType.ReadConnections
];

const devAppDefinition: AppDefinition = {
    id: '97755777-2233-4117-a03b-583c8c4c5112',
    name: 'Guideflow Dev R22.3',
    moniker: 'guideflow',
    permissionKeys: permissionKeys,
    circlePermissionKeys: [],
    drives: drives,
    circleDrives: [],
    host: "dev.vidence.co:3443"
}

const demoAppDefinition: AppDefinition = {
    id: '44444711-316b-888c-4444-583c8c481919',
    name: 'Guideflow Demo R22.2',
    moniker: 'guideflow',
    permissionKeys: permissionKeys,
    circlePermissionKeys: [],
    drives: drives,
    circleDrives: [],
    host: "guideflow-demo.vidence.co"
}

const productionAppDefinition: AppDefinition = {
    id: '999ce711-316b-411c-a03b-583c8c4c5999',
    name: 'Guideflow',
    moniker: 'guideflow',
    permissionKeys: permissionKeys,
    circlePermissionKeys: [],
    drives: drives,
    circleDrives: [],
    host: 'guideflow.in'
}

export const getAppDefinition = (): AppDefinition => {
    const envName = process.env.REACT_APP_ENVIRONMENT;

    switch (envName.toLowerCase()) {
        case "production":
            return productionAppDefinition;

        case "demo":
            return demoAppDefinition;
            
        default:
            return devAppDefinition;
    }
}