import {DotYouClient} from "@youfoundation/js-lib/core";
import {getNewId, jsonStringify64, tryJsonParse} from "@youfoundation/js-lib/helpers";
import {makeObservable, observable, runInAction} from "mobx";
import moment from "moment";
import {SimplifiedStorageProvider} from "../core/storage/SimplifiedStorageProvider";
import {HomebaseStorage, Image64Data, ImageHeader, SaveFileConfig, SaveOptions} from "../core/storage/HomebaseStorageTypes";
import {CassiePayloadResult, CassieSavePayloadData} from "../core/cassie/CassieTypes";
import {normalizeGuid} from "../DataHelpers";
import {DineroSnapshot} from "dinero.js";

const PayloadKeys = {
    DefinitionImage: "defimage",
    CoverImage: "coverimage"
}

export interface DefinitionStorage extends HomebaseStorage {
    id: string,
    title: string,
    durationMinutes?: number,
    exchange?: DineroSnapshot<any>,
    prerequisiteHealings?: string[],
    prerequisiteClasses?: string[],
    prerequisiteInitiations?: string[]
}

export class DefinitionStorageProvider<TDefinition extends DefinitionStorage> {
    private readonly provider: SimplifiedStorageProvider<TDefinition>;
    private readonly builtInDefinitions: DefinitionStorage[];
    public StateChanged: number = 0;

    constructor(config: SaveFileConfig, dotYouClient: DotYouClient, builtInDefinitions: DefinitionStorage[]) {
        this.provider = new SimplifiedStorageProvider<TDefinition>(config, dotYouClient);
        this.builtInDefinitions = builtInDefinitions ?? [];
        makeObservable(this, {
            StateChanged: observable,
        });
    }

    public async get(id: string): Promise<TDefinition | null> {
        return await this.provider.getByUniqueId(id);
    }

    public async getAll(): Promise<TDefinition[]> {
        return await this.provider.getAll<TDefinition>();
    }

    public async exportGraph(context?: string) {
        return await this.provider.exportGraph(context);
    }

    public async getAllGrouped(): Promise<Record<string, TDefinition[]>> {
        const grouping: Record<string, TDefinition[]> = {};
        const definitions = await this.getAll();

        const sorted = definitions.sort((a, b) => a.title.localeCompare(b.title));

        sorted.forEach(definition => {
            const firstLetter = definition.title.charAt(0).toUpperCase();
            if (!grouping[firstLetter]) {
                grouping[firstLetter] = [];
            }
            grouping[firstLetter].push(definition);
        });

        return grouping;
    }

    public async getList(list: string[]): Promise<TDefinition[]> {
        return await this.provider.getList<TDefinition>(list);
    }

    public async saveProfileImage(definitionId: string, versionTag: string, image: Image64Data) {
        const header: ImageHeader = {
            width: image.width,
            height: image.height,
            mimeType: image.mimeType
        }

        const payload: CassieSavePayloadData = {
            key: PayloadKeys.DefinitionImage,
            contentType: image.mimeType,
            descriptorContent: jsonStringify64(header),
            thumbnails: [],
            content: image.base64Data
        };

        await this.provider.savePayload(definitionId, versionTag, payload);
        this.notifyStateChange();
    }

    public async getProfileImage(definitionId: string): Promise<Image64Data | null> {
        return await this.provider.getImagePayload(definitionId, PayloadKeys.DefinitionImage);
    }

    public async saveCoverImage(definitionId: string, versionTag: string, image: Image64Data | null) {

        const header: ImageHeader = {
            width: image?.width ?? 0,
            height: image?.height ?? 0,
            mimeType: image?.mimeType ?? ""
        }

        const payload: CassieSavePayloadData = {
            key: PayloadKeys.CoverImage,
            contentType: image?.mimeType ?? "",
            descriptorContent: jsonStringify64(header),
            thumbnails: [],
            content: image?.base64Data ?? ""
        };

        await this.provider.savePayload(definitionId, versionTag, payload);

        this.notifyStateChange();
    }

    public async getCoverImage(definitionId: string): Promise<Image64Data | null> {

        const data: CassiePayloadResult | null = await this.provider.getPayload(definitionId, PayloadKeys.CoverImage);

        if (!data) {
            //console.debug("No image found: ", definitionId)
            return null;
        }

        const header: ImageHeader = tryJsonParse<ImageHeader>(data.descriptorContent || "");

        return {
            base64Data: data.content64,
            width: header.width,
            height: header.height,
            mimeType: header.mimeType
        }
    }

    public async save(definition: TDefinition): Promise<string> {
        console.log('saving', definition);

        const options: SaveOptions =
            {
                groupId: undefined,
                userDate: undefined,
                onVersionConflict: () => {
                    //TODO: how to handle this?
                }
            };

        if (!definition.id) {
            definition.id = getNewId();
        }

        if (!definition.title) {
            throw Error("A title is required on the definition");
        }

        const result = await this.provider.saveHeaderFile(definition, undefined, options);

        if (result) {
            this.notifyStateChange();
        }

        return "";
    }

    public async savePayload(definitionId: string, versionTag: string, payload: CassieSavePayloadData) {
        await this.provider.savePayload(definitionId, versionTag, payload);
        this.notifyStateChange();
    }

    public async getPayload(definitionId: string, key: string): Promise<CassiePayloadResult> {
        return await this.provider.getPayload(definitionId, key);
    }

    public async delete(id: string): Promise<boolean> {
        return this.provider.setArchived(id, true).then(success => {
            if (success) {
                this.notifyStateChange();
            }
            return success;
        });
    };

    public async undelete(id: string): Promise<boolean> {
        return this.provider.setArchived(id, false).then(success => {
            if (success) {
                this.notifyStateChange();
            }
            return success;
        });
    };

    public async syncFileType(): Promise<number> {
        const count = await this.provider.syncFileType(moment.utc().unix());
        if (count > 0) {
            this.notifyStateChange();
        }
        return count;
    }

    public async synchronize(asOfTime: number) {
        const count = await this.provider.syncFileType(asOfTime);
        if (count > 0) {
            this.notifyStateChange();
        }
    }

    public async initialize(version: number, onStatusMessage: (message: string) => void) {
        if (version === 1) {
            await Promise.all(this.builtInDefinitions.map(definition => this.upsert(definition, onStatusMessage)));
        }
    }

    private async upsert(definition: DefinitionStorage, onStatusMessage: (message: string) => void): Promise<any> {
        const existingDefinition = await this.provider.getByUniqueId(definition.id);
        if (existingDefinition) {
            onStatusMessage(`${definition.title} already exists; no changes made 😎`);
        } else {
            try {
                await this.save(definition as TDefinition);
                onStatusMessage(`${definition.title} added 👍`);
            } catch (e) {
                onStatusMessage(`failed to add ${definition.title} 😵 [${e.message}]`);
            }
        }
    }

    private notifyStateChange() {
        // console.log('notifying');
        runInAction(() => {
            this.StateChanged++;
        });
    }

    protected isBuiltinDefinition = (id: string): boolean => {
        return this.builtInDefinitions.find(h => normalizeGuid(h.id) === normalizeGuid(id)) != null;
    }
}