import {DotYouClient} from "@youfoundation/js-lib/core";
import {DefinitionStorage, DefinitionStorageProvider} from "./DefinitionStorageProvider";
import {builtInHealings} from "./builtin/BuiltInHealings";
import {SaveFileConfig} from "../core/storage/HomebaseStorageTypes";
import {GuideFlowConfig} from "../../system/GuideFlowConfig";


const fileConfig: SaveFileConfig = {
    dataType: 400,
    fileType: 777,
    targetDrive: GuideFlowConfig.DataDrive
}

export const healingDocumentDataType = 555;

export interface HealingDefinition extends DefinitionStorage {
    title: string;
    description: string;
}

type TDefinition = HealingDefinition;

export class HealingDefinitionProvider extends DefinitionStorageProvider<TDefinition> {
    private static instance: HealingDefinitionProvider

    static getInstance(dotYouClient: DotYouClient): HealingDefinitionProvider {
        if (HealingDefinitionProvider.instance == null) {
            HealingDefinitionProvider.instance = new HealingDefinitionProvider(fileConfig, dotYouClient, builtInHealings);
        }

        return HealingDefinitionProvider.instance;
    }

}