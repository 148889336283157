import React, {createContext, useContext, useEffect, useMemo} from "react";
import useAuth from "./hooks/auth/useAuth";
import {Toaster} from "react-hot-toast";
import {ContactProvider} from "./providers/contact/ContactProvider";
import {ClassDefinitionProvider} from "./providers/definitions/ClassDefinitionProvider";
import {HealingDefinitionProvider} from "./providers/definitions/HealingDefinitionProvider";
import {SyncProvider} from "./providers/SyncProvider";
import {DocumentProvider} from "./providers/core/documents/DocumentProvider";
import {InitiationDefinitionProvider} from "./providers/definitions/InitiationDefinitionProvider";
import {LocationDefinitionProvider} from "./providers/definitions/LocationDefinitionProvider";
import {RecommendationProvider} from "./providers/recomendations/RecommendationProvider";
import {ConfigProvider} from "./providers/config/ConfigProvider";
import {CalDotComProvider} from "./providers/scheduling/CalDotComProvider";

export interface GFContext {
    CalDotComProvider: CalDotComProvider,
    Configuration: ConfigProvider,
    Contacts: ContactProvider,
    ClassDefinitions: ClassDefinitionProvider,
    HealingDefinitions: HealingDefinitionProvider,
    // ActivationDefinitions: ActivationDefinitionProvider,
    InitiationDefinitions: InitiationDefinitionProvider,
    Locations: LocationDefinitionProvider,
    Documents: DocumentProvider,
    Recommendations: RecommendationProvider
    // Bookings: BookingProvider
    // PackageDefinitions: PackageDefinitionProvider
}

export const GuideFlowProviderContext = createContext<GFContext>({} as GFContext);  //gluten free context? ;)

// @ts-ignore
export function GuideFlowProvider({children}) {
    const {getDotYouClient} = useAuth();
    const client = getDotYouClient();

    const ctx: GFContext = useMemo(() => {
        return {
            CalDotComProvider: CalDotComProvider.getInstance(client),
            Configuration: ConfigProvider.getInstance(client),
            Contacts: ContactProvider.getInstance(client),
            ClassDefinitions: ClassDefinitionProvider.getInstance(client),
            HealingDefinitions: HealingDefinitionProvider.getInstance(client),
            // ActivationDefinitions: ActivationDefinitionProvider.getInstance(client),
            InitiationDefinitions: InitiationDefinitionProvider.getInstance(client),
            Locations: LocationDefinitionProvider.getInstance(client),
            Documents: DocumentProvider.getInstance(client),
            Recommendations: RecommendationProvider.getInstance(client)
            // Bookings: BookingProvider.getInstance(client)
            // PackageDefinitions: PackageDefinitionProvider.getInstance(client)
        }
    }, [client]);

    useEffect(() => {
        SyncProvider.getInstance(ctx).startSyncTimer();
    }, [ctx]);

    return (
        <GuideFlowProviderContext.Provider value={ctx}>
            <Toaster position="top-center" reverseOrder={false}/>
            {children}
        </GuideFlowProviderContext.Provider>
    );
}

export function useGuideFlowProviderContext() {
    return useContext<GFContext>(GuideFlowProviderContext)
}