import {INotification, Mediator} from "mediatr-ts";
import {ContactProgressionEvent} from "../contact/ContactProgressionEventProvider";

const mediator = new Mediator();
export default mediator;

type ProgressionEventChangeType = "added" | "updated" | "deleted"

export class ProgressionEventChanged implements INotification {
    constructor(public id: string, public changeType: ProgressionEventChangeType, public progressionEvent?: ContactProgressionEvent) {
    }
}