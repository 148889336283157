import {HomebaseFile, ExternalFileIdentifier, FileQueryParams, TargetDrive} from "@youfoundation/js-lib/core";
import {IndexedHeaderRecord, IndexedPayloadRecord, IndexedThumbnailRecord} from "./LocalStorageTypes";
import {DeletedHomebaseFile} from "@youfoundation/js-lib/dist/core/DriveData/File/DriveFileTypes";
import DataStorage from "./DataStorage";
import {normalizeGuid} from "../../../DataHelpers";
import {ThumbnailFile} from "@youfoundation/js-lib/dist/core/core";
import {uint8ArrayToBase64} from "@youfoundation/js-lib/helpers";

// const serialize = (o: any): string | undefined => {
//     try {
//         return o ? jsonStringify64(o) : undefined
//     } catch (ex) {
//         console.error("failed serialize", o);
//         throw ex;
//     }
// }
//
// const deserialize = (data: string | undefined): any => {
//     try {
//         return data ? JSON.parse(data) : undefined;
//     } catch (ex) {
//         console.error("failed JSON Parse:", data);
//         throw ex;
//     }
// }

export interface UpsertPayloadRequest {
    fileId: string,
    targetDrive: TargetDrive,
    payloadKey: string,
    contentType: string,
    descriptor: string;
    payloadData: string,
    // thumbnails: UpsertThumbnailRequest[]
    thumbnails: ThumbnailFile[]
}

// export interface UpsertThumbnailRequest extends ThumbnailDescriptor {
//     data: string
// }

export class IndexDBProvider {

    private static instance: IndexDBProvider;

    static getInstance() {
        if (IndexDBProvider.instance == null) {
            IndexDBProvider.instance = new IndexDBProvider();
        }

        return IndexDBProvider.instance;
    }

    private storage: DataStorage;

    constructor() {
        this.storage = new DataStorage();
    }

    public async UpsertHeaders(headers: (HomebaseFile | DeletedHomebaseFile)[], targetDrive: TargetDrive) {
        const db = await this.storage.getDb(targetDrive);
        await db.transaction('readwrite', db.files, () => {
            for (let i = 0; i < headers.length; i++) {
                const dsr = headers[i]

                const metadata = dsr.fileMetadata;
                const record: IndexedHeaderRecord = {
                    fileId: normalizeGuid(dsr.fileId),
                    targetDriveAlias: normalizeGuid(targetDrive.alias),
                    targetDriveType: normalizeGuid(targetDrive.type),
                    versionTag: dsr.fileMetadata.versionTag,
                    created: metadata.created,
                    updated: metadata.updated,
                    globalTransitId: metadata.globalTransitId,
                    senderOdinId: metadata.senderOdinId,
                    fileType: metadata.appData.fileType,
                    dataType: metadata.appData.dataType,
                    groupId: metadata.appData.groupId,
                    userDate: metadata.appData.userDate,
                    uniqueId: metadata.appData.uniqueId,
                    tags: metadata.appData.tags,
                    archivalStatus: metadata.appData.archivalStatus,
                    priority: dsr.priority,
                    dsr: dsr as HomebaseFile
                }

                db.files.put(record);
            }
        });
    }

    public async UpsertPayload(request: UpsertPayloadRequest) {

        const thumbs: IndexedThumbnailRecord[] = [];

        for (let t = 0; t < request.thumbnails.length; t++) {

            const thumbnail = request.thumbnails[t];
            const record: IndexedThumbnailRecord = {
                fileId: normalizeGuid(request.fileId),
                payloadKey: request.payloadKey,
                thumbnailKey: thumbnail.key,
                width: thumbnail.pixelWidth,
                height: thumbnail.pixelHeight,
                contentType: thumbnail.payload.type,
                data: uint8ArrayToBase64(new Uint8Array(await thumbnail.payload.arrayBuffer()))
            };

            thumbs.push(record);
        }

        const payload: IndexedPayloadRecord = {
            fileId: normalizeGuid(request.fileId),
            targetDriveAlias: normalizeGuid(request.targetDrive.alias),
            targetDriveType: normalizeGuid(request.targetDrive.type),
            payloadKey: request.payloadKey,
            contentType: request.contentType,
            descriptor: request.descriptor,
            data: request.payloadData,
            // thumbnails: thumbs
        }

        let db = await DataStorage.getInstance().getDb(request.targetDrive);
        await db.transaction('rw', [db.payloads, db.thumbnails], () => {
            db.payloads.put(payload);
            thumbs.forEach(t => db.thumbnails.put(t));
        });
    }

    public async GetPayload(file: ExternalFileIdentifier, key: string): Promise<IndexedPayloadRecord | null> {
        const db = await DataStorage.getInstance().getDb(file.targetDrive);
        return db.payloads.get({
            fileId: normalizeGuid(file.fileId),
            payloadKey: key
        });

    }

    public async GetByUniqueId(uniqueId: string, targetDrive: TargetDrive): Promise<HomebaseFile | null> {
        const db = await DataStorage.getInstance().getDb(targetDrive);
        const record = await db.files.get({
            uniqueId: normalizeGuid(uniqueId),
        });

        return record?.dsr;
    }

    public async GetThumbnailByUniqueId(uniqueId: string, targetDrive: TargetDrive, payloadKey: string, width: number, height: number): Promise<IndexedThumbnailRecord> {
        const db = await DataStorage.getInstance().getDb(targetDrive);

        const file = await this.GetByUniqueId(uniqueId, targetDrive);
        
        if(!file)
        {
            return null;
        }
        
        //TODO: maybe consider getting next highest match?
        return db.thumbnails.get({
            fileId:normalizeGuid(file.fileId),
            payloadKey: payloadKey,
            width: width,
            height: height,
        });
    }

    public async GetByFileId(file: ExternalFileIdentifier): Promise<HomebaseFile | null> {
        const db = await DataStorage.getInstance().getDb(file.targetDrive);
        const record = await db.files.get({
            fileId: normalizeGuid(file.fileId)
        });

        return record?.dsr;
    }

    public async QueryUniqueIdByList(idList: string[], targetDrive: TargetDrive): Promise<HomebaseFile[]> {
        const db = await DataStorage.getInstance().getDb(targetDrive);
        const normalizedIdList = idList.map(id => normalizeGuid(id));
        const results = await db.files.toCollection().filter(p => normalizedIdList.includes(normalizeGuid(p.uniqueId))).toArray();
        return results.map(r => r.dsr);
    }

    public async Query(params: FileQueryParams): Promise<HomebaseFile[]> {
        const db = await DataStorage.getInstance().getDb(params.targetDrive);
        let collection = db.files.toCollection();

        if (params.fileType) {
            collection = collection.filter(file => params.fileType.includes(file.fileType));
        }

        if (params.dataType) {
            collection = collection.filter(file => params.dataType.includes(file.dataType));
        }

        // if (params.fileState) {
        //     collection = collection.filter(file => params.fileState.includes(file.fileState));
        // }

        if (params.sender) {
            collection = collection.filter(file => params.sender.includes(file.senderOdinId));
        }

        if (params.groupId) {
            collection = collection.filter(file => params.groupId.some(id => normalizeGuid(file.groupId) === normalizeGuid(id)));
        }

        if (params.userDate) {
            // Assuming userDate is an object with 'from' and 'to' properties
            collection = collection.filter(file => file.userDate >= params.userDate.start &&
                file.userDate <= params.userDate.end);
        }

        if (params.tagsMatchAtLeastOne) {
            collection = collection.filter(file => params.tagsMatchAtLeastOne.some(tag => file.tags.map(t => normalizeGuid(t)).includes(normalizeGuid(tag))));
        }

        if (params.tagsMatchAll) {
            collection = collection.filter(file => params.tagsMatchAll.every(tag => file.tags.map(t => normalizeGuid(t)).includes(normalizeGuid(tag))));
        }

        if (params.globalTransitId) {
            collection = collection.filter(file => params.globalTransitId.some(id => normalizeGuid(file.globalTransitId) === normalizeGuid(id)));
        }

        if (params.clientUniqueIdAtLeastOne) {
            collection = collection.filter(file => params.clientUniqueIdAtLeastOne.some(id => normalizeGuid(file.uniqueId) === normalizeGuid(id)));
        }

        if (params.archivalStatus) {
            collection = collection.filter(file => params.archivalStatus.includes(file.archivalStatus));
        }

        const results = await collection.toArray();
        // console.log('local results', results);
        return results.map(r => r.dsr);
    }

    public async DeletePayload(fileId: string, targetDrive: TargetDrive, payloadKey: string) {
        const db = await DataStorage.getInstance().getDb(targetDrive);
        db.payloads.delete([normalizeGuid(fileId), payloadKey]);
    }

    public async HardDeleteFile(fileId: string, targetDrive: TargetDrive) {

        const db = await DataStorage.getInstance().getDb(targetDrive);
        const queryResult = await db.files.where({
            fileId: normalizeGuid(fileId),
        }).toArray();

        if (!queryResult || queryResult.length === 0) {
            return null;
        }

        db.transaction('rw', [db.files, db.payloads], () => {
            db.files.delete(normalizeGuid(fileId));
            db.payloads.where({fileId: normalizeGuid(fileId)}).delete();
        });
    }

}