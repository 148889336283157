import React, {useState} from "react";
import {Dialog, DialogActions, DialogBody, DialogTitle} from "../tailwindui/dialog";
import {Button} from "../tailwindui/button";
import {Field, Label} from "../tailwindui/fieldset";
import {dateTextToUtcSeconds, utcSecondsToDateText} from "../../../helpers/Dates";
import {ClockIcon} from "@heroicons/react/16/solid";

interface DateSelectorProps {
    title: string;
    show: boolean;
    onClose: () => void;
    date: number;
    onSave: (dateTimeUtc: number) => void;
}

export default function DateTimeSelector(props: DateSelectorProps) {

    const [date, setDate] = useState<number>(props.date);

    const handleDateChange = (event: any) => {
        alert(event.target.value);
        if (event.target.value) {
            setDate(dateTextToUtcSeconds(event.target.value));
        }
    }

    const handleDialogClose = () => {
        props.onClose();
    }

    const handleDateSelected = () => {
        props.onSave(date);
    }

    const handleCancel = () => {
        props.onClose();
    }

    return (
        <Dialog
            size="sm"
            onClose={handleDialogClose}
            initialFocus={undefined}
            open={props.show}>

            <DialogTitle>
                <div className="flex flex-row gap-3 justify-center">
                    <ClockIcon className="h-6 w-6" aria-hidden="true"/>
                    <h3 className="text-base font-semibold leading-6 text-gray-900">
                        {props.title}
                    </h3>
                </div>
            </DialogTitle>
            {/*<DialogDescription>Please agree to the following terms and conditions to continue.</DialogDescription>*/}
            <DialogBody className="text-sm/6 text-zinc-900 dark:text-white h-full overflow-y-scroll">
                <div className="sm:col-span-2">
                    <Field>
                        <Label>Date</Label>
                    </Field>
                    <input aria-label="Date and time" value={utcSecondsToDateText(date)} type="datetime-local" onChange={handleDateChange}/>
                </div>
            </DialogBody>
            <DialogActions className="w-full">
                <div>
                    <Button
                        type="button"
                        disabled={false}
                        onClick={handleDateSelected}
                        className="mt-1 inline-flex w-full justify-center">
                        Ok
                    </Button>
                </div>
                <div>
                    <Button
                        type="button"
                        onClick={handleCancel}
                        className="mt-1 inline-flex w-full justify-center">
                        Cancel
                    </Button>
                </div>
            </DialogActions>
        </Dialog>
    );
}