import {DotYouClient} from "@youfoundation/js-lib/core";
import {DefinitionStorage, DefinitionStorageProvider} from "./DefinitionStorageProvider";
import {GuideFlowConfig} from "../../system/GuideFlowConfig";
import {SaveFileConfig} from "../core/storage/HomebaseStorageTypes";
import {builtInClasses} from "./builtin/BuiltInClasses";
import {allClasses} from "./sampleSchedule";
import {normalizeGuid} from "../DataHelpers";
import moment from "moment";


// deposit-paid, fully-paid : obvious
// tentative - you spoke with the student, they want to come but have not paid
// candidate - you or the app put the student as a candidate; you need to contact them
export type AttendeeStatus = "deposit-paid" | "fully-paid" | "tentative" | "candidate";

export interface ClassAttendee {
    contactId: string
    status: AttendeeStatus
}

export interface ScheduledClass {
    id: string,
    classDefinitionId: string,
    locationId: string,
    startDateTimeUtc: number,
    students: ClassAttendee[]
}

const fileConfig: SaveFileConfig = {
    dataType: 500,
    fileType: 702,
    targetDrive: GuideFlowConfig.DataDrive
}

export interface ClassDefinition extends DefinitionStorage {
    id: string;
    title: string;
    description: string;
    isMultiDay?: boolean;
}

type TDefinition = ClassDefinition;

export class ClassDefinitionProvider extends DefinitionStorageProvider<TDefinition> {
    private static instance: ClassDefinitionProvider;

    static getInstance(dotYouClient: DotYouClient): ClassDefinitionProvider {
        if (ClassDefinitionProvider.instance == null) {
            ClassDefinitionProvider.instance = new ClassDefinitionProvider(fileConfig, dotYouClient, builtInClasses);
        }

        return ClassDefinitionProvider.instance;
    }

    public async saveScheduledClass() {
        //remember to this.StateChanged++;
    }

    public async getAllScheduledClasses(): Promise<ScheduledClass[]> {

        return allClasses.filter(c => c.startDateTimeUtc >= moment.utc().startOf('day').valueOf() / 1000);
        // const data = await this.getPayload(definitionId, PayloadKeys.ClassPlanning);
    }

    public async getScheduledClasses(definitionId: string): Promise<ScheduledClass[]> {
        return allClasses.filter(c => normalizeGuid(c.classDefinitionId) === normalizeGuid(definitionId));
    }

    public async getScheduledClass(classScheduleId: string): Promise<ScheduledClass> {
        const items = allClasses.filter(c => normalizeGuid(c.id) === normalizeGuid(classScheduleId));
        if (items.length === 1) {
            return items[0];
        }
        return null;
    }

}