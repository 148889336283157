import {MagnifyingGlassIcon, ArrowPathRoundedSquareIcon, ClockIcon, PlusIcon} from "@heroicons/react/16/solid";
import {Link, useNavigate} from "react-router-dom";
import {useGuideFlowProviderContext} from "../../../GuideFlowProviderContext";
import React, {useEffect, useState} from "react";
import {observer} from "mobx-react-lite";
import {XMarkIcon} from "@heroicons/react/24/outline";
import {sampleContacts} from "./data";
import {ContactProfileImage} from "./ContactProfileImage";
import {Contact} from "../../../providers/contact/ContactProvider";
import {ContactStatusText} from "../../controls/renderers/Contacts/ContactStatusText";
import {classNames} from "../../viewUtils";

const ObservingContactList = observer(() => {

    const [searchText, setSearchText] = useState<string>("");
    const [directoryData, setDirectoryData] = useState<Record<string, Contact[]>>();
    const [filteredDirectory, setFilteredDirectory] = useState<Record<string, Contact[]>>();
    const [includeArchived, setIncludeArchived] = useState(false);
    const [isSyncing, setIsSyncing] = useState(false);
    const context = useGuideFlowProviderContext();
    const navigate = useNavigate();

    useEffect(() => {
        context.Contacts.getAllGrouped(includeArchived).then(contacts => {
            setDirectoryData(contacts);
        });

    }, [context.Contacts, context.Contacts.StateChanged, includeArchived]);

    const handleSearch = (text: string) => {
        if (!directoryData) {
            return;
        }

        if (!text) {
            setFilteredDirectory(directoryData);
        }

        const fd: Record<string, Contact[]> = {};
        Object.keys(directoryData).map((letter) => {
            const matchingRecords = directoryData[letter].filter((record) => {
                const regex = new RegExp(text, 'i');
                return record.firstname.match(regex) || record.surname.match(regex);
            });

            if (matchingRecords && matchingRecords.length > 0) {
                if (!fd[letter]) {
                    fd[letter] = [];
                }

                fd[letter] = matchingRecords;
            }

            return null;

        });

        setSearchText(text);
        setFilteredDirectory(fd);

        return null;
    }

    const handleClearSearch = () => {
        setSearchText("");
        setFilteredDirectory(null);
    }

    const generateData = async () => {
        console.log('creating sample contacts')
        for (let i = 0; i < sampleContacts.length; i++) {
            const c = sampleContacts[i];
            await context.Contacts.save(c);
            console.log('created record for ', c.firstname)
        }
    }

    const handleSyncRequest = async () => {
        setIsSyncing(true);
        context.Contacts.syncFileType().then(_ => {
            setIsSyncing(false);
            // console.log('synced file count', count);
        });
    }

    if (!directoryData) {
        return <>Loading..</>
    }

    const directory = searchText ? filteredDirectory : directoryData;
    return (
        <div className="h-full border-3 border-red-900">
            <div className="px-6 pb-4 pt-6">
                <div className="flex flex-row justify-between">
                    <div className="flex space-x-2">
                        <h2 className="text-lg font-medium text-gray-900">Contacts</h2>
                        <button
                            type="button"
                            disabled={isSyncing}
                            onClick={() => {
                                navigate("new");
                            }}
                            className="rounded-full bg-indigo-600 p-1 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                            <PlusIcon className="h-4 w-5" aria-hidden="true"/>
                        </button>
                        <button
                            type="button"
                            disabled={isSyncing}
                            onClick={handleSyncRequest}
                            className="rounded-full bg-indigo-600 p-1 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                            {isSyncing && <ClockIcon className="h-4 w-5" aria-hidden="true"/>}
                            {!isSyncing && <ArrowPathRoundedSquareIcon className="h-4 w-5" aria-hidden="true"/>}
                        </button>
                    </div>
                    <div className="spacing-2 align-middle">
                        <span className="text-gray-400 font-light text-sm mr-2">
                            Archived
                        </span>
                        <input
                            className="h-5 w-5 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                            type="checkbox"
                            checked={includeArchived}
                            onChange={_ => setIncludeArchived(!includeArchived)}
                        />
                    </div>
                    {/*{*/}
                    {/*    process.env.REACT_APP_ENVIRONMENT === "development" &&*/}
                    {/*    <button*/}
                    {/*        type="button"*/}
                    {/*        disabled={isSyncing}*/}
                    {/*        onClick={generateData}*/}
                    {/*        className="rounded-full bg-indigo-600 p-1 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">*/}
                    {/*        Sample data*/}
                    {/*    </button>*/}
                    {/*}*/}
                </div>
                <form className="mt-6 flex gap-x-4" action="#">
                    <div className="min-w-0 flex-1">
                        <label htmlFor="search" className="sr-only">
                            Search
                        </label>
                        <div className="relative rounded-md shadow-sm">
                            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" aria-hidden="true"/>
                            </div>
                            <input
                                type="search"
                                name="search"
                                id="search"
                                className="block w-full rounded-md border-0 py-1.5 pl-10 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-pink-500 sm:text-sm sm:leading-6"
                                placeholder="Search"
                                onChange={(e) => handleSearch(e.target.value)}
                                value={searchText}
                            />
                        </div>
                    </div>
                    <button
                        type="button"
                        onClick={handleClearSearch}
                        className="inline-flex justify-center rounded-md bg-white px-3 py-2 text-gray-400 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                        <XMarkIcon className="h-5 w-5" aria-hidden="true"/>
                        <span className="sr-only">Clear</span>
                    </button>
                </form>
            </div>

            {/* Directory list */}
            <nav className="min-h-0 h-full flex-1 overflow-y-auto" aria-label="Directory">
                {Object.keys(directory).map((letter) => (
                    <div key={letter} className="relative">
                        <div
                            className="sticky top-0 z-10 border-b border-t border-gray-200 bg-gray-50 px-6 py-1 text-sm font-medium text-gray-500">
                            <h3>{letter}</h3>
                        </div>
                        <ul className="relative z-0 divide-y divide-gray-200">
                            {directory[letter].map((contact) => {
                                const initials = `${contact?.firstname[0]} ${contact?.surname[0]}`;
                                return (
                                    <li key={contact.id}>
                                        <Link to={`${contact.id}/overview`}>
                                            <div
                                                className={classNames(contact.storage.isArchived ? "bg-gray-50" : "",
                                                    "relative flex items-center space-x-3 px-6 py-5 focus-within:ring-2 focus-within:ring-inset focus-within:ring-pink-500 hover:bg-gray-50")}>
                                                <div className="flex-shrink-0">
                                                    <ContactProfileImage id={contact.id} initials={initials} className="size-12 lg:size-16"/>
                                                </div>
                                                <div className="min-w-0 flex-1">
                                                    <div className="focus:outline-none">
                                                        <span className="absolute inset-0" aria-hidden="true"/>
                                                        <p className="text-sm font-medium text-gray-900">{contact.firstname} {contact.surname}</p>
                                                        {contact.storage.isArchived && <p className="text-xs text-gray-500"> Archived</p>}
                                                        <p className="mt-1 truncate text-sm text-gray-500">{contact?.id &&
                                                            <ContactStatusText id={contact.id}/>}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    </li>);
                            })}
                        </ul>
                    </div>
                ))}
                <div className="h-32"/>
            </nav>
        </div>
    )
});

export const ContactList = () => {

    return (
        <ObservingContactList/>
    );
};
