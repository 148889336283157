import {useEffect, useRef, useState} from 'react';
import {Navigate, useSearchParams} from 'react-router-dom';
import {useYouAuthAuthorization} from "../hooks/auth/useAuth";
import {Helmet} from "react-helmet-async";

const FinalizeLogin = () => {
    const isRunning = useRef(false);

    const [searchParams] = useSearchParams();
    const {finalizeAuthorization} = useYouAuthAuthorization();
    const [finalizeState, setFinalizeState] = useState<undefined | 'success' | 'error'>();

    const identity = searchParams.get('identity');
    const public_key = searchParams.get('public_key');
    const salt = searchParams.get('salt');
    const returnUrl = searchParams.get('state');

    useEffect(() => {
        (async () => {
            if (!identity || !public_key || !salt) return;
            if (isRunning.current) return;

            isRunning.current = true;
            const authState = await finalizeAuthorization(identity, public_key, salt);
            setFinalizeState(authState ? 'success' : 'error');
        })();
    }, [finalizeAuthorization, identity, public_key, salt]);

    // console.log('im here o', finalizeState)
    if (!identity || !public_key || !salt) return <Navigate to={'/auth'}/>;
    if (finalizeState === 'success') return <Navigate to={returnUrl || '/'}/>;
    if (finalizeState === 'error') return <Navigate to={'/auth?state=finalize-error'}/>;

    return (
        <>
            <Helmet>
                <title>Login | Guideflow</title>
            </Helmet>
            <div className="cursor-wait ">
                <div className="pointer-events-none ">
                    <div>Finalizing Login</div>
                </div>
            </div>
        </>
    );
};

export default FinalizeLogin;
