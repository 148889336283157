import {useFriendlyText} from "../../../../hooks/friendlyText/useFriendlyText";

interface Props {
    datetime: number,
    date?: boolean,
    year?: boolean,
    time?: boolean,
    fromNow?: boolean,
}

export default function DateTimeUtcText(props: Props) {

    const text = useFriendlyText();
    const timestamp = props.datetime;

    let fromNow = "";

    if (props.fromNow) {
        fromNow = `${text.fromNow(timestamp)}`;
        if (props.date || props.time) {
            fromNow = ` (${fromNow})`;
        }
    }

    return (
        <time dateTime={text.asShortDateYearWithTime(timestamp)}>
            {props.date && (props.year ? text.asShortDateWithYear(timestamp) : text.asShortDate(timestamp))}
            {props.date && props.time && `, `}
            {props.time && text.asTime(timestamp)}
            {props.time && props.fromNow && ` `}
            {fromNow}
        </time>
    )
}