function isGuidWithoutDashes(str: string | undefined) {

    if (!str) {
        return false
    }

    // Regular expression to match a GUID without dashes (32 alphanumeric characters)
    const guidPattern = /^[0-9a-fA-F]{32}$/;

    // Test if the string matches the GUID pattern
    return guidPattern.test(str);
}

function isGuid(str: string | undefined) {
    if (!str) {
        return false
    }

    // Regular expression to match a GUID in the "8-4-4-12" format
    // const guidPattern = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
    const guidPattern = new RegExp(/^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/);

    // Test if the string matches the GUID pattern
    return guidPattern.test(str);
}

export const isValidGuid = (maybeGuid: string | undefined) => isGuid(maybeGuid) || isGuidWithoutDashes(maybeGuid);

export const isValidGuidList = (maybeGuidList: string[] | undefined) => {
    for (const idx in maybeGuidList) {
        // @ts-ignore
        if (!isValidGuid(maybeGuidList[idx])) {
            return false;
        }
    }

    return true;
};

export const AssertIsValidGuid = (maybeGuid: string | undefined) => {
    if (!isValidGuid(maybeGuid)) {
        throw Error("The guid is invalid");
    }
}

export const AssertIsValidGuidList = (maybeGuidList: string[] | undefined) => {
    if (!isValidGuidList(maybeGuidList)) {
        throw Error("One or more GUIDs is invalid");
    }
}