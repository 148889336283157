import React, {useEffect, useState} from "react";
import {useGuideFlowProviderContext} from "../../../../GuideFlowProviderContext";
import {LoadingPanel} from "../../../controls/vidence/blanks/LoadingPanel";
import {classNames} from "../../../viewUtils";
import {FollowUpRecommendation} from "../../../../providers/recomendations/RecommendationProvider";
import {ContactProfileHeader} from "../../../controls/renderers/Contacts/ContactProfileHeader";
import {DropMenu, DropMenuItem} from "../../../controls/vidence/menus/dropmenu/Dropmenu";

interface ClientFollowUpsProps {

}

export function ClientFollowUps(props: ClientFollowUpsProps) {
    const context = useGuideFlowProviderContext();
    const [recommendations, setRecommendations] = useState<FollowUpRecommendation[]>();

    const [loaded, setLoaded] = useState(false);

    useEffect(() => {

        context.Recommendations.getContactsForFollowup().then(followupList => {
            setRecommendations(followupList);
            setLoaded(true);
        });

    }, [props, context.Recommendations.StateChanged, context.Recommendations]);

    if (!loaded) {
        return <LoadingPanel text="loading followups..."/>
    }

    return (
        <ul className="divide-y divide-gray-100">
            {recommendations?.map((reco) => {
                return (
                    <li key={reco.id} className="flex justify-between gap-x-6 py-5">
                        <div className="flex min-w-0 gap-x-4">
                            <ContactProfileHeader id={reco.contactId}/>
                        </div>
                        <div className="flex shrink-0 items-center gap-x-6">
                            <div className="hidden sm:flex sm:flex-col sm:items-end">
                                <p className={classNames('rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset'
                                )}>
                                    {reco.subject}
                                    {/*Last Contacted: {}*/}
                                </p>
                            </div>
                            <DropMenu>
                                <DropMenuItem title="View" to={`/contacts/${reco.contactId}`}/>
                                {/*<DropMenuItem title="Call" to={`/contacts/${reco.contactId}`}/>*/}
                            </DropMenu>
                        </div>
                    </li>
                )
            })}
        </ul>
    );

    // return (
    //     // <Link key={contact.id} to={`${contact.id}/overview`}>
    //     <div
    //         className="relative flex items-center space-x-3 px-6 py-5 focus-within:ring-2 focus-within:ring-inset focus-within:ring-pink-500 hover:bg-gray-50">
    //         <div className="flex-shrink-0">
    //             <ContactProfileImage id={contact.id} initials={initials} className="size-12 lg:size-16"/>
    //         </div>
    //         <div className="min-w-0 flex-1">
    //              <span
    //                  className="inline-flex flex-shrink-0 items-center rounded-full bg-green-50 px-1.5 py-0.5 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">Paid
    //              </span>
    //            
    //             <button className="focus:outline-none">
    //                 {/* Extend touch target to entire panel */}
    //                 <span className="absolute inset-0" aria-hidden="true"/>
    //                 <p className="text-sm font-medium text-gray-900">{contact.firstname} {contact.surname}</p>
    //                 <p className="truncate text-sm text-gray-500">{contact.status}</p>
    //             </button>
    //         </div>
    //     </div>
    //     // </Link>
    // );
}