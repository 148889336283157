import defaultTheme from "tailwindcss/defaultTheme";

type TailwindBreakpoint = 'sm' | 'md' | 'lg' | 'xl' | '2xl' | string;
export const getBreakpointValue = (value: string): number =>
    +defaultTheme.screens[value].slice(
        0,
        defaultTheme.screens[value].indexOf('px')
    );

export const getCurrentBreakpoint = (): TailwindBreakpoint | undefined => {
    let currentBreakpoint: TailwindBreakpoint | undefined;
    let biggestBreakpointValue = 0;
    for (const breakpoint of Object.keys(defaultTheme.screens)) {
        const breakpointValue = getBreakpointValue(breakpoint);

        if (breakpointValue > biggestBreakpointValue && window.innerWidth >= breakpointValue) {
            biggestBreakpointValue = breakpointValue;
            currentBreakpoint = breakpoint;
        }
    }
    return currentBreakpoint;
};

export const isSmallScreen = (): boolean => {
    const b = getCurrentBreakpoint();
    return b === undefined || b === "sm";
}

export const isMediumScreen = (): boolean => {
    const b = getCurrentBreakpoint();
    return b === undefined || b === "md";
}