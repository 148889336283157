import {EllipsisVerticalIcon} from "@heroicons/react/20/solid";
import React, {ReactElement} from "react";
import {Dropdown, DropdownButton, DropdownItem, DropdownMenu} from "../../../tailwindui/dropdown";

export function DropMenu(props: { imageElement?: ReactElement, menuButtonClassName?: string, children: any }) {

    const image = props.imageElement ?? <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true"/>;
    return (<Dropdown>
        <DropdownButton outline className="border-0">
            {image}
        </DropdownButton>
        <DropdownMenu>
            {props.children}
        </DropdownMenu>
    </Dropdown>)
}

export function DropMenuItem(props: { title: string, to?: string, onClick?: Function }) {

    const handleClick = () => {
        if(props.onClick) {
            props.onClick();
        }
    }


    return (
        <DropdownItem href={props.to} onClick={handleClick}>{props.title}</DropdownItem>
    )
}
