import React from "react";
import {useParams} from "react-router-dom";
import {contactDocumentDataType} from "../../../../providers/contact/ContactProvider";
import {DocumentsList} from "../../../controls/vidence/documents/DocumentsList";

export default function ContactDocumentsTab() {
    const {id} = useParams();
    return (
        <div className="flex-1 flex-row flex-grow h-full">
            <DocumentsList ownerId={id} dataType={contactDocumentDataType}/>
        </div>);
}