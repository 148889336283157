import React, {useEffect, useState} from "react";
import {useGuideFlowProviderContext} from "../../../../../GuideFlowProviderContext";
import {ScheduledClass} from "../../../../../providers/definitions/ClassDefinitionProvider";
import {ClassPlanningPreview} from "./ClassPlanningPreview";


export function ClassPlanningHorizontalList() {

    const [scheduledClasses, setScheduledClasses] = useState<ScheduledClass[]>([]);

    const [loaded, setLoaded] = useState(false);
    const context = useGuideFlowProviderContext();

    useEffect(() => {
        context.ClassDefinitions.getAllScheduledClasses().then(classes => {
            setScheduledClasses(classes);
            setLoaded(true);
        })

    }, [context.ClassDefinitions, context.ClassDefinitions.StateChanged]);

    if (!loaded) {
        return <div>loading classes...</div>
    }

    return (
        <div className="flex flex-col">
            <div className="snap-x snap-mandatory flex w-full overflow-x-auto gap-x-3">
                {scheduledClasses.map(item =>
                    <div key={item.id} className="snap-center shrink-0 flex grow items-center justify-center">
                        <ClassPlanningPreview key={item.classDefinitionId} scheduledClass={item}/>
                    </div>
                )}
            </div>
        </div>
    )
}