import {ClassDefinition} from "../../../providers/definitions/ClassDefinitionProvider";

export const SECTION_ROOT = "/classes";

export type ClassDefinitionForm = Omit<ClassDefinition, 'exchange'> & {
    exchangeRaw: number;
};

export const emptyClassDefinition: ClassDefinitionForm = {
    id: "",
    title: "",
    description: "",
    durationMinutes: 0,
    exchangeRaw: 0,
    prerequisiteHealings: [],
    prerequisiteClasses: []
};