import {useEffect, useState} from "react";
import {useGuideFlowProviderContext} from "../../../../GuideFlowProviderContext";
import {Link} from "react-router-dom";
import {ClassDefinition} from "../../../../providers/definitions/ClassDefinitionProvider";

interface ClassTitleListProps {
    idList: string[]
}

export default function ClassTitleList(props: ClassTitleListProps) {

    const [list, setList] = useState<ClassDefinition[]>()
    const context = useGuideFlowProviderContext();

    useEffect(() => {
        context.ClassDefinitions.getList(props.idList ?? []).then(setList);
    }, [context.ClassDefinitions, context.ClassDefinitions.StateChanged, props.idList]);

    // console.log('titles', props.idList)
    return (
        <ul>{list?.map(d =>
            <li key={d.id} title={d.description}>
                <Link to={`/classes/${d.id}`}>
                    {d.title}
                </Link>
            </li>
        )}</ul>
    )
}