import moment from "moment";
import {dinero, DineroSnapshot, toDecimal} from "dinero.js";
import {generalConfig} from "../../system/config";
import {HomebaseStorageInfo} from "../../providers/core/storage/HomebaseStorageTypes";
import {t} from "../../helpers/i18n/dictionary";

export const SHORT_DATE_FORMAT = "MMM D";
export const SHORT_DATE_WITH_YEAR = SHORT_DATE_FORMAT + " YYYY";
export const SHORT_DATE_AND_TIME_FORMAT = SHORT_DATE_WITH_YEAR + " HH:MM";

function friendlyTime(minutes: number) {
    if (minutes < 60) {
        return `${minutes} minute${minutes === 1 ? '' : 's'}`;
    } else {
        const hours = Math.floor(minutes / 60);
        const remainingMinutes = minutes % 60;

        if (remainingMinutes === 0) {
            return `${hours} hour${hours === 1 ? '' : 's'}`;
        } else {
            return `${hours} hour${hours === 1 ? '' : 's'} and ${remainingMinutes} minute${remainingMinutes === 1 ? '' : 's'}`;
        }
    }
}

export const useFriendlyText = () => {
    return {
        asMinutes: (minutes: number) => {
            return friendlyTime(minutes);
        },

        asHours: (hours: number) => {
            return friendlyTime(hours * 60);
        },

        fromUnixTimestamp: (timestamp: number) => {
            const date = moment(timestamp);
            return date.fromNow();
        },

        asCurrency: (data: DineroSnapshot<any>, withCurrencyCode?: boolean): string => {
            return `${parseFloat(toDecimal(dinero(data)))} ${withCurrencyCode ? generalConfig.currency.code : ""}`;
        },

        asShortDate: (datetimeUnixUtc: number): string => {
            return moment.unix(datetimeUnixUtc).format(SHORT_DATE_FORMAT);
        },

        asShortDateYearWithTime: (datetimeUnixUtc: number): string => {
            return moment.unix(datetimeUnixUtc).format(SHORT_DATE_AND_TIME_FORMAT);
        },

        asShortDateWithYear: (datetimeUnixUtc: number) => {
            const date = moment.unix(datetimeUnixUtc);
            return date.format(SHORT_DATE_WITH_YEAR);
        },

        asTime: (datetimeUnixUtc: number) => {
            return moment.unix(datetimeUnixUtc).format("LT");
        },

        fromNow: (datetimeUnixUtc: number) => {
            return moment.unix(datetimeUnixUtc).fromNow();
        },

        bytesAsText: (bytes: number, si = false, dp = 1) => {
            const thresh = si ? 1000 : 1024;

            if (Math.abs(bytes) < thresh) {
                return bytes + ' b';
            }

            const units = si
                ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
                : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
            let u = -1;
            const r = 10 ** dp;

            do {
                bytes /= thresh;
                ++u;
            } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);


            return bytes.toFixed(dp) + ' ' + units[u];
        },

        homebaseCreatedLong(storage: HomebaseStorageInfo) {
            if (storage) {
                return `${this.asShortDateWithYear(storage.created / 1000)} (${this.fromNow(storage.created / 1000)})`;
            }

            return t("N/A");
        },

        // updatedLong(storage: HomebaseStorageInfo) {
        //     if (storage) {
        //         if (storage.updated) {
        //             return `${this.asShortDateWithYear(storage.updated/1000)} (${this.fromNow(storage.updated/1000)})`;
        //         }
        //        
        //         return this.createdLong(storage);
        //        
        //     }
        //
        //     return t("N/A");
        // }
    }
}