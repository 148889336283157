import React, {useEffect, useState} from "react";
import {PlusCircleIcon} from "@heroicons/react/16/solid";
import {useNavigate, useParams} from "react-router-dom";
import {ContactProgressionEvent, ContactProgressionEventType} from "../../../../../providers/contact/ContactProgressionEventProvider";
import {useGuideFlowProviderContext} from "../../../../../GuideFlowProviderContext";
import {useFriendlyText} from "../../../../../hooks/friendlyText/useFriendlyText";
import {LoadingPanel} from "../../../../controls/vidence/blanks/LoadingPanel";
import EmptyScreen from "../../../../controls/vidence/blanks/EmptyScreenAction";
import ClassTitle from "../../../../controls/renderers/Classes/ClassTitle";
import HealingTitle from "../../../../controls/renderers/Healings/HealingTitle";
import InitiationTitle from "../../../../controls/renderers/Initiation/InitiationTitle";
import {DropMenu, DropMenuItem} from "../../../../controls/vidence/menus/dropmenu/Dropmenu";
import ConfirmationDialog from "../../../../controls/vidence/modal/ConfirmationDialog";
import toast from "react-hot-toast";
import {HealingDefinitionThumbnail} from "../../../Healings/HealingDefinitionThumbnail";
import {ClassDefinitionThumbnail} from "../../../Classes/ClassDefinitionThumbnail";

export default function ContactProgressionList() {

    const {id} = useParams();
    const navigate = useNavigate();
    const [entries, setEntries] = useState<ContactProgressionEvent[]>();
    const [entryForDeletion, setEntryForDeletion] = useState<ContactProgressionEvent | null>(null);

    const [loaded, setLoaded] = useState(false);
    const context = useGuideFlowProviderContext();
    const text = useFriendlyText();

    useEffect(() => {
        setLoaded(true);
        context.Contacts.ProgressionEvents.getAll(id, {cassieQueryOptions: {forceServerCall: true}}).then((items) => {
            setLoaded(true);
            setEntries(items);
        });
    }, [id, context.Contacts.ProgressionEvents, context.Contacts.ProgressionEvents.StateChanged]);


    const handleAddEntry = async (type: ContactProgressionEventType) => {
        navigate(`${type}/new`);
    }

    function handleEditEntry(entry: ContactProgressionEvent) {
        navigate(`${entry.definitionType}/edit/${entry.id}`);
    }

    async function handleFinalizeDelete(answer: boolean) {

        setEntryForDeletion(null);
        if (answer) {
            const t = entryForDeletion.definitionType
            const success = await context.Contacts.ProgressionEvents.delete(entryForDeletion.id)
            if (success) {
                setEntries(p => p.filter(e => e.id !== entryForDeletion.id));
                toast.success(`Deleted ${t} event`, {icon: '😔'});
            }
        }
    }

    if (!loaded) {
        return (<LoadingPanel text="loading progression history..."/>);
    }

    function getThumbnailComponent(entry: ContactProgressionEvent) {

        switch (entry.definitionType) {
            case "class":
                return <ClassDefinitionThumbnail id={entry.definitionId} className="size-11 lg:size-11"/>

            case "healing":
                return <HealingDefinitionThumbnail id={entry.definitionId} className="size-11 lg:size-11"/>

            case "initiation":
            // return <InitiationDefinitionThumbnail id={entry.definitionId} className="size-12 lg:size-16"/>
        }
    }

    if (loaded) {
        return (
            <div>
                <ConfirmationDialog
                    isOpen={entryForDeletion !== null}
                    title="Are you sure?"
                    onAnswerReceived={handleFinalizeDelete}>
                    Are you sure you want to delete <b><EventTitle event={entryForDeletion}/></b>
                </ConfirmationDialog>

                <div>
                    <div className="flex h-16 flex-col justify-center">
                        <div className="flex justify-between py-3">
                            {/* Left buttons */}
                            <div className="isolate inline-flex rounded-md shadow-sm sm:space-x-3 sm:shadow-none">
                                  <span className="inline-flex sm:shadow-sm">
                                    <button
                                        type="button"
                                        onClick={() => handleAddEntry("healing")}
                                        className="relative inline-flex rounded-l-md items-center gap-x-1.5 bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:z-10 hover:bg-gray-50 focus:z-10">
                                      <PlusCircleIcon className="-ml-0.5 h-5 w-5 text-gray-400" aria-hidden="true"/> Healing
                                    </button>
                                      <button
                                          type="button"
                                          onClick={() => handleAddEntry("class")}
                                          className="relative inline-flex items-center gap-x-1.5 bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:z-10 hover:bg-gray-50 focus:z-10">
                                      <PlusCircleIcon className="-ml-0.5 h-5 w-5 text-gray-400" aria-hidden="true"/> Class
                                    </button>
                                  
                                      <button
                                          type="button"
                                          onClick={() => handleAddEntry("initiation")}
                                          className="relative inline-flex -ml-px rounded-r-md items-center gap-x-1.5 bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:z-10 hover:bg-gray-50 focus:z-10">
                                          <PlusCircleIcon className="-ml-0.5 h-5 w-5 text-gray-400" aria-hidden="true"/> Initiation
                                      </button>
                                  </span>
                            </div>
                        </div>
                    </div>
                </div>

                {loaded && entries?.length === 0 &&
                    <EmptyScreen text="Looks empty in here... add some progression history"/>}

                {loaded && entries?.length > 0 &&
                    <ul role="list" className="divide-y divide-gray-100">
                        {entries.map((entry) => (

                            <li key={entry.id} className="flex justify-between gap-x-6 py-5">
                                <div className="flex min-w-0 gap-x-4">
                                    {getThumbnailComponent(entry)}
                                    <div className="min-w-0 flex-auto">
                                        {/*<p className="text-sm font-semibold leading-6 text-gray-900">{person.name}</p>*/}
                                        {/*<p className="mt-1 truncate text-xs leading-5 text-gray-500">{person.email}</p>*/}
                                        <EventTitle event={entry}/>
                                        <p className="mt-1 truncate text-xs leading-5 text-gray-500">
                                            {entry.eventDateUtcSeconds && text.asShortDateWithYear(entry.eventDateUtcSeconds)}
                                        </p>
                                    </div>
                                </div>
                                <div className="shrink-0 sm:flex sm:flex-col sm:items-end">
                                    <DropMenu>
                                        <DropMenuItem title="Edit" onClick={() => {
                                            handleEditEntry(entry);
                                        }}/>
                                        <DropMenuItem title="Remove" onClick={() => {
                                            setEntryForDeletion(entry)
                                        }}/>
                                    </DropMenu>
                                </div>
                            </li>))}
                    </ul>
                }

                {/*{loaded && entries?.length > 0 &&*/}
                {/*    <div className="max-w-full border border-red-900 overflow-x-clip h-32">*/}
                {/*        <div className="border w-[5000px] bg-cyan-500 border-green-900 py-2 align-middle sm:px-6 lg:px-8">*/}

                {/*            /!*    <table className="min-w-full divide-y divide-gray-300">*!/*/}
                {/*            /!*        <thead>*!/*/}
                {/*            /!*        <tr>*!/*/}
                {/*            /!*            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">*!/*/}
                {/*            /!*                Name*!/*/}
                {/*            /!*            </th>*!/*/}
                {/*            /!*            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">*!/*/}
                {/*            /!*                Date*!/*/}
                {/*            /!*            </th>*!/*/}
                {/*            /!*            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">*!/*/}
                {/*            /!*                Location*!/*/}
                {/*            /!*            </th>*!/*/}
                {/*            /!*            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">*!/*/}
                {/*            /!*                Manual*!/*/}
                {/*            /!*            </th>*!/*/}
                {/*            /!*            <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">*!/*/}
                {/*            /!*                <span className="sr-only">Edit</span>*!/*/}
                {/*            /!*            </th>*!/*/}
                {/*            /!*        </tr>*!/*/}
                {/*            /!*        </thead>*!/*/}
                {/*            /!*        <tbody className="divide-y divide-gray-200 bg-white">*!/*/}
                {/*            /!*        {entries.map((entry) => (*!/*/}
                {/*            /!*            <tr key={entry.id}>*!/*/}
                {/*            /!*                <td className="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0">*!/*/}
                {/*            /!*                    <div className="flex items-center">*!/*/}
                {/*            /!*                        <div className="h-11 w-11 flex-shrink-0">*!/*/}
                {/*            /!*                            /!*<img className="h-11 w-11 rounded-full" src={person.image} alt=""/>*!/*!/*/}
                {/*            /!*                            {getThumbnailComponent(entry)}*!/*/}
                {/*            /!*                        </div>*!/*/}
                {/*            /!*                        <div className="ml-4">*!/*/}
                {/*            /!*                            /!*<div className="font-medium text-gray-900">{person.name}</div>*!/*!/*/}
                {/*            /!*                            /!*<div className="mt-1 text-gray-500">{person.email}</div>*!/*!/*/}
                {/*            /!*                            <EventTitle event={entry}/>*!/*/}
                {/*            /!*                        </div>*!/*/}
                {/*            /!*                    </div>*!/*/}
                {/*            /!*                </td>*!/*/}
                {/*            /!*                <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">*!/*/}
                {/*            /!*                    /!*<div className="text-gray-900">{person.title}</div>*!/*!/*/}
                {/*            /!*                    /!*<div className="mt-1 text-gray-500">{person.department}</div>*!/*!/*/}
                {/*            /!*                    {entry.eventDateUtcSeconds && text.asShortDateWithYear(entry.eventDateUtcSeconds)}*!/*/}

                {/*            /!*                </td>*!/*/}
                {/*            /!*                <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">*!/*/}
                {/*            /!*                    <LocationTitle id={entry.locationId}/>*!/*/}
                {/*            /!*                </td>*!/*/}
                {/*            /!*                <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500"> {entry.studentManualNumber}</td>*!/*/}
                {/*            /!*                <td className="relative whitespace-nowrap py-5 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">*!/*/}
                {/*            /!*                    <DropMenu>*!/*/}
                {/*            /!*                        <DropMenuItem title="Edit" onClick={() => {*!/*/}
                {/*            /!*                            handleEditEntry(entry);*!/*/}
                {/*            /!*                        }}/>*!/*/}
                {/*            /!*                        <DropMenuItem title="Remove" onClick={() => {*!/*/}
                {/*            /!*                            setEntryForDeletion(entry)*!/*/}
                {/*            /!*                        }}/>*!/*/}
                {/*            /!*                    </DropMenu>*!/*/}
                {/*            /!*                </td>*!/*/}
                {/*            /!*            </tr>*!/*/}
                {/*            /!*        ))}*!/*/}
                {/*            /!*        </tbody>*!/*/}
                {/*            /!*    </table>*!/*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*}*/}


            </div>)
    }
}

function EventTitle(props: { event: ContactProgressionEvent }) {

    if (!props.event) {
        return <></>;
    }
    switch (props.event.definitionType) {
        case "class":
            return <ClassTitle id={props.event.definitionId}/>
        case "healing":
            return <HealingTitle id={props.event.definitionId}/>
        case "initiation":
            return <InitiationTitle id={props.event.definitionId}/>
    }

}