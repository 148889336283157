import React, {useEffect, useState} from "react";
import {useGuideFlowProviderContext} from "../../../GuideFlowProviderContext";
import {Dialog, DialogActions, DialogBody, DialogTitle} from "../tailwindui/dialog";
import {CheckCircleIcon, UserIcon} from "@heroicons/react/20/solid";
import {Button} from "../tailwindui/button";
import {LoadingPanel} from "../vidence/blanks/LoadingPanel";
import {Description} from "../tailwindui/fieldset";
import {normalizeGuid} from "../../../providers/DataHelpers";
import {ClassDefinition} from "../../../providers/definitions/ClassDefinitionProvider";
import ClassTitleList from "../renderers/Classes/ClassTitleList";

interface ClassSelectorProps {
    selection?: ClassDefinition[],
    allowMultiple?: boolean,
    readonly?: boolean,
    onSaveSelection: (selectedHealings: ClassDefinition[]) => void
}

export default function ClassSelector(props: ClassSelectorProps) {

    const context = useGuideFlowProviderContext();
    const [allClasses, setAllClasses] = useState<Record<string, ClassDefinition[]>>();
    const [loading, setLoading] = useState(true);
    const [showDialog, setShowDialog] = useState(false);
    const [selection, setSelection] = useState<ClassDefinition[]>([]);
    const handleDialogClose = () => {
        setShowDialog(false);
    }

    const isSelected = (definition: ClassDefinition) => {
        if (selection) {
            return selection.filter(def => normalizeGuid(def.id) === normalizeGuid(definition.id)).length > 0;
        }
    }

    const handleSaveFinalSelection = () => {
        props.onSaveSelection(selection ?? []);
        setShowDialog(false);
    }

    const handleCancel = () => {
        setShowDialog(false);
    }

    const handleDefinitionOnChange = (event: any, definition: ClassDefinition) => {
        const item = selection.filter(item => normalizeGuid(item.id) === definition.id);
        const isSelected = item !== null;

        if (isSelected) {
            if (props.allowMultiple) {
                setSelection(prev => [...prev, definition]);
            } else {
                setSelection([definition]);
            }
        } else {
            if (props.allowMultiple) {
                const filtered = selection.filter(item => normalizeGuid(item.id) !== definition.id);
                setSelection(filtered);
            } else {
                setSelection([]);
            }
        }
    }

    useEffect(() => {
        setLoading(true);
        context.ClassDefinitions.getAllGrouped().then(list => {
            setAllClasses(list);
            setLoading(false);
        });

    }, [context.ClassDefinitions, context.ClassDefinitions.StateChanged]);

    const hasDialogSelection = selection?.length > 0;
    const propsSelection = props.selection ?? [];

    if (loading) {
        return <LoadingPanel text={"loading classes"}/>
    }

    return (
        <>
            <Dialog
                size="lg"
                onClose={handleDialogClose}
                initialFocus={undefined}
                open={showDialog}>

                <DialogTitle>
                    <div className="flex flex-row gap-3 justify-center">
                        <UserIcon className="h-6 w-6" aria-hidden="true"/>
                        <h3 className="text-base font-semibold leading-6 text-gray-900">
                            Classes
                        </h3>
                    </div>
                </DialogTitle>
                {/*<DialogDescription>Please agree to the following terms and conditions to continue.</DialogDescription>*/}
                <DialogBody className="text-sm/6 text-zinc-900 dark:text-white h-96 overflow-y-scroll border-b">
                    {Object.keys(allClasses).map((letter) => (
                        <div key={letter} className="relative border ">
                            <div className="sticky top-0 z-10 border-b border-t border-gray-200 bg-gray-50 px-6 py-1 text-sm font-medium text-gray-500">
                                <h3>{letter}</h3>
                            </div>
                            <ul className="relative z-0 divide-y divide-gray-200">
                                {allClasses[letter].map((definition) => (
                                    <div key={definition.id} className="relative flex items-center space-x-3 px-6 py-5 hover:bg-gray-50"
                                         onClick={(event) => handleDefinitionOnChange(event, definition)}>
                                        {isSelected(definition) && <CheckCircleIcon className="h-5 w-5"/>}
                                        {!isSelected(definition) &&
                                            <svg fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5">
                                                <path
                                                    d="M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z"
                                                    stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>}

                                        <div className="space-x-4 w-full text-nowrap">
                                            <div>{definition.title}</div>
                                            <Description>{definition.description}</Description>
                                        </div>
                                    </div>
                                ))}
                            </ul>
                        </div>
                    ))}
                </DialogBody>
                <DialogActions className="w-full">
                    <div>
                        <Button
                            type="button"
                            disabled={!hasDialogSelection}
                            onClick={handleSaveFinalSelection}
                            className="mt-1 inline-flex w-full justify-center">
                            Save
                        </Button>
                    </div>
                    <div>
                        <Button
                            type="button"
                            onClick={handleCancel}
                            className="mt-1 inline-flex w-full justify-center">
                            Cancel
                        </Button>
                    </div>
                </DialogActions>
            </Dialog>
            <div className="flex flex-row gap-3">
                {!props.allowMultiple &&
                    <div>
                        {propsSelection.length > 0 && <span className="pr-3 ">{propsSelection[0].title}</span>}
                        {propsSelection.length === 0 && <span className="pr-3 text-gray-600">Select a Class</span>}
                        {!props.readonly && <Button type="button" onClick={() => setShowDialog(true)} outline={true}>
                            ...
                        </Button>}
                    </div>}

                {props.allowMultiple &&
                    <>
                        {propsSelection.length > 0 && <span className="pr-3 "><ClassTitleList idList={propsSelection.map(def => def.id)}/></span>}
                        {propsSelection.length === 0 && <span className="pr-3 text-gray-600">Select Classes</span>}
                        {!props.readonly && <Button type="button" onClick={() => setShowDialog(true)} outline={true}>
                            ...
                        </Button>}
                    </>}
            </div>
        </>
    );
}