import {useGuideFlowProviderContext} from "../../../../../GuideFlowProviderContext";
import {Controller, useForm} from "react-hook-form";
import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {Field, Label} from "../../../../controls/tailwindui/fieldset";
import {Button} from "../../../../controls/tailwindui/button";
import {CassieAwareSubmitButton} from "../../../../controls/vidence/cassie/CassieAwareSubmitButton";
import {CassieOfflineBanner} from "../../../../controls/vidence/cassie/CassieOfflineBanner";
import {ContactProgressionEventForm} from "./FormTypes";
import {HealingDefinition} from "../../../../../providers/definitions/HealingDefinitionProvider";
import {ContactProgressionEvent} from "../../../../../providers/contact/ContactProgressionEventProvider";
import toast from "react-hot-toast";
import {SECTION_ROOT} from "../../FormTypes";
import HealingSelector from "../../../../controls/gf/HealingSelector";
import {dateTextToUtcSeconds, utcSecondsToDateText} from "../../../../../helpers/Dates";
import ConfirmationDialog from "../../../../controls/vidence/modal/ConfirmationDialog";

export default function ContactProgressionAddEditHealing() {

    const {id, progressionId} = useParams();
    const context = useGuideFlowProviderContext();
    const {control, register, reset, handleSubmit} = useForm<ContactProgressionEventForm>();
    const [isSaving, setIsSaving] = useState(false);
    const [entryForDeletion, setEntryForDeletion] = useState<ContactProgressionEvent | null>(null);

    const navigate = useNavigate();

    useEffect(() => {
        if (progressionId) {
            context.Contacts.ProgressionEvents.get(progressionId).then(evnt => {
                context.HealingDefinitions.getList([evnt.definitionId]).then(list => {
                    reset({
                        definition: list,
                        eventDate: utcSecondsToDateText(evnt.eventDateUtcSeconds),
                        ...evnt,
                    });
                });
            });
        }
    }, [progressionId, reset, context.Contacts.ProgressionEvents, context.Contacts.ProgressionEvents.StateChanged, context.HealingDefinitions, context.HealingDefinitions.StateChanged]);

    const handleHealingChange = (value: HealingDefinition[], field: any) => {
        field.onChange(value);
    }

    const handleFinalizeDelete = async (answer: boolean) => {
        if (answer) {
            await context.Documents.delete(entryForDeletion.id);
        }

        setEntryForDeletion(null);
    }

    const onSubmit = async (formData: ContactProgressionEventForm) => {

        setIsSaving(true);

        // console.log('date' ,formData.eventDateUtcSeconds);
        const item: ContactProgressionEvent = {
            ...formData,
            contactId: id,
            eventDateUtcSeconds: dateTextToUtcSeconds(formData.eventDate),
            definitionType: "healing",
            definitionId: formData.definition[0].id
        };

        // console.log('item', item);

        await context.Contacts.ProgressionEvents.save(item);
        toast.success("Healing Session Completed!", {icon: "🪄"});
        setIsSaving(false);
        navigate(`${SECTION_ROOT}/${id}/progression`);
    };

    const isEditMode = progressionId != null;

    return (
        <div className="h-full">
            <ConfirmationDialog
                isOpen={entryForDeletion !== null}
                title="Are you sure?"
                onAnswerReceived={handleFinalizeDelete}>
                Are you sure you want to delete this?
            </ConfirmationDialog>

            <CassieOfflineBanner/>
            <div className="p-3">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="xl:gap-x-16">
                        <div>
                            <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
                                <div>
                                    <Field>
                                        <Label>Healing</Label>
                                        {/*<Input {...register("", {required: true,})} invalid={errors?.title != null} autoFocus={true}/>*/}
                                        {/*{errors?.title && <ErrorMessage>*{errors.title.message}</ErrorMessage>}*/}

                                        <Controller
                                            name="definition"
                                            control={control}
                                            rules={{required: true}}
                                            defaultValue={undefined}
                                            render={({field}) => {
                                                return <HealingSelector
                                                    allowMultiple={false}
                                                    readonly={isEditMode}
                                                    selection={field.value?.map((v: any) => v as HealingDefinition) ?? []}
                                                    onSaveSelection={(value) => handleHealingChange(value, field)}
                                                />
                                            }}
                                        />

                                    </Field>
                                </div>
                                <div className="sm:col-span-2">
                                    <Field>
                                        <Label>Date/Time</Label>
                                    </Field>
                                    <input aria-label="Date and time" {...register("eventDate", {required: true})} type="date"/>
                                </div>

                                {/*<div className="sm:col-span-2">*/}
                                {/*    <Field>*/}
                                {/*        <Label>Location</Label>*/}
                                {/*    </Field>*/}
                                {/*    <Controller*/}
                                {/*        name="locationId"*/}
                                {/*        control={control}*/}
                                {/*        defaultValue={null}*/}
                                {/*        render={(field) => (*/}
                                {/*            <LocationSelector Label="Default" onChange={(value) => handleLocationChange(value, field)}/>*/}
                                {/*        )}*/}
                                {/*    />*/}
                                {/*</div>*/}
                            </div>
                        </div>
                    </div>

                    <div className="mt-6 flex items-center justify-end gap-x-6">
                        <Button type="button" disabled={isSaving} onClick={() => navigate("../")} outline={true}>
                            Cancel
                        </Button>
                        <CassieAwareSubmitButton isSaving={isSaving}/>
                    </div>
                </form>
            </div>
        </div>
    )
}