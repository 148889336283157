import React, {useEffect, useState} from "react";
import {ContactList} from "./ContactList";
import {Outlet, useParams} from "react-router-dom";
import {isMediumScreen, isSmallScreen} from "../../../helpers/breakpoint";

export default function ContactSectionIndex() {
    const {id} = useParams();
    const [showDirectory, setShowDirectory] = useState(false);
    const tiny = isSmallScreen() || isMediumScreen();
    useEffect(() => {
        setShowDirectory(tiny && !id);
    }, [id, tiny]);

    return (
        <div className="h-full relative z-0 flex flex-1 overflow-y-auto overflow-x-hidden">
            {showDirectory &&
                <aside className="w-full flex-shrink-0 border-r border-gray-200 order-first flex flex-col">
                    <ContactList/>
                </aside>
            }

            {!tiny && 
                <aside className="h-full w-96 flex-shrink-0 border-r border-gray-200 xl:order-first xl:flex xl:flex-col">
                    <ContactList/>
                </aside>}

            <main className="flex-1 relative z-0  focus:outline-none xl:order-last">
                <Outlet/>
            </main>
        </div>);
}