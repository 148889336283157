import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {useGuideFlowProviderContext} from "../../../../../GuideFlowProviderContext";
import {ClassDefinition, ScheduledClass} from "../../../../../providers/definitions/ClassDefinitionProvider";
import {LoadingPanel} from "../../../../controls/vidence/blanks/LoadingPanel";
import {ContactProfileHeader} from "../../../../controls/renderers/Contacts/ContactProfileHeader";
import {DropMenu, DropMenuItem} from "../../../../controls/vidence/menus/dropmenu/Dropmenu";
import {Dialog, DialogActions, DialogBody, DialogTitle} from "../../../../controls/tailwindui/dialog";
import {UserIcon} from "@heroicons/react/20/solid";
import {Button} from "../../../../controls/tailwindui/button";
import {Recommendation} from "../../../../../providers/recomendations/RecommendationProvider";

interface DialogProps {
    showDialog: boolean
    onClose: Function
}

export default function ClassScheduleAttendeeDialog(props: DialogProps) {
    const {id, classScheduleId} = useParams();
    const [loaded, setLoaded] = useState(false);
    const [scheduledClass, setScheduledClass] = useState<ScheduledClass | undefined>();
    const [relatedDefinition, setRelatedDefinition] = useState<ClassDefinition>();
    const context = useGuideFlowProviderContext();

    //Directly eligible
    //find contacts who have taken the prereqs

    // Recent contact (recent connections, recent healing recipients, recent ..)

    useEffect(() => {
        context.ClassDefinitions.getScheduledClass(classScheduleId).then((scheduledClass) => {
            context.ClassDefinitions.get(scheduledClass.classDefinitionId).then(def => {
                setScheduledClass(scheduledClass);
                setRelatedDefinition(def);
                setLoaded(true);
            });
        });
    }, [classScheduleId, context.ClassDefinitions, context.ClassDefinitions.StateChanged, id]);

    if (!loaded) {
        return <LoadingPanel text="Loading the class schedule..."/>
    }

    console.log(relatedDefinition);

    function handleDialogClose() {

    }

    function handleCancel() {
        props.onClose();
    }

    return (

        <Dialog
            size="2xl"
            onClose={handleDialogClose}
            initialFocus={undefined}
            open={props.showDialog}>

            <DialogTitle>
                <div className="flex flex-row gap-3 justify-center">
                    <UserIcon className="h-6 w-6" aria-hidden="true"/>
                    <h3 className="text-base font-semibold leading-6 text-gray-900">
                        Find attendees
                    </h3>
                </div>
            </DialogTitle>
            <DialogBody className="text-sm/6 text-zinc-900 dark:text-white h-full overflow-y-scroll">
                <RecommendedAttendeeList scheduledClass={scheduledClass}/>
            </DialogBody>
            <DialogActions className="w-full">
                <div>
                    {/*<Button*/}
                    {/*    type="button"*/}
                    {/*    disabled={!hasDialogSelection}*/}
                    {/*    onClick={handleSaveFinalSelection}*/}
                    {/*    className="mt-1 inline-flex w-full justify-center">*/}
                    {/*    Save*/}
                    {/*</Button>*/}
                </div>
                <div>
                    <Button
                        type="button"
                        onClick={handleCancel}
                        className="mt-1 inline-flex w-full justify-center">
                        Cancel
                    </Button>
                </div>
            </DialogActions>
        </Dialog>
    )
}

function RecommendedAttendeeList(props: { scheduledClass: ScheduledClass }) {

    const [recommendedStudents, setRecommendedStudents] = useState<Recommendation[]>([]);
    const [loaded, setLoaded] = useState(false);

    const context = useGuideFlowProviderContext();

    useEffect(() => {
        context.Recommendations.getClassRecommendations(props.scheduledClass.classDefinitionId).then(students => {
            setRecommendedStudents(students);
            setLoaded(true);
        });
    }, [props, context.Recommendations.StateChanged, context.Recommendations]);

    if (!loaded) {
        return <LoadingPanel text="loading recommendations"/>
    }
    
    return (
        <div className="">
            <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                    <h1 className="text-base font-semibold leading-6 text-gray-900">Attendees</h1>
                    <p className="mt-2 text-sm text-gray-700">
                        Recommended Attendees
                    </p>
                </div>
                <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                    {/*<button*/}
                    {/*    type="button"*/}
                    {/*    onClick={() => handleAddEntry()}*/}
                    {/*    className="relative inline-flex rounded-md items-center gap-x-1.5 bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:z-10 hover:bg-gray-50 focus:z-10">*/}
                    {/*    <PlusCircleIcon className="-ml-0.5 h-5 w-5 text-gray-400" aria-hidden="true"/>Add*/}
                    {/*</button>*/}
                </div>
            </div>
            <div className="-mx-4 mt-8 sm:-mx-0">
                <table className="min-w-full divide-y divide-gray-300">
                    <thead>
                    <tr>
                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                            Name
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                            Reason
                        </th>
                    </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                    {recommendedStudents.map((contact) => (
                        <tr key={contact.id}>
                            <td>
                                <ContactProfileHeader id={contact.id}/>
                            </td>
                            <td className="px-3 py-4 text-sm text-gray-500">
                                contact
                            </td>
                            <td className="py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                                <DropMenu>
                                    <DropMenuItem title="Mark as Candidate" onClick={undefined}/>
                                    <DropMenuItem title="Mark as Tentative" onClick={undefined}/>
                                    <DropMenuItem title="Mark Deposit" onClick={undefined}/>
                                    <DropMenuItem title="Mark Payment" onClick={undefined}/>
                                    <DropMenuItem title="Remove" onClick={undefined}/>
                                </DropMenu>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

