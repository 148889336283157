import {observer} from "mobx-react-lite";
import React from "react";
import {CassiePayloadResult} from "../../../../../providers/core/cassie/CassieTypes";

interface ImageProps {
    payload: CassiePayloadResult
    className: string
}

export const Image = observer((props: ImageProps) => {
    const imageUri = `data:${props.payload.contentType};base64,${props.payload.content64}`;
    return (<img className={`bg-white/75 ${props.className}`} src={imageUri} alt=""/>);
});