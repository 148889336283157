import {HealingDefinition} from "../HealingDefinitionProvider";
import {parseDineroSnapshot} from "../../../helpers/Money";
import {hoursToMinutes} from "../../../helpers/Dates";

export const LifeActivation: HealingDefinition = {
    id: "aaa9a1df-0dc7-49a0-a07f-4bd65e5c6699",
    title: "Life Activation",
    exchange: parseDineroSnapshot(250),
    durationMinutes: 90,
    description: ""
}

export const FullSpiritActivation: HealingDefinition = {
    id: "bbb689b1-d6f1-4e5f-a3c7-fefcec285196",
    title: "Full Spirit Activation",
    exchange: parseDineroSnapshot(250),
    durationMinutes: 90,
    description: "",
    prerequisiteHealings: [LifeActivation.id]
}

export const GalacticActivation1: HealingDefinition = {
    id: "aaa689b1-cccc-4e5f-aa99-fefcec285132",
    title: "Galactic Activation",
    exchange: parseDineroSnapshot(1200),
    durationMinutes: 90,
    description: "",
    prerequisiteHealings: [LifeActivation.id]
}

export const ShamanicAuraClearing: HealingDefinition = {
    id: "aaad86a7-f0ed-4852-a1a6-9c0af5d40efe",
    title: "Shamanic Aura Clearing",
    exchange: parseDineroSnapshot(50),
    durationMinutes: 30,
    description: ""
}
export const SoulRetrieval: HealingDefinition = {
    id: "aaa86a51-3333-43af-acf6-4759247d88bd",
    title: "Soul Retrieval",
    exchange: parseDineroSnapshot(250),
    durationMinutes: hoursToMinutes(2),
    description: ""
}
export const EgyptianAuraHealing: HealingDefinition = {
    id: 'aaa64f4f-d650-4eee-a47a-006434db77ee',
    title: 'Egyptian Aura Healing',
    exchange: parseDineroSnapshot(250),
    durationMinutes: 60,
    description: ""
}
export const InterstellarTubeOfLight: HealingDefinition = {
    id: 'aaa5f15a-a428-1123-bc77-0ab6634ee714',
    title: 'Archangel Michael Interstellar Tube of Light',
    exchange: parseDineroSnapshot(250),
    durationMinutes: 60,
    description: ""
}
export const CordCutting: HealingDefinition = {
    id: 'aaa9434e-aa3e-4c6f-8774-bc83fc608c34',
    title: 'Cord Cutting',
    exchange: parseDineroSnapshot(250),
    durationMinutes: 90,
    description: ""
}
export const CoreWillInfusion: HealingDefinition = {
    id: 'aaaa2ff0-da34-4902-aa33-7e28693081f0',
    title: 'Core Will Infusion',
    exchange: parseDineroSnapshot(250),
    durationMinutes: 60,
    description: ""
}
export const FireAndSoulInfusion: HealingDefinition = {
    id: 'aaab74cd-0bae-4889-bbb3-f3851fdc1273',
    title: 'Fire and Soul Infusion',
    exchange: parseDineroSnapshot(250),
    durationMinutes: 60,
    description: ""
}
export const SparkOfLife: HealingDefinition = {
    id: 'aaa17dd2-4413-aa33-9f68-05da727c80be',
    title: 'Spark of Life',
    exchange: parseDineroSnapshot(250),
    durationMinutes: 90,
    description: ""
}


export const builtInHealings: HealingDefinition[] = [
    LifeActivation,
    GalacticActivation1,
    FullSpiritActivation,
    ShamanicAuraClearing,
    SoulRetrieval,
    EgyptianAuraHealing,
    InterstellarTubeOfLight,
    CordCutting,
    CoreWillInfusion,
    FireAndSoulInfusion,
    SparkOfLife
]