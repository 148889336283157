import {useEffect, useState} from "react";
import {useGuideFlowProviderContext} from "../../../../GuideFlowProviderContext";
import {ClassDefinition} from "../../../../providers/definitions/ClassDefinitionProvider";

interface InitiationTitleListProps {
    idList: string[]
}

export default function InitiationTitleList(props: InitiationTitleListProps) {

    const [list, setList] = useState<ClassDefinition[]>()
    const context = useGuideFlowProviderContext();

    useEffect(() => {
        context.InitiationDefinitions.getList(props.idList ?? []).then(setList);
    }, [context.InitiationDefinitions, context.InitiationDefinitions.StateChanged, props.idList]);

    // console.log('titles', props.idList)
    return (
        <ul>{list?.map(d =>
            <li key={d.id} title={d.description}>
                {/*<Link to={`/classes/${d.id}`}>*/}
                    {d.title}
                {/*</Link>*/}
            </li>
        )}</ul>
    )
}