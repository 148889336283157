import {ApiType, DotYouClient} from '@youfoundation/js-lib/core';
import {base64ToUint8Array} from '@youfoundation/js-lib/helpers';
import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import useVerifyToken from './useVerifyToken';
import {
    logout as logoutYouauth,
    finalizeAuthentication as finalizeAuthenticationYouAuth,
    getRegistrationParams,
    preAuth as preauthApps,
    retrieveIdentity,
    saveIdentity,
    createEccPair,
    YouAuthorizationParams,
    saveEccKey,
    retrieveEccKey,
    throwAwayTheECCKey,
} from '@youfoundation/js-lib/auth';
import {getAppDefinition} from "../../system/AppDefinition";

export const APP_SHARED_SECRET = 'APSS';
export const APP_AUTH_TOKEN = 'BX0900';

const hasSharedSecret = () => {
    const raw = window.localStorage.getItem(APP_SHARED_SECRET);
    return !!raw;
};

const useAuth = () => {
    const [authenticationState, setAuthenticationState] = useState<
        'unknown' | 'anonymous' | 'authenticated'
    >(hasSharedSecret() ? 'unknown' : 'anonymous');
    const navigate = useNavigate();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const logout = async (): Promise<void> => {
        await logoutYouauth(getDotYouClient());

        localStorage.removeItem(APP_SHARED_SECRET);
        localStorage.removeItem(APP_AUTH_TOKEN);
        setAuthenticationState('anonymous');

        navigate('/');
        window.location.reload();
    };

    const preauth = async (): Promise<void> => {
        await preauthApps(getDotYouClient());
    };

    const getAppAuthToken = () => window.localStorage.getItem(APP_AUTH_TOKEN);

    const getSharedSecret = () => {
        const raw = window.localStorage.getItem(APP_SHARED_SECRET);
        if (raw) return base64ToUint8Array(raw);
    };

    const getDotYouClient = () => {
        const headers: Record<string, string> = {};
        const authToken = getAppAuthToken();

        if (authToken) {
            headers[APP_AUTH_TOKEN] = authToken;
        }

        return new DotYouClient({
            sharedSecret: getSharedSecret(),
            api: ApiType.App,
            identity: retrieveIdentity(),
            headers: headers,
        });
    };

    const {data: hasValidToken, isFetchedAfterMount} = useVerifyToken(getDotYouClient());

    useEffect(() => {
            if (isFetchedAfterMount && hasValidToken !== undefined && window.navigator.onLine) {
                setAuthenticationState(hasValidToken ? 'authenticated' : 'anonymous');

                if (!hasValidToken) {
                    console.warn("invalid token");
                }

                // if (!hasValidToken) {
                //     setAuthenticationState('anonymous');
                //     if (window.localStorage.getItem(APP_SHARED_SECRET)) {
                //         console.log('Token is invalid, logging out..');
                //         // Auth state was presumed logged in, but not allowed.. Will attempt reload page?
                //         //  (Browsers may ignore, as it's not a reload on user request)
                //         logout();
                //     }
                // }
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [hasValidToken, isFetchedAfterMount]);

    return {
        logout,
        preauth,
        authToken: getAppAuthToken(),
        getDotYouClient,
        getSharedSecret,
        getIdentity: retrieveIdentity,
        isAuthenticated: authenticationState !== 'anonymous',
    };
};

export const useYouAuthAuthorization = () => {
    const getAuthorizationParameters = async (returnUrl: string): Promise<YouAuthorizationParams> => {
        const eccKey = await createEccPair();

        // Persist key for usage on finalize
        await saveEccKey(eccKey);

        const finalizeUrl = `${window.location.origin}/auth/finalize`;
        const appDefinition = getAppDefinition();

        return await getRegistrationParams(
            finalizeUrl,
            appDefinition.name,
            appDefinition.id,
            appDefinition.permissionKeys,
            appDefinition.circlePermissionKeys,
            appDefinition.drives,
            appDefinition.circleDrives,
            undefined, //circles
            eccKey.publicKey,
            appDefinition.host,
            undefined,
            returnUrl
        )
            ;
    };

    const finalizeAuthorization = async (identity: string, publicKey: string, salt: string) => {
        try {
            const privateKey = await retrieveEccKey();
            if (!privateKey) throw new Error('Failed to retrieve key');

            const {clientAuthToken, sharedSecret} = await finalizeAuthenticationYouAuth(
                identity,
                privateKey,
                publicKey,
                salt
            );

            if (identity) saveIdentity(identity);
            localStorage.setItem(APP_SHARED_SECRET, sharedSecret);
            localStorage.setItem(APP_AUTH_TOKEN, clientAuthToken);

            throwAwayTheECCKey();
        } catch (ex) {
            console.error(ex);
            return false;
        }

        return true;
    };

    return {getAuthorizationParameters, finalizeAuthorization};
};

export default useAuth;
