import React from "react";
import {classNames} from "../../../viewUtils";

interface HighlightTagProps {
    icon?: any
    color: "green" | "yellow",
    text: string
}

export default function HighlightTag(props: HighlightTagProps) {

    let color = "";
    let iconColor = "";
    
    switch (props.color) {
        case "green":
            color = "text-green-700 ring-green-600/20 bg-green-50"
            iconColor = "text-green-500";
            break;
        case "yellow":
            color = "text-yellow-700 ring-yellow-600/20 bg-yellow-50"
            iconColor = "text-yellow-500";
            break;

    }
    return (
        <span
            className={classNames("inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset", color)}>
            {props.icon && <props.icon className={classNames("h-5 w-5 pr-1 flex-shrink-0 self-center", iconColor)} aria-hidden="true"/>}
            {props.text}
        </span>
    )
}