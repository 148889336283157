import {observer} from "mobx-react-lite";
import React, {useEffect, useState} from "react";
import {useGuideFlowProviderContext} from "../../../../../GuideFlowProviderContext";
import {Image64Data} from "../../../../../providers/core/storage/HomebaseStorageTypes";
import {imageDataToUri, previewThumbnailUri} from "../../image/ImageUtil";
import {DocumentDescriptor} from "../../../../../providers/core/documents/DocumentProvider";

interface ThumbnailProps {
    document: DocumentDescriptor
    className: string
}

export const Thumbnail = observer((props: ThumbnailProps) => {
    const context = useGuideFlowProviderContext();
    const [image, setImage] = useState<Image64Data>();

    useEffect(() => {
        context.Documents.getThumbnail(props.document.id).then(setImage);
    }, [context.Documents, context.Documents.StateChanged, props.document]);

    const imageUri = image ? imageDataToUri(image) :
        props.document.storage.previewThumbnail ?
            previewThumbnailUri(props.document.storage.previewThumbnail) : "";
    
    return (<img className={`bg-white/75 ${props.className}`} src={imageUri} alt=""/>);
});