import moment, {Moment} from "moment";
import {GFContext} from "../GuideFlowProviderContext";

export class SyncProvider {
    private static instance: SyncProvider
    private readonly context: GFContext;

    private lastSyncTime: Moment = undefined;
    private readonly pollingInterval = 12000;
    private intervalId;

    constructor(context: GFContext) {
        this.context = context;
        this.callSync = this.callSync.bind(this);
    }

    static getInstance(context: GFContext) {
        if (SyncProvider.instance == null) {
            SyncProvider.instance = new SyncProvider(context);
        }
        return SyncProvider.instance;
    }

    public startSyncTimer() {
        if (!this.intervalId) {
            console.log(`Sync timer started at ${this.pollingInterval} ms`);
            this.lastSyncTime = moment.utc();
            // const secondsToGoBack = 19;
            // const asOfTime = moment.utc().subtract(secondsToGoBack, 'seconds');

            this.intervalId = setInterval(this.callSync, this.pollingInterval);
        }
    }

    public stopSyncTimer() {
        clearInterval(this.intervalId);
        this.intervalId = null
    }

    private async callSync() {
        
        if(!window.navigator.onLine)
        {
            console.debug("sync called but connection is offline");
            return;
        }

        const now = moment.utc();

        // const asOfTime = this.lastSyncTime.unix();
        const asOfTime = now.subtract(100, 'minutes').unix();

        // console.log("calling sync as of", asOfTime);
        // console.log("now is", now.unix());

        //
        // Note: Order matters
        //

        // First definitions
        await this.context.ClassDefinitions.synchronize(asOfTime);
        await this.context.HealingDefinitions.synchronize(asOfTime);

        // Sync contacts
        await this.context.Contacts.synchronize(asOfTime);
        this.lastSyncTime = moment.utc();
    }

}