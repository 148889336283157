import React, {useEffect, useState} from "react";
import {InitProvider} from "./providers/InitProvider";
import useAuth from "./hooks/auth/useAuth";
import {useGuideFlowProviderContext} from "./GuideFlowProviderContext";
import {Button} from "./components/controls/tailwindui/button";
import {useNavigate} from "react-router-dom";
import SpinnerIcon from "./components/controls/icons/SpinnerIcon";

type InitState = "requires-check" | "checking" | "init-required" | "initializing" | "complete";

export const INIT_HAS_RUN = "initHasRun";

export default function WelcomeScreen() {

    const ctx = useGuideFlowProviderContext();
    const {getDotYouClient} = useAuth();
    const client = getDotYouClient();
    const navigate = useNavigate();

    const [initStatus, setInitStatus] = useState<InitState>("requires-check");
    const initProvider = InitProvider.getInstance(client, ctx);
    const [messages, setMessages] = useState<string[]>([]);

    useEffect(() => {

        if (initStatus === "requires-check") {
            setInitStatus("checking");

            initProvider.isFirstTimeRun().then(file => {
                if (file && file.version === 1) {
                    setInitStatus("complete");
                } else {
                    setInitStatus("init-required");
                }
            });
        }
    }, [initStatus, initProvider, client, ctx]);

    function onDefinitionInitialized(message: string) {
        setMessages(ps => [...ps, message]);
    }

    const handleSetup = async () => {
        setInitStatus("initializing");
        await initProvider.performFirstTimeOps(onDefinitionInitialized);

        setInitStatus("complete");
        localStorage.setItem(INIT_HAS_RUN, "1");
    }

    const handleFinalize = () => {
        localStorage.setItem(INIT_HAS_RUN, "1");
        navigate("/home");
    }

    if (initStatus === "checking") {
        return <p>ah. hold up you... ☝️ I'm checking if this is your first time using this app or if you're just on a new device... 😘</p>
    }

    if (initStatus === "init-required") {

        return <div className="flex gap-3 bg-slate-50 flex-col relative z-0 flex-1 overflow-scroll p:3 sm:p-1">
            <p>OMG, it is.. welcome n000bie 💛... Let me setup some healings and classes you will, no doubt, have by now... 🖖</p>
            <Button onClick={handleSetup}>Sweet, setup my stuff now...</Button>
        </div>;
    }

    if (initStatus === "initializing") {
        return (<div>
            <SpinnerIcon/> yea! we're doing the thing... so; be patient 😬😬😬
            <ul>
                {messages.map(m => <li key={m}>{m}</li>)}
            </ul>
        </div>)
    }

    if (initStatus === "complete") {
        return (
            <div className="flex gap-3 bg-slate-50 flex-col relative z-0 flex-1 overflow-scroll p:3 sm:p-1">
                Alright.. you're all setup.
                <Button onClick={handleFinalize}>Let's go</Button>
            </div>);
    }
}

