import {DotYouClient} from "@youfoundation/js-lib/core";
import {DefinitionStorage, DefinitionStorageProvider} from "./DefinitionStorageProvider";
import {SaveFileConfig} from "../core/storage/HomebaseStorageTypes";
import {GuideFlowConfig} from "../../system/GuideFlowConfig";
import {builtInInitiations} from "./builtin/BuiltInInitiations";

const fileConfig: SaveFileConfig = {
    dataType: 777,
    fileType: 999,
    targetDrive: GuideFlowConfig.DataDrive
}

export interface InitiationDefinition extends DefinitionStorage {
    title: string,
    description: string
}

type TDefinition = InitiationDefinition;

export class InitiationDefinitionProvider extends DefinitionStorageProvider<TDefinition> {
    private static instance: InitiationDefinitionProvider

    static getInstance(dotYouClient: DotYouClient): InitiationDefinitionProvider {
        if (InitiationDefinitionProvider.instance == null) {
            InitiationDefinitionProvider.instance = new InitiationDefinitionProvider(fileConfig, dotYouClient, builtInInitiations);
        }

        return InitiationDefinitionProvider.instance;
    }

}