export function classNames(...classes: any) {
    return classes.filter(Boolean).join(' ')
}

export const naah = (value: any, value2?: any) => {
    let valueToReturn = value2 ?? value;

    if (value) {
        return valueToReturn;
    }

    return "N/A"
}
