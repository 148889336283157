import {HealingDefinition} from "../../../providers/definitions/HealingDefinitionProvider";

export const SECTION_ROOT = "/healings";

export type HealingDefinitionForm = Omit<HealingDefinition, 'exchange'> & {
    exchangeRaw: number;
};

export const emptyHealingDefinition: HealingDefinitionForm = {
    id: "",
    title: "",
    description: "",
    durationMinutes: 0,
    exchangeRaw: 0,
    prerequisiteHealings: []
};