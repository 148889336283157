import {useGuideFlowProviderContext} from "../../../GuideFlowProviderContext";
import React, {useEffect, useState} from "react";
import {observer} from "mobx-react-lite";
import {Avatar} from "../../controls/tailwindui/avatar";
import {imageDataToUri} from "../../controls/vidence/image/ImageUtil";
import {Image64Data} from "../../../providers/core/storage/HomebaseStorageTypes";

interface ContactProfileImageProps {
    id: string,
    initials: string,
    className: string,
    onClick?: () => void
}

export const ContactProfileImage = observer((props: ContactProfileImageProps) => {
    const context = useGuideFlowProviderContext();
    const [image, setImage] = useState<Image64Data>();

    useEffect(() => {
        // console.log('getting profile image')
        context.Contacts.getProfileImage(props.id).then(setImage);
    }, [context.Contacts, context.Contacts.StateChanged, props.id]);

    const imageUri = image ? imageDataToUri(image) : "";
    return (<Avatar onClick={props.onClick} initials={props.initials} square={false} className={`bg-white/75 ${props.className}`}
                    src={imageUri}/>)
})
