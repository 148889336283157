import React from "react";
import {ScheduledClass} from "../../../../../providers/definitions/ClassDefinitionProvider";
import ClassTitle from "../../../../controls/renderers/Classes/ClassTitle";
import {AcademicCapIcon} from "@heroicons/react/24/outline";
import {useFriendlyText} from "../../../../../hooks/friendlyText/useFriendlyText";
import {UserIcon} from "@heroicons/react/20/solid";
import {Link} from "react-router-dom";
import {filterCandiates, filterConfirmed} from "../../../../../providers/definitions/utils/scheduleUtils";
import HighlightTag from "../../../../controls/vidence/alerts/HighlightTag";

interface ClassPlanningPreviewProps {
    scheduledClass: ScheduledClass
}

export function ClassPlanningPreview(props: ClassPlanningPreviewProps) {

    const item = props.scheduledClass;

    const confirmed = filterConfirmed(item);
    const candidates = filterCandiates(item);

    const text = useFriendlyText();

    return (<div className="sm:min-h-40 lg:w-80 h-full">
        <div className="grid grid-cols-1 w-full rounded-lg border">
            <div className="relative overflow-hidden rounded-lg bg-white px-4 pb-12 pt-5 shadow sm:px-6 sm:pt-6">
                <dt className="">
                    <div className="absolute rounded-md bg-indigo-500 p-3">
                        <AcademicCapIcon className="h-6 w-6 text-white" aria-hidden="true"/>
                    </div>
                    <p className="ml-16 truncate text-sm font-medium text-gray-500">
                        <ClassTitle id={item.classDefinitionId}/>
                    </p>
                </dt>
                <dd className="ml-16 flex items-baseline">
                    <p className="text-sm text-gray-400">{text.asShortDate(item.startDateTimeUtc)} ({text.fromNow(item.startDateTimeUtc)})</p>
                </dd>

                <div className="mt-5 space-x-2">
                    <HighlightTag color="yellow" icon={UserIcon} text={`${candidates.length} Candidate(s)`}/>
                    <HighlightTag color="green" icon={UserIcon} text={`${confirmed.length} Confirmed`}/>
                </div>
                
                <dd className="ml-16 flex items-baseline sm:pb-7">
                    <div className="absolute inset-x-0 bottom-0 bg-gray-50 px-4 py-4 sm:px-6">
                        <div className="text-sm">
                            <Link to={`/classes/${item.classDefinitionId}/schedule`} className="font-medium text-indigo-600 hover:text-indigo-500">
                                View
                            </Link>
                        </div>
                    </div>
                </dd>
            </div>
        </div>
    </div>)
}
